import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CONFIG } from '../../../Utils/config';
import { STATUS, REGIONS } from '../../../Utils/utils';
import { Editor } from "@tinymce/tinymce-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeField from 'react-simple-timefield';
import './AddListing.css';
import deleteicon from '../../../Assets/Img/delete.png';
import axios from 'axios';
import { connect } from 'react-redux';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import Dropzone from 'react-dropzone';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

class AddListing extends Component {
    static defaultProps = {
        allowedTypes: ['image/jpeg', ' image/jpg', ' image/png', ' image/svg'],
        crop: {
            aspect: 91 / 54,
            width: 200,
            keepSelection: true
        },
        maxFileSize: 15000,
    }

    constructor(props) {
        super(props);
        this.state = {
            newsTypes: [],
            articleDescription: '',
            newsTypeIds: [],
            minDate: '',
            time: '',
            ArticleImage: '',
            newsDetail: {
                NewsID: 0,
                ArticleTitle: '',
                NewsTypeIds: '',
                ArticleDescription: '',
                KeyWord: '',
                PublishedDate: '',
                IsLeadStory: '',
                ArticleImage: '',
                StatusId: '',
                PublishedDateStr: '',
                isButtonDisabled: false
            },
            isAdd: true,
            imageToCrop: null,
            imageToCropList: [],
            open: false,
            crop: this.props.crop,
            uploadError: false,
            DeletedImage: [],
        };

        this.onPublishTimeChange = this.onPublishTimeChange.bind(this);
        this.onArticleDescriptionChange = this.onArticleDescriptionChange.bind(this);
        this.onPublishDateChange = this.onPublishDateChange.bind(this);
        this.onAMPMChange = this.onAMPMChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getLookupNewsTypes();
        var isAdd = true;
        var isAM = true;

        if (this.props.match.params.newsId) {
            let newsId = this.props.match.params.newsId;
            isAdd = false;
            this.getNewsAndEventsDetail(newsId);
            this.setState({ isAdd });
        } else {
            var today = new Date();
            var hour = today.getHours();
            var min = today.getMinutes();
            if (hour > 12) {
                hour = hour - 12;
                isAM = false;
            }

            if (hour < 10) {
                hour = "0" + hour;
            }
            if (min < 10) {
                min = "0" + min;
            }

            var time = hour + ":" + min;
            var newsDetail = this.state;
            newsDetail.PublishedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            newsDetail.PublishedDateStr = newsDetail.PublishedDate.toLocaleDateString();
            this.setState({ newsDetail, time, isAM });

        }
    }

    getLookupNewsTypes() {
        axios.get(CONFIG.API_URL + 'lookup/newstypes').then(response => {
            if (response.status === 200) {
                this.setState({ newsTypes: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    getNewsAndEventsDetail(newsID) {

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.get(CONFIG.API_URL + 'admin/newsandevents/' + newsID + '/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                var newsDetail = response.data;
                var ArticleImage = response.data.ArticleImage;
                var newsTypeIds = Array.from(newsDetail.NewsTypeIds.split(','), Number);

                //Calculate hours and mins. 
                var today = new Date(newsDetail.PublishedDate);

                newsDetail.PublishedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                var hour = today.getHours();
                var isAM = true;
                var min = today.getMinutes();

                if (hour === 0) {
                    hour = "12";
                    isAM = false;
                }
                if (min < 10) {
                    min = "0" + min;
                }

                if (hour > 12) {
                    hour = hour - 12;
                    isAM = false;
                }
                if (hour < 10) {
                    hour = "0" + hour;
                }
                var time = hour + ":" + min;
                //Calculate hours and mins.

                this.setState({ newsDetail, isAM, time, newsTypeIds, ArticleImage });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    listingSubmit(event, errors, values) {
        this.props.dispatch(showLoader());
        var isValid = false;
        const { time, isAM, newsDetail, DeletedImage } = this.state;
        var articleDescriptionError = '';
        var categoryError = '';
        var timeError = '';

        var splitPTime = time.split(":");
        var hour = splitPTime[0];
        var min = splitPTime[1];

        if (splitPTime[0] === 0) {
            timeError = "Hour should be between 1 and 12";
        }

        if (splitPTime[0] > 12) {
            timeError = "Hour should not be greater than 12";
        }

        if (splitPTime[1] > 60) {
            timeError = timeError ? timeError + "and " : "Minute should not be greater than 60";
        }

        if (!newsDetail.ArticleDescription) {
            articleDescriptionError = "Article Description is required.";
        }

        if (!newsDetail.NewsTypeIds) {
            categoryError = "Category is required.";
        }

        this.setState({ articleDescriptionError, categoryError, timeError });

        if (errors.length === 0 && newsDetail.NewsTypeIds && newsDetail.ArticleDescription && (splitPTime[0] <= 12 && splitPTime[0] > 0) && splitPTime[1] <= 60) {
            isValid = true;
        }

        if (isValid) {
            var timeL = time;
            var splitTime = timeL.split(":");

            if (!isAM) {

                hour = splitTime[0] > 0 ? (+splitTime[0] + 12) : 0;
            }
            timeL = hour + ":" + min;

            const data = {
                RegionId: CONFIG.REGION_ID,
                NewsID: newsDetail.NewsID,
                ArticleTitle: values.ArticleTitle,
                ArticleDescription: newsDetail.ArticleDescription,
                KeyWord: values.KeyWord,
                PublishedDate: newsDetail.PublishedDate,
                PublishedDateStr: newsDetail.PublishedDateStr,
                IsLeadStory: values.IsLeadStory,
                NewsTypeIds: newsDetail.NewsTypeIds,
                ArticleImage: newsDetail.ArticleImage,
                Time: timeL,
                StatusId: newsDetail.StatusId,
                MinutesRead: parseFloat(values.MinutesRead).toFixed(2),
                DeletedImage: DeletedImage.toString(),
            }
            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }

            axios.post(CONFIG.API_URL + 'admin/newsandevents/add', data)
                .then(response => {
                    if (response.status === 200) {
                        if (data.NewsID > 0) {
                            this.props.dispatch(setSnackbar(true, "success", "The article updated successfully"));
                        } else {
                            this.props.dispatch(setSnackbar(true, "success", "The article added successfully"));
                        }
                        this.cancelListing();
                    }
                })
                .catch(err => {
                    this.props.dispatch(hideLoader());
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
        else
            this.props.dispatch(hideLoader());
    }

    cancelListing() {
        const { from } = { from: { pathname: "/admin/newsandevents" } };
        this.props.history.push(from);
    }

    draftListing() {
        const { newsDetail } = this.state;
        newsDetail.StatusId = STATUS.Draft;
        this.setState({ newsDetail }, () => {
            document.getElementById('btn').click();
        });
    }

    saveClick() {
        this.props.dispatch(showLoader());
        const { newsDetail } = this.state;
        newsDetail.StatusId = STATUS.Active;
        this.setState({ newsDetail }, () => {
            document.getElementById('btn').click();
        });
    }

    onArticleDescriptionChange(articleDescription, editor) {
        const { newsDetail } = this.state;
        newsDetail.ArticleDescription = articleDescription
        this.setState({ newsDetail });
    }

    onChangeCategory(id) {

        var newsTypeIds = this.state.newsTypeIds;

        var indexOfnewsTypeIds = newsTypeIds.indexOf(id);
        if (indexOfnewsTypeIds > -1) {
            newsTypeIds.splice(indexOfnewsTypeIds, 1);
        } else {
            newsTypeIds.push(id);
        }
        const { newsDetail } = this.state;
        newsDetail.NewsTypeIds = newsTypeIds.toString();
        this.setState({ newsTypeIds, newsDetail });
    }

    onPublishDateChange(date) {
        var today = date == null ? new Date() : new Date(date);
        const { newsDetail } = this.state;
        newsDetail.PublishedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        newsDetail.PublishedDateStr = newsDetail.PublishedDate.toLocaleDateString();
        this.setState({ newsDetail });
    }

    onPublishTimeChange(event, time) {

        this.setState({ time });
    }

    onAMPMChange() {
        const { isAM } = this.state;
        this.setState({ isAM: !isAM });
    }

    removePropertyImage() {
        const { newsDetail, DeletedImage } = this.state;
        DeletedImage.push(newsDetail.ArticleImage);
        newsDetail.ArticleImage = '';
        this.setState({ newsDetail, DeletedImage });
    }

    onKeyPressOnlyNumber(e) {
        const re = /[0-9.]+/g;
        
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    }

    onPasteOnlyNumber(e) {
        const re = /^[0-9.]*$/;
        var str = e.clipboardData.getData('Text');
        
        if (!re.test(str)) {
            e.preventDefault();
        }
    }

    saveImage = (file) => {
        this.setState({ crop: this.props.crop }, () => {
            if (file.length > 0) {
                file = file[0];
                const i = new Image()
                i.onload = () => {
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => {
                        let lastUploadedFile = {
                            name: file.name,
                            contentType: file.type,
                            content: reader.result,
                            preview: URL.createObjectURL(file),
                            width: i.width,
                            height: i.height,
                            naturalWidth: i.naturalWidth,
                            naturalHeight: i.naturalHeight
                        };

                        let imgWidth = lastUploadedFile.width <= CONFIG.NewsAndEventsImageWidth ? lastUploadedFile.width : CONFIG.NewsAndEventsImageWidth;
                        let imgHeight = lastUploadedFile.height <= CONFIG.NewsAndEventsImageHeight ? lastUploadedFile.height : CONFIG.NewsAndEventsImageHeight;
                        
                        this.setState({
                            crop: { width: imgWidth, height: imgHeight, aspect: this.props.crop.aspect, keepSelection: this.props.keepSelection },
                            uploadError: false
                        });

                        if (this.state.imageToCrop) {
                            let { imageToCropList } = this.state;
                            imageToCropList.push(lastUploadedFile);
                            this.setState({ imageToCropList: imageToCropList });
                        } else {
                            this.setState({ imageToCrop: lastUploadedFile, open: true });
                        }
                    };

                    reader.onabort = () => {
                        this.setState({
                            uploadError: true
                        });
                    };

                    reader.onerror = () => {
                        this.setState({
                            uploadError: true
                        });
                    }
                }
                i.src = URL.createObjectURL(file)
            } else {
                this.setState({
                    uploadError: true
                });
            }
        });
    }

    renderAcceptedFileTypes = () => {
        let typesStr = '';
        if (this.props.allowedTypes) {
            this.props.allowedTypes.forEach((type /*, index*/) => {
                typesStr += `${type}, `;
            });
        }
        return typesStr;
    };

    /** Cropper Methods Start **/
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onChange = (pixel, crop) => {
        if (crop.height === 0 || crop.width === 0) {
            return;
        }
        this.setState({ crop: crop, pixelCrop: pixel });
    };

    onAccept = () => {
        const { pixelCrop } = this.state;
        this.makeClientCrop(pixelCrop);
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const { imageToCrop } = this.state;
            const blob = await this.getCroppedImg(
                this.imageRef,
                crop,
                imageToCrop.name
            );
            var lthis = this;

            var croppedImageUrl = window.URL.createObjectURL(blob);
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                var formData = {
                    Base64StringFile: base64data.split(",")[1],
                    Name: imageToCrop.name,
                    RegionID: CONFIG.REGION_ID
                }

                let token = localStorage.getItem('accessKey');

                if (token) {
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                }

                if (!CONFIG.ISDEV) {
                    axios.post(CONFIG.API_URL + 'admin/newsandevents/upload/image', formData).then(response => {
                        if (response.status === 200) {
                            const { newsDetail } = lthis.state;
                            newsDetail.ArticleImage = response.data;
                            lthis.setState({ croppedImageUrl, newsDetail, imageToCrop: null, imageToCropList: [], open: false, crop: lthis.props.crop });
                        }
                    });
                } else {
                    axios.get(CONFIG.API_URL + 'home/upload/image/test').then(response => {
                        if (response.status === 200) {
                            const { newsDetail } = lthis.state;
                            newsDetail.ArticleImage = response.data;
                            lthis.setState({ croppedImageUrl, newsDetail, imageToCrop: null, imageToCropList: [], open: false, crop: lthis.props.crop });
                        }
                    });
                }
            }
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    onCancel = () => {
        this.setState({ imageToCrop: null, imageToCropList: [], open: false, crop: this.props.crop });
    };
    /** Cropper Methods End **/

    render() {

        const { newsTypes, newsTypeIds, time, isAM, newsDetail, articleDescriptionError, categoryError,
            timeError, articleImageError, isAdd, imageToCrop, open, crop } = this.state;
        document.title = newsDetail.NewsID > 0 ? CONFIG.PAGE_TITLE + "Edit Article Listing" : CONFIG.PAGE_TITLE + "Add Article Listing ";

        var title = newsDetail.NewsID > 0 ? "Edit Article Listing" : "Add Article Listing ";
        return (
            isAdd || (!isAdd && newsDetail.ArticleTitle) ?
                <div className="form-back-shadow add-listing-news margin-t-15">
                    <h1>{title}</h1>
                    <Col>
                        <AvForm onSubmit={this.listingSubmit.bind(this)} ref={c => (this.myFormSearch = c)}>
                            <Row>
                                <Label className="add-listing-lable">Category:</Label>
                                {newsTypes ? newsTypes.map(n => {
                                    return (
                                        <React.Fragment>
                                            <AvField type="checkbox" className="checkbox-align" name={n.ID}
                                                onChange={(e) => this.onChangeCategory(n.ID)} value={newsTypeIds.indexOf(n.ID) > -1 ? true : false} checked={newsTypeIds.indexOf(n.ID) > -1 ? true : false} />
                                            <span>{n.Name}</span>

                                        </React.Fragment>
                                    )
                                })
                                    : ''}
                                <span className="error-addlisting error-category1">{categoryError ? categoryError : ''}</span>
                            </Row>

                            <Row>
                                <Label className="add-listing-lable">Lead Story:</Label>
                                <AvField type="checkbox" className="checkbox-align" name="IsLeadStory" value={newsDetail.IsLeadStory} checked={newsDetail.IsLeadStory} />
                                <span>Yes</span>
                            </Row>
                            <Row>
                                <Label className="add-listing-lable">Article Title:*</Label>
                                <AvField name="ArticleTitle" label="" type="text" value={newsDetail.ArticleTitle}
                                    validate={{
                                        required: { value: true, errorMessage: 'Article Title is required' },
                                        maxLength: { value: 1000 }
                                    }} />
                            </Row>

                            <Row>
                                <Label className="add-listing-lable">Article Description:*</Label>
                                <Editor
                                    value={newsDetail.ArticleDescription}
                                    apiKey={CONFIG.TINY_MCE_KEY}
                                    init={{
                                        height: 300,
                                        width: 500,
                                        menubar: true,
                                        plugins: "link",
                                    }}
                                    onEditorChange={this.onArticleDescriptionChange}
                                />
                                <span className="error-addlisting error-category2">{articleDescriptionError ? articleDescriptionError : ''}</span>
                            </Row>


                            <Row>
                                <Label className="add-listing-lable">Keywords:*</Label>
                                <AvField name="KeyWord" label="" type="text" value={newsDetail.KeyWord}
                                    validate={{
                                        required: { value: true, errorMessage: 'Keyword is required' },
                                        maxLength: { value: 1000 }
                                    }} />
                            </Row>
                            <div className="add-margin-L140">
                                {this.state.uploadError || this.props.uploadError ? (
                                    <p className="margin-l-10">
                                        <p className="margin-b-3 error-msg"><em> Accepted file types:{this.props.allowedTypes.toString()}</em></p>

                                        <p className="margin-b-3 error-msg"><em>File size must not exceed <b>{this.props.maxFileSize} KB.</b></em></p>
                                    </p>
                                ) : null}
                            </div>
                            <Row className="articel-img-show">
                                <Label className="add-listing-lable">Image:</Label>
                                {!newsDetail.ArticleImage ?
                                    <React.Fragment>
                                        <div>
                                            {open ?
                                                <Modal isOpen={open} className="image-cropper">
                                                    <ModalHeader>Image cropper
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        <div basic style={{ margin: "0px" }} textAlign="center">
                                                            <ReactCrop src={imageToCrop.preview} crop={crop}
                                                                onImageLoaded={this.onImageLoaded}
                                                                onChange={this.onChange} />
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button className="reset-btn margin-r-0" onClick={() => { this.onCancel() }}>Cancel</Button>
                                                        <Button className="accept-btn" onClick={this.onAccept}>Accept</Button>
                                                    </ModalFooter>
                                                </Modal>
                                                : ''
                                            }
                                        </div>
                                        {/* {this.state.uploadError ? (
                                            <p>
                                                <p className="margin-b-3 error-msg"><em> Accepted file types: {this.props.allowedTypes.toString()}</em></p>

                                                <p className="margin-b-3 error-msg"><em>File size must not exceed <b>{this.props.maxFileSize} KB.</b></em></p>
                                            </p>
                                        ) : null} */}
                                        <Dropzone
                                            disableClick={true}
                                            onDrop={this.saveImage.bind(this)}
                                            className='dropzone'
                                            activeClassName='active-dropzone'
                                            multiple={false}
                                            maxSize={this.props.maxFileSize * 1000}
                                            accept={this.renderAcceptedFileTypes()}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()} className="crop-img-news">
                                                        <input {...getInputProps()} />
                                                        <p><FontAwesomeIcon icon={faFileUpload} /> Drag and drop files here or click to select files</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>

                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="image-resize-articeldetails">
                                            <img className="width-100 height-100" src={'/ListingImages/' + newsDetail.ArticleImage} onError={(e) => { e.target.onerror = null; e.target.src = '' }} alt={newsDetail.ArticleImage} />
                                        </div>
                                        <div className="type-container" onClick={() => this.removePropertyImage(newsDetail.newsID)}><img src={deleteicon} alt="delete" title="Delete and Submit" /></div>
                                    </React.Fragment>
                                }
                            </Row>
                            <span className="error-addlisting add-margin-L15">{articleImageError ? articleImageError : ''}</span>
                            <Row >
                                <Label className="add-listing-lable">Publish Date:</Label>
                                <DatePicker className="is-touched is-pristine av-valid form-control" selected={newsDetail.PublishedDate} dateFormat={CONFIG.REGION_ID === REGIONS.US ? "MM-dd-yyyy" : "dd-MM-yyyy"} onChange={this.onPublishDateChange} minDate={new Date()} />
                                <div className="datetime-artical">
                                    <Label className="add-listing-lable">Publish time:</Label>
                                    <TimeField className="addtiming" value={time} onChange={this.onPublishTimeChange} />
                                    <Link className="time-type" onClick={this.onAMPMChange}>{isAM ? "AM" : "PM"} </Link>
                                    {timeError ? <span className="error-addlisting">{timeError}</span> : ''}
                                </div>
                            </Row>
                            <Row className="margin-t-1rem">
                                <Label className="add-listing-lable">Minutes Read:*</Label>
                                <AvField type="number" name="MinutesRead" max="60" value={newsDetail.MinutesRead}
                                    onPaste={(e) => this.onPasteOnlyNumber(e)}
                                    onKeyPress={(e) => this.onKeyPressOnlyNumber(e)}
                                    validate={{
                                        required: { value: true, errorMessage: "Minutes Read is required" },
                                    }} />
                            </Row>
                            <Row className="news-add-btns">
                                <Button id="btn" className="displaynone save-btn">Save</Button>
                                <Button className="next-btn" onClick={this.saveClick.bind(this)}>Submit</Button>
                                {newsDetail.NewsID > 0 ? '' : <Button className="search-btn" onClick={this.draftListing.bind(this)}>Save as Draft</Button>}
                                <Button className="reset-btn" onClick={this.cancelListing.bind(this)}>Cancel</Button>
                            </Row>
                        </AvForm>
                    </Col>
                </div>
                : ''
        );
    }
}
export default connect()(AddListing);