import React, { Component } from "react";
import { Button, Row, Container, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import { CONFIG } from "../../Utils/config";
import { normalizeInput, REGIONS } from "../../Utils/utils";
import { connect } from "react-redux";
import { setSnackbar } from "../../redux/ducks/snackbarReducer";
import "./Advertise.css";
import { showLoader, hideLoader } from "../../redux/ducks/application";
import Helmet from "../../Utils/Helmet";
import properties from "./../../Assets/Img/properties-sale.png";
import usertraget from "./../../Assets/Img/user-traget.png";
import shieldtick from "./../../Assets/Img/shield-tick.png";
import zoomeye from "./../../Assets/Img/zoom-eye.png";
import HubspotForm from "react-hubspot-form";
import advertisebanner from "./../../Assets/Img/advertise-banner.jpg";

class Advertise extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="advertise">
        <Helmet
          title="Advertise"
          addPostfixTitle={true}
          keywords="Advertise, Advertise with List Self Storage"
        />

        <div
          style={{
            backgroundImage: `url(${advertisebanner})`,
            backgroundRepeat: "no-repeat",
            height: "400px",
            width: "100%",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <div class="overlay-bg bg-color"></div>
          <Container className="padding-remove">
            <div class="banner-container">
              <div class="banner-title">
                <h1>
                  Advertise with <br />
                  List Self Storage
                </h1>
              </div>
            </div>
          </Container>
        </div>

        <Container className="padding-remove ">
          <Col sm={12} className="remove-padding mt-5 mb-5">
            <Row className="padding-t-0 ">
              <Col sm={6} className="advt-paragraph">
                <h2>Grow Your Brand with Us</h2>
                <p>
                  List Self Storage (LSS) is the world’s premier platform for
                  buying and selling self storage facilities. Since launching in
                  2015, List Self Storage has hosted over $5 billion in self
                  storage, conversion and development properties for sale with
                  cooperation from the brokerage community.
                </p>

                <p>
                  We deliver quality content through our weekly newsletters and
                  website. We consistently deliver value to our audience of
                  thousands of self-storage professionals across the UK. This
                  ensures maximum reach and engagement for our advertisers.
                </p>

                <div className="text-center">
                  <img
                    src={properties}
                    alt="Image"
                    className="properties-img-responsive"
                  />
                </div>
              </Col>
              <Col sm={1} className="tablet"></Col>
              <Col sm={5} className="cust-form-advt">
                <h3>
                  Learn about our <br /> advertising opportunities
                </h3>
                <div className="advt-form">
                <HubspotForm
                  portalId="4297816"
                  formId="20027df0-a660-4f70-b278-1b2b3576b5cd"
                  css=" "
                />
                </div>
                
              </Col>
            </Row>
          </Col>
        </Container>

        <Container className="padding-remove">
          <Col sm={12} className="remove-padding mt-5 bottom-section">
            <h2 className="mt-4 mb-4"> Top Reasons to Advertise with Us</h2>
            <Row className="padding-t-0 mt-3" >
              <Col sm={4}>
                <div className="advertis-us">
                  <div className="advt-us-img">
                    <img src={usertraget} alt="Image" />
                  </div>
                  <div className="advt-us-content">
                    <div className="advt-us-title">
                      Targeted <br /> Audience
                    </div>
                    <p>
                      Reach a niche audience actively engaged in the UK
                      self-storage market.
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="advertis-us">
                  <div className="advt-us-img">
                    <img src={shieldtick} alt="Image" />
                  </div>
                  <div className="advt-us-content">
                    <div className="advt-us-title">
                      Industry <br /> Authority
                    </div>
                    <p>
                      Leverage our platform's reputation as a leading authority
                      in self-storage property listings.
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="advertis-us">
                  <div className="advt-us-img">
                    <img src={zoomeye} alt="Image" />
                  </div>
                  <div className="advt-us-content">
                    <div className="advt-us-title">
                      Enhanced <br /> Visibility
                    </div>
                    <p>
                      Maximize exposure for your listings and brand, ensuring
                      optimal visibility and lead generation.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    );
  }
}

export default connect()(Advertise);
