import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Switch from 'react-switch';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faSearch } from '@fortawesome/free-solid-svg-icons';
import edit from '../../../Assets/Img/edit.png';
import view from '../../../Assets/Img/view.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from "react-router-dom";
import { CONFIG } from '../../../Utils/config';
import { STATUS, REGIONS } from '../../../Utils/utils';
import axios from 'axios';
import './VendorProfileList.css';
import Pagination from '@material-ui/lab/Pagination';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Helmet from '../../../Utils/Helmet';

export default class VendorProfileList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorTypes: [],
            vendorStatus: [],
            stateList: [],
            countryList: [],
            pagination: {},
            newsTypeIds: [],
            searchCriteria: {
            },
            PaidCount: 0,
            FreeCount: 0,
            pageNum: 0
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getLookupVendorTypes();
        this.getLookupStatus();
        this.InitialSearchValue();
    }

    InitialSearchValue() {
        var searchCriteria = {
            CompanyName: '',
            StatusId: 0,
            CountryID: CONFIG.REGION_ID === REGIONS.US ? CONFIG.US_CountryId : '',
            StateID: "",
            VendorTypeID: 0,
            RegionId: CONFIG.REGION_ID,

            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: '',
            SortOrder: ''
        }
        this.setState({ searchCriteria }, () => {
            this.getVendorList(searchCriteria);
            // if (CONFIG.REGION_ID != REGIONS.UK) {
            //     this.getLookupState();
            // }
        });
    }

    getLookupVendorTypes() {
        axios.get(CONFIG.API_URL + 'lookup/vendor/types').then(response => {
            if (response.status === 200) {
                this.setState({ vendorTypes: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getLookupStatus() {
        axios.get(CONFIG.API_URL + 'lookup/status').then(response => {
            if (response.status === 200) {
                this.setState({ vendorStatus: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }


    getLookupCountry() {
        axios.get(CONFIG.API_URL + 'lookup/country/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                this.setState({ countryList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getLookupState() {
        const { searchCriteria } = this.state;

        axios.get(CONFIG.API_URL + 'lookup/state/' + searchCriteria.CountryID).then(response => {
            if (response.status === 200) {
                this.setState({ stateList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    handleChangePage = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            this.getVendorList(searchCriteria);
        });
    };


    getVendorList(data) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'vendor/profile/list', data).then(response => {
            if (response.status === 200) {
                this.setState({
                    searchResult: response.data.Vendors, pagination: response.data.Pagination, FreeCount: response.data.FreeCount, PaidCount: response.data.PaidCount,
                    pageNum: Math.ceil(response.data.Pagination.TotalRecords / 10)
                });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    resetSearch() {
        this.InitialSearchValue();
    }

    onSearchCountryChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.CountryID = e.target.value;

        this.setState({ searchCriteria });
    }
    onSearchStateChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.StateID = e.target.value;
        this.setState({ searchCriteria })
    }

    onSearchStatusChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.StatusId = e.target.value;
        this.setState({ searchCriteria })
    }

    onSearchCompanyNameChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.CompanyName = e.target.value;
        this.setState({ searchCriteria })
    }

    onSearchVendorTypeChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.VendorTypeID = e.target.value;
        this.setState({ searchCriteria })
    }

    searchSubmit() {
        const { searchCriteria } = this.state;
        this.getVendorList(searchCriteria);
    }

    editVendor(listing) {
        const { from } = { from: { pathname: "/user/vendor-profile/" + listing.VendorID } };
        this.props.history.push(from);
    }

    restoreVendor(id) {
        const { searchCriteria } = this.state;

        confirmAlert({
            message: 'Are you sure to make this profile active?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        axios.put(CONFIG.API_URL + 'Vendor/' + id + "/" + CONFIG.REGION_ID)
                            .then(res => {
                                this.getVendorList(searchCriteria);
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });

                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    deleteVendor(id) {
        const { searchCriteria } = this.state;

        confirmAlert({
            message: 'Are you sure to make this profile inactive?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        this.setState({ isLoading: true });

                        axios.put(CONFIG.API_URL + 'Vendor/delete/' + id + "/" + CONFIG.REGION_ID)
                            .then(res => {
                                this.getVendorList(searchCriteria);
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });

                            });

                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    render() {
        // document.title = CONFIG.PAGE_TITLE + 'Vendor Profile List';

        const { searchResult, pagination, pageNum, searchCriteria, vendorStatus, vendorTypes } = this.state;

        return (
            <div>
                <Helmet
                    title='Vendor Profile List'
                    addPostfixTitle={true}
                    keywords="Vendor Profile List, Vendor"
                />
                <div className="header-name-box form-back-shadow margin-t-15 addpadding-property">
                    <Row className="marign-t-8">
                        <Col sm={8}>
                            <h6>Vendor Directory</h6>
                        </Col>
                        <Col sm={4}>
                            <div className="add-new-btn">
                                <Link to="/user/vendor-profile" ><FontAwesomeIcon icon={faPlusSquare} /> Add new company</Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="form-back-shadow ">
                    <div className="filters-search">
                        <AvForm onSubmit={this.searchSubmit.bind(this)} >
                            <Row>
                                <Col md={4}>
                                    <AvField name="CompanyName" label="Company Name:" type="text"
                                        value={searchCriteria.CompanyName} onChange={(e) => this.onSearchCompanyNameChange(e)} />
                                </Col>
                                {/* {CONFIG.REGION_ID != REGIONS.UK ?
                                    <Col md={2} >
                                        <AvField type="select" name="StateID" value={searchCriteria.StateID}
                                            onChange={(e) => this.onSearchStateChange(e)}
                                            label="State:">
                                            <option value="">--Select--</option>
                                            {stateList ? stateList.map(n => {
                                                return (
                                                    <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                        disabled={n.Id === '' ? true : false}
                                                        value={n.Id}>
                                                        {n.Id}
                                                    </option>
                                                );
                                            }) : ''}
                                        </AvField>
                                    </Col>
                                    : ''} */}
                                {CONFIG.REGION_ID === REGIONS.US ?
                                    <Col md={3}>
                                        <AvField type="select" name="StatusId" value={searchCriteria.StatusId}
                                            onChange={(e) => this.onSearchStatusChange(e)}
                                            label="Status:">
                                            <option value="0">--Select--</option>
                                            {vendorStatus ? vendorStatus.map(n => {
                                                if (n.ID === STATUS.Active || n.ID === STATUS.InActive || n.ID === STATUS.DeleteApprove) {
                                                    return (
                                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                            disabled={n.ID === '' ? true : false}
                                                            value={n.ID}>
                                                            {n.Name}
                                                        </option>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            }) : ''}
                                        </AvField>
                                    </Col>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <Col md={3}>
                                            <AvField type="select" name="StatusId" value={searchCriteria.StatusId}
                                                onChange={(e) => this.onSearchStatusChange(e)}
                                                label="Status:">
                                                <option value="0">--Select--</option>
                                                {vendorStatus ? vendorStatus.map(n => {
                                                    if (n.ID === STATUS.Active || n.ID === STATUS.InActive || n.ID === STATUS.DeleteApprove) {
                                                        return (
                                                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                disabled={n.ID === '' ? true : false}
                                                                value={n.ID}>
                                                                {n.Name}
                                                            </option>
                                                        );
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                }) : ''}
                                            </AvField>
                                        </Col>
                                        : ""}
                                {CONFIG.REGION_ID === REGIONS.US ?
                                    <Col md={3}>
                                        <AvField type="select" name="StatusId" value={searchCriteria.VendorTypeID}
                                            onChange={(e) => this.onSearchVendorTypeChange(e)}
                                            label="Listing Type:">
                                            <option value="0">--Select--</option>
                                            {vendorTypes ? vendorTypes.map(n => {
                                                return (
                                                    <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                        disabled={n.ID === '' ? true : false}
                                                        value={n.ID}>
                                                        {n.Name}
                                                    </option>
                                                );
                                            }) : ''}
                                        </AvField>
                                    </Col>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <Col md={3}>
                                            <AvField type="select" name="StatusId" value={searchCriteria.VendorTypeID}
                                                onChange={(e) => this.onSearchVendorTypeChange(e)}
                                                label="Listing Type:">
                                                <option value="0">--Select--</option>
                                                {vendorTypes ? vendorTypes.map(n => {
                                                    return (
                                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                            disabled={n.ID === '' ? true : false}
                                                            value={n.ID}>
                                                            {n.Name}
                                                        </option>
                                                    );
                                                }) : ''}
                                            </AvField>
                                        </Col>
                                        : ""}



                                <Col md={2} className="text-align-right margin-t-20 padding-left-remove">
                                    <Button className="search-btn"><FontAwesomeIcon icon={faSearch} /> Search</Button>
                                    <Button onClick={this.resetSearch.bind(this)} className="reset-btn margin-r-0">Reset</Button>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                    <Row className="total-watchlist margin-t-5">
                        <Col md={6} className="total-numb padding-remove">

                            {searchResult && searchResult.length > 0 ? <p>Total Vendor Profiles: {pagination.TotalRecords}</p> : ''}

                        </Col>
                        <Col md={6} className="total-numb">

                        </Col>
                    </Row>
                    <Col md={12} className="margin-t-15 padding-remove">
                        <div className="table-model parent-category">
                            {searchResult ? searchResult.length > 0 ?
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {searchResult.map((record) =>
                                            <TableRow key={record.VendorID} >
                                                <TableCell>{record.CompanyName}</TableCell>
                                                <TableCell>{record.Status}</TableCell>
                                                <TableCell>{record.VendorType}</TableCell>
                                                <TableCell>
                                                    <Link type="edit" onClick={() => this.editVendor(record)}><img src={edit} alt="Edit" className="cursor-pointer" /></Link>
                                                    {record.StatusId === STATUS.Active || record.StatusId === STATUS.DeleteApprove ? <Link to={"/user/profile/" + record.Permalink} ><img src={view} alt="View" className="cursor-pointer" /></Link> : ''}
                                                    {record.StatusId === STATUS.Active ?
                                                        <Switch className="active-in slide-button" onChange={event => this.deleteVendor(record.VendorID)} checked={true} />
                                                        : ''}
                                                    {record.StatusId === STATUS.InActive ?
                                                        <Switch className="active-in slide-button" onChange={event => this.restoreVendor(record.VendorID)} checked={false} />
                                                        : ''}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan="4"><Pagination className="seaarch-pagination" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                                : <h6 className="no-records-found">No records found</h6>
                                : ''}
                        </div>
                    </Col>
                </div>
            </div>
        );
    }
}
