import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CONFIG } from '../../../../Utils/config';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './AssignVendor.css';
import Pagination from '@material-ui/lab/Pagination';

export default class AssignVendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.isOpen,
            userID: props.userID,
            isLoading: false,
            pageNum: 0

        }
        this.getVendors = this.getVendors.bind(this);
    }

    componentDidMount() {
        const { isOpen } = this.state;
        if (isOpen) {
            this.InitialSearchValue();
        }
    }

    InitialSearchValue() {
        var searchCriteria = {
            Name: '',
            Email: '',
            RegionId: CONFIG.REGION_ID,
            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: '',
            SortOrder: ''
        }
        this.setState({ searchCriteria }, () => {
            this.getVendors(searchCriteria);
        });
    }

    searchUsers(event, errors, values) {
        const { searchCriteria } = this.state;
        searchCriteria.Name = values.Name;
        searchCriteria.Email = values.Email;
        searchCriteria.Page = 1;
        this.setState({ searchCriteria }, () => {
            this.getVendors(searchCriteria);
        });
    }

    resetSearch() {
        this.formUsers && this.formUsers.reset();
        this.InitialSearchValue();
    }

    toggleModalClose = () => {
        this.setState({ isOpen: false, isLoading: false }, () => {
            this.props.parentMethod(0);
        });
    }

    getVendors(data) {

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        this.setState({ searchSellerError: '' });

        axios.post(CONFIG.API_URL + 'vendor/users', data)
            .then(response => {

                let result = response.data;
                this.setState({
                    users: result.Users,
                    pagination: result.Pagination,
                    pageNum: Math.ceil(result.Pagination.TotalRecords / 10)
                });
            })
    }

    setVendor(userID, firstname) {
        this.props.parentMethod(userID, firstname);

    }

    handleChangePage = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            this.getVendors(searchCriteria);
        });

    };

    render() {
        const { isOpen, users, userID, pageNum } = this.state;
        return (
            <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={isOpen} toggle={this.toggleModalClose.bind(this)} className="vendor-assign-table">
                <ModalHeader toggle={this.toggleModalClose.bind(this)}>Assign Vendor
                  </ModalHeader>
                <ModalBody className="overflow-scroll">
                    <div className="filters-search form-back-shadow">
                        <AvForm onSubmit={this.searchUsers.bind(this)} ref={c => (this.formUsers = c)}>
                            <Row>
                                <Col md={6}>
                                    <AvField name="Name" label="Name:" type="text" />
                                </Col>
                                <Col md={6}>
                                    <AvField name="Email" label="Email:" type="text" />
                                </Col>
                                <Col md={12} className="text-align-right">
                                    <Button className="search-btn" ><FontAwesomeIcon icon={faSearch} /> Search</Button>
                                    <Button className="reset-btn" onClick={this.resetSearch.bind(this)}>Reset</Button>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                    <div className="table-scroll vendor-popup-table">
                        {users ? users.length > 0 ?
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="width-80seller">Select</TableCell>
                                        <TableCell> Name</TableCell>
                                        <TableCell className="email-width">Email</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) =>
                                        <TableRow>
                                            <TableCell className="width-80seller">
                                                {
                                                    userID !== user.UserID ?
                                                        <Button className="select-btn" onClick={() => this.setVendor(user.UserID, user.LastName ? user.FirstName + " " + user.LastName : user.FirstName)}>Select</Button>
                                                        :
                                                        <Label className="selected">Select</Label>
                                                }
                                            </TableCell>
                                            <TableCell>{user.FirstName} {user.LastName}</TableCell>
                                            <TableCell className="email-width">{user.Login}</TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan="3">
                                            <Pagination className="seaarch-pagination table-pagination-model" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            :
                            <h6 className="no-records-found">No vendors found</h6>
                            : ''
                        }
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}