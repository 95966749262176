import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {
    Button, Modal, ModalHeader, ModalBody, Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG } from './../../Utils/config';
import { USERTYPES, REGIONS } from './../../Utils/utils';
import { removeLocalStorage, getToken, getUserType, getName } from './../../Utils/localStorage';
import mobileimg from './../../Assets/Img/phone_icon.png';
import lss_logo from './../../Assets/Img/LSS-logo.png';
import uslogo from './../../Assets/Img/us-logo.png';
import uklogo from './../../Assets/Img/uk-lgo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './../../Assets/icon-fonts/icons.css';
import './Header.css';
import { setSnackbar } from '../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../redux/ducks/application';
import { UserRegistration } from '../../Views/Account/UserRegistration';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isSigninPopup: false,
            isForgotPassword: false,
            isLoading: false,
            signinError: '',
            isSuccess: false,
            error: '',
            token: '',
            name: '',
            isUserExist: false,
            path: '',
            ismvpRedirect: false,
            mvpURL: null,
            propertyID: 0,
            errors: {},
            userMigrationMesssage: '',
            isSubmitProperty: false
        };
        this.forgotPassword = this.forgotPassword.bind(this);
        this.parentMethod = this.parentMethod.bind(this);
        this.getMVPURL = this.getMVPURL.bind(this);
    }

    toggle() {
        this.setState(state => ({ isOpen: !state.isOpen }));
    }

    getMVPURL() {
        this.setState({ isSigninPopup: false });
        window.open(this.state.mvpURL, '_blank');
    }

    signIn(values) {
        if (values === "property") {
            this.setState({ isSubmitProperty: true })
        }
        var ismvpRedirect = localStorage.getItem('mvpparams') ? true : false;
        var propertyID = localStorage.getItem('propertyid');
        var mvpURL = CONFIG.REPORT_URL + "partner-user/" + localStorage.getItem('mvpparams');

        this.setState({
            isSigninPopup: true, isUserExist: false, ismvpRedirect: ismvpRedirect,
            mvpURL: mvpURL, propertyID: propertyID, errors: {}
        });

        localStorage.removeItem('mvpparams');
        localStorage.removeItem('propertyid');
    }

    forgotPassword() {

        this.setState({ isSigninPopup: false, isForgotPassword: true, signinError: '', userMigrationMesssage: '', isUserExist: false, isSuccess: false, errors: {} });
    }

    handleFogotSubmit(event, errors, values) {
        var isValid = true;

        if (values.Email.trim() === "") {
            errors["Email"] = "Email is required";
            isValid = false;
        }

        this.setState({ errors });
        if (isValid) {
            this.setState({ isLoading: true, isSuccess: false, error: '' });
            const data = {
                'UserName': values.Email,
                'RegionId': CONFIG.REGION_ID,
            }

            axios.post(CONFIG.API_URL + 'Account/forgotpassword/', data)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({ isLoading: false, isSuccess: true });
                    }
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response != null && err.response.status === 400) {
                        var error = err.response.data;
                        if (error === "INACTIVE-USER") {
                            error = "Your account is inactive.";
                        }
                        this.setState({ error });
                    }
                    else {
                        const error = "Something went wrong.";
                        this.setState({ error });
                    }
                });
        }
    }

    handleSubmit(event, errors, values) {
        this.props.dispatch(showLoader());
        var isValid = true;

        if (values.UserName.trim() === "") {
            errors["UserName"] = "Email is required";
            isValid = false;
        }
        if (values.Password.trim() === "") {
            errors["Password"] = "Password is required";
            isValid = false;
        }
        this.setState({ errors });
        if (isValid) {
            this.setState({ isLoading: true, signinError: '', userMigrationMesssage: '' });
            const loginIp = {
                'UserName': values.UserName,
                'Password': values.Password,
                'RegionId': CONFIG.REGION_ID
            }

            axios.post(CONFIG.API_URL + 'Account/Secretlogin/', loginIp)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({ isSigninPopup: false, signinError: '' });
                        var data = response.data;
                        var regionid = data.RegionId;
                        if (this.state.isSubmitProperty === true) {
                            if (regionid === CONFIG.REGION_ID) {
                                const { from } = { from: { pathname: "/login/" + data.SecureID } }
                                this.props.parm.children.props.history.push(from);
                            }
                            else {
                                if (regionid === REGIONS.UK) {
                                    window.location.href = CONFIG.LSS_UK + "/login/" + data.SecureID;
                                } else if (regionid === REGIONS.US) {
                                    window.location.href = CONFIG.LSS_US + "/login/" + data.SecureID;
                                }
                            }
                        } else {
                            if (regionid === CONFIG.REGION_ID) {
                                const { from } = { from: { pathname: "/logins/" + data.SecureID } }
                                this.props.parm.children.props.history.push(from);
                            } else {
                                if (regionid === REGIONS.UK) {
                                    window.location.href = CONFIG.LSS_UK + "/logins/" + data.SecureID;
                                } else if (regionid === REGIONS.US) {
                                    window.location.href = CONFIG.LSS_US + "/logins/" + data.SecureID;
                                }
                            }
                        }
                    }
                })
                .catch(err => {
                    if (err.response != null && err.response.status === 400) {
                        const signinError = err.response.data;
                        if (signinError === "PASSWORDRESET-PENDING") {
                            this.setState({ userMigrationMesssage: 'Please check your email to reset your password' });
                        } else {

                            this.setState({ signinError });
                        }
                    }
                    else {
                        const signinError = "Something went wrong.";
                        this.setState({ signinError });
                    }

                });
        }
        else
            this.props.dispatch(hideLoader());
    }

    toggleClose() {
        this.setState({ error: '', isSigninPopup: false, isForgotPassword: false, signinError: '', userMigrationMesssage: '', isSubmitProperty: false });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var token = getToken();
        var userType = getUserType();
        var name = getName();

        this.setState({ token, userType, name });
    }

    handleClickOutside() {
        if (document.getElementById("dvclose")) {
            document.getElementById("dvclose").click();
        }
    }

    signOut() {
        removeLocalStorage();
        var token = getToken();
        this.setState({ token });
        const { from } = { from: { pathname: "/" } }
        this.props.parm.children.props.history.push(from);
    }

    signUp() {
        this.setState({ isSigninPopup: false, isForgotPassword: false, signinError: '' });
        const { from } = { from: { pathname: "/signup" } }
        this.props.parm.children.props.history.push(from);
    }

    onEmailKeyUp(e) {
        var email = e.target.value;

        if (email.trim() !== "") {
            var data = {
                UserEmail: email,
                RegionID: CONFIG.REGION_ID
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            };

            var lthis = this;
            fetch(CONFIG.API_URL + 'Account/signup/validation', requestOptions)
                .then(function (response) {
                    return response.json();
                }).then(function (data) {
                    if (data.User && !data.User.IsLSS) {
                        lthis.setState({ isUserExist: true, userID: data.User.UserID });
                    } else if (!data.User) {
                        if (data.Message !== "No data") {
                            lthis.props.dispatch(setSnackbar(true, "error", data.Message));
                            lthis.form && lthis.form.reset();
                            lthis.formForgot && lthis.formForgot.reset();
                        }
                    }
                }
                )
        }
    }

    parentMethod() {
        this.setState({ isUserExist: false, isSigninPopup: false, isForgotPassword: false });
    }

    render() {
        const { name, userType, token, isOpen, signinError, isSigninPopup, isForgotPassword,
            isSuccess, error, isUserExist, userID, ismvpRedirect, errors, userMigrationMesssage } = this.state;
        return (
            <div className="height-99" onClick={() => this.handleClickOutside(this)}>
                {isUserExist ? <UserRegistration isUserExist={isUserExist} UserID={userID} headerProps={this.props} parentMethod={this.parentMethod} /> : ''}
                <div className="header-top-bar">
                    <div className="header-mobileno">
                        <span className="phone-no">
                            <img src={mobileimg} alt="Sales/Support" />
                            <span> Sales/Support:
                                {CONFIG.REGION_ID === REGIONS.US ?
                                    <a href="tel:313-484-4670"> 313-484-4670</a>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <a href="tel:020 3048 3123"> 020 3048 3123</a>
                                        : <a href="tel:020 3048 3123"> 020 3048 3123</a>}
                            </span>
                        </span>
                    </div>
                    <ul className="header-top-bar-social">
                        <li>
                            {!token ?
                                CONFIG.REGION_ID === REGIONS.US ?
                                    <a href={CONFIG.LSS_UK} className="padding-flag" title="Go to UK site" rel="noreferrer"><img src={uklogo} alt="Go to UK site" /></a>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <a href={CONFIG.LSS_US} className="padding-flag" title="Go to USA site" rel="noreferrer"><img src={uslogo} alt="Go to USA site" /></a>
                                        : ""
                                : ''}
                        </li>
                        <li>
                            <a href={CONFIG.REGION_ID === REGIONS.UK ? 'https://www.linkedin.com/showcase/list-self-storage-uk/'
                                : 'https://www.linkedin.com/company/list-self-storage'} target="blank" rel="noopener noreferrer">
                                <span className="icon-linkedin"></span>
                            </a>
                        </li>
                        <li>
                            <a href={CONFIG.REGION_ID === REGIONS.UK ? 'https://twitter.com/ListStorageUK'
                                : 'https://twitter.com/listselfstorage'} target="blank" rel="noopener noreferrer">
                                <span className="icon-twitter"></span>
                            </a>
                        </li>
                        <li>
                            <a href={CONFIG.REGION_ID === REGIONS.UK ? 'https://www.facebook.com/ListSelfStorageUK/'
                                : 'https://www.facebook.com/List-Self-Storage-1548917092061555/'} target="blank" rel="noopener noreferrer">
                                <span className="icon-facebook"></span>
                            </a>
                        </li>
                        <li className="navlast">
                            {!token ?
                                <span id="loginclick" onClick={this.signIn.bind(this)}>Log In / Sign Up <FontAwesomeIcon icon={faSignInAlt} /></span>
                                : <React.Fragment> <span onClick={this.signOut.bind(this)}> {name} Log Out <FontAwesomeIcon icon={faSignOutAlt} /></span></React.Fragment>}

                        </li>
                    </ul>
                </div>
                <div>
                    <Modal size="md" isOpen={isSigninPopup} toggle={this.toggleClose.bind(this)} className="popup-login confirm-btn create-new edit-market-dashboard">
                        <ModalHeader className="header-confirm" toggle={this.toggleClose.bind(this)}>
                        </ModalHeader>
                        <ModalBody className="overflow-scroll basic-details">

                            <div className="login-lss">
                                <div className='login-close'>
                                    <button type='button' className='close' aria-label="Close">
                                        <span className='login-x-close' onClick={() => this.toggleClose()}>&#10006;</span>
                                    </button>
                                    <h4>Welcome Back</h4>
                                    <h5>Log in to your account</h5>

                                    <AvForm onSubmit={this.handleSubmit.bind(this)} ref={c => (this.formForgot = c)} className="signin-fields">
                                        <AvField name="UserName" label="" placeholder="Email" type="email"
                                            onBlur={(e) => this.onEmailKeyUp(e)}
                                        />
                                        <span className="errorMessage errorposition151"> {errors.UserName}</span>
                                        <AvField name="Password" label="" placeholder="Password" type="password"
                                        />
                                        <span className="errorMessage errorposition205"> {errors.Password}</span>
                                        <div className="margin-b15">
                                            <div className="custom-control custom-checkbox text-left">
                                                <input type="checkbox" className="custom-control-input" id="defaultChecked2" />
                                                <label className="custom-control-label fontsize-15 font-color" for="defaultChecked2">Remember me</label>
                                            </div>
                                            <Link className="text-right fontsize-15" onClick={this.forgotPassword}> Forgot password?</Link>
                                        </div>
                                        <div><Button className="signin-blue">Sign In</Button></div>
                                        {signinError ? <span className="sign-error">{signinError}</span> : ''}
                                        {userMigrationMesssage ? <span className="sign-error">{userMigrationMesssage}</span> : ''}

                                    </AvForm>
                                </div>
                            </div>
                            <div className="login-lss">
                                <div className="line-hr">OR</div>
                            </div>
                            <div className="login-lss">
                                <h4 className="fontsize-20">Create an Account</h4>
                                <h6>Don't have an account? <Link onClick={this.signUp.bind(this)}>Sign up here!</Link></h6>
                            </div>
                            {ismvpRedirect ?
                                <div className="text-skip">
                                    <span onClick={this.getMVPURL.bind(this)}>Skip and purchase report</span>
                                </div>
                                : ''}
                        </ModalBody>
                    </Modal>
                </div>
                <div>
                    <Modal size="md" isOpen={isForgotPassword} toggle={this.toggleClose.bind(this)} className="popup-login confirm-btn create-new edit-market-dashboard">
                        <ModalHeader className="header-confirm" toggle={this.toggleClose.bind(this)}>Forgot password
                        </ModalHeader>
                        <ModalBody className="overflow-scroll basic-details">


                            {isSuccess ?
                                <span className="success">Password reset link has been sent to your email.</span>
                                : ''}

                            <div className="login-lss">
                                <div className='login-close'>
                                    <button type='button' className='close' aria-label="Close">
                                        <span className='login-x-close' onClick={() => this.toggleClose()}>&#10006;</span>
                                    </button>
                                    <h4>Welcome Back</h4>
                                    <h5>Forgot Your Password?</h5>
                                    <p>Enter your account email address and we will send you instructions to reset your password.</p>

                                    <AvForm onSubmit={this.handleFogotSubmit.bind(this)} ref={c => (this.form = c)}>
                                        <AvField name="Email" label="" placeholder="Email" type="email" maxLength="255"
                                            onBlur={(e) => this.onEmailKeyUp(e)}
                                        />
                                        <span className="errorMessage margin-17t"> {errors.Email}</span>
                                        <Button className="signin-blue">Reset Password</Button>
                                        {error !== '' ? <span className="sign-error">{error}</span> : ''}
                                    </AvForm>

                                </div>
                            </div>
                            <div className="login-lss">
                                <div className="line-hr">OR</div>
                            </div>
                            <div className="login-lss">
                                <h4 className="fontsize-20">Create an Account</h4>
                                <h6>Don't have an account? <Link onClick={this.signUp.bind(this)}>Sign up here!</Link></h6>
                            </div>


                        </ModalBody>
                    </Modal>
                </div>
                <div className="menu-bar">
                    <Navbar color="light" light expand="md">
                        <Link to="/" className="logo-100">
                            <img src={lss_logo} alt="List Self Storage" className="logo-lss" />
                        </Link>
                        <NavbarToggler onClick={this.toggle.bind(this)} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <Link to="/" className={window.location.pathname === '/' || window.location.pathname === '/home' ? "headeractive" : ""}>Home</Link>
                                </NavItem>
                                <NavItem className="navhead-menu">
                                    <Link to="/listings" className={window.location.pathname.indexOf('/listings') >= 0 || window.location.pathname.indexOf('/property') >= 0 ? "headeractive" : ""}>Listings</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/vendors" className={window.location.pathname.indexOf('/vendors') >= 0 ? "headeractive" : ""}>Vendors</Link>
                                </NavItem>
                                <NavItem>
                                    <a href="/industry-insights" className={window.location.pathname.indexOf('/industry-insights') >= 0 || window.location.pathname.indexOf('/industry') >= 0 ? "headeractive" : ""} rel="noreferrer">Industry Insights</a>
                                </NavItem>
                                {token ?
                                    <NavItem>
                                        <Link to={userType === USERTYPES.Admin ? "/admin/addproperty" : "/user/addproperty"}>Submit Property</Link>
                                    </NavItem>
                                    :
                                    <NavItem className="navhead-menu">
                                        <Link id="SubmitProperty" onClick={this.signIn.bind(this, "property")}>Submit Property</Link>
                                    </NavItem>
                                }
                                <NavItem>
                                    <Link to="/advertise" className={window.location.pathname.indexOf('/advertise') >= 0 ? "headeractive" : ""}>Advertise</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/aboutus" className={window.location.pathname.indexOf('/aboutus') >= 0 ? "headeractive" : ""}>About Us</Link>
                                </NavItem>
                                {token ?
                                    <NavItem>
                                        <Link to={userType === USERTYPES.Admin ? "/admin/usermanagement" : "/user/dashboard"} className="remove-right-padding">Dashboard</Link>
                                    </NavItem>
                                    : ''}
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>

            </div>
        );
    }
}
export default connect()(Header);