import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label, ModalFooter, Container } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { CONFIG } from '../../../Utils/config';
import Pagination from '@material-ui/lab/Pagination';
import edit from '../../../Assets/Img/edit.png';
import {
    getFormattedFloat, getFormattedInt, getPropertyViewUrl, CURRENCY, normalizeInput,
    PROPERTYSTATUS, USERTYPES, BROKERTYPES, PRICEOPTION, REGIONS, CURRENCYCODE, COUNTRYCODE, COUNTRYID
} from '../../../Utils/utils';
import { getUserType, getBrokerType, getUserID, getEmail, getSecureID } from '../../../Utils/localStorage';
import deleteicon from '../../../Assets/Img/delete.png';
import pstar from '../../../Assets/Img/pstar.png';
import fstar from '../../../Assets/Img/fstar.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import { faSearch, faCheckCircle, faCreditCard, faFileUpload, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import './AddProperty.css';
import Geocode from "react-geocode";
import { PropertyReView } from '../PropertyReView';
import CreditCardInput from 'react-credit-card-input';
import vendor_no from '../../../Assets/Img/ventor-list.png';
import { Editor } from "@tinymce/tinymce-react";
import GoogleMapReact from "google-map-react";
import Dropzone from 'react-dropzone';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import CoBroker from '../CoBroker/CoBroker.js';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import { FileUpload } from '../FileUpload'
import Helmet from '../../../Utils/Helmet';
import MapGL, { Marker, NavigationControl } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import Geocoder from "react-map-gl-geocoder";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { TextField } from '@material-ui/core';

/* stepper for admin */
function getStepsForAdminAdd() {
    return ['Info & Images', 'Features', 'Description', 'Tag Broker', 'Add Co-Broker(s)', 'Review & Submit', 'Confirm'];
}

/* stepper for broker. it has payment */
function getStepsForBrokerAdd() {
    return ['Info & Images', 'Features', 'Description', 'Add Co-Broker(s)', 'Review Property', 'Payment & Submit', 'Confirm'];
}

function getStepsForAdd() {
    return ['Info & Images', 'Features', 'Description', 'Add Co-Broker(s)', 'Review & Submit', 'Confirm'];
}

class AddProperty extends Component {
    static defaultProps = {
        allowedTypes: ['image/jpeg', ' image/jpg', ' image/png', ' image/svg'],
        crop: {
            aspect: 12 / 5,
            width: 200,
            keepSelection: true
        },
        maxFileSize: 15000,
    }

    constructor(props) {
        super(props);

        this.state = {
            property: {},
            userType: '',
            brokerType: 0,
            activeStep: 0,
            steps: [],
            countryList: [],
            stateList: [],
            termList: [],
            propertyImages: [],
            errors: {},
            SecurityCode2: '',
            searchCriteria: {
                Name: '',
                BrokerTypeID: '0',
                RegionId: CONFIG.REGION_ID,
                Page: 1,
                PageLength: CONFIG.PAGE_LENGTH,
                SortBy: '',
                SortOrder: ''
            },
            users: [],
            pageNumUser: 0,
            isPropertyReview: false,
            existingCards: [],
            propertyTypes: [],
            isPropertySaveClose: false,
            alternatePriceList: [],
            isButtonDisabled: false,
            currencyList: [],
            imageToCrop: null,
            imageToCropList: [],
            open: false,
            crop: this.props.crop,
            uploadError: false,
            DeletedImage: [],
            countryData: [],
            stateData: [],
            secureId: getSecureID(),
            focusAfterClose: true,
            aModel: false,
            addressChecked: '',
            setStep: 1,
            editAddress: {},
            addressDetails: {},
            isLoading: false,
            isAddressChanged: false,
            selectedAddress: {},
            stateAbb: '',
            countryId: CONFIG.REGION_ID === REGIONS.US ? COUNTRYID.US : CONFIG.REGION_ID === REGIONS.UK ? COUNTRYID.UK : 0,
            countryID: 0,
            isAddressFound: false,
            StateCountrys: {},
            showState: true,
            filteredState: [],
            showStates: true,
            filteredStates: [],
            filteredCountry: [],
            isPriceAuction: false,
            selectedAddressD: {},
            viewport: {
                width: "100%",
                height: 650,
                longitude: CONFIG.REGION_ID === REGIONS.UK ? -4.5364 : -107.2985,
                latitude: CONFIG.REGION_ID === REGIONS.UK ? 54.2363 : 41.7517,
                zoom: CONFIG.REGION_ID === REGIONS.UK ? 4 : 2,
            },
            checkCoordinatesValidOrNot: false,
            isClickedOrNotState: false,
            bestMatchInfo: false,
            AddressChange: false,
            iscompleted: false,
            showFile: false,
            docID: "",
            docTitle: "",
            propertyDocument: [],
        }

        this.onAuctionDateChange = this.onAuctionDateChange.bind(this);
        this.validatePropertyInfo = this.validatePropertyInfo.bind(this);
        this.validateProperty = this.validateProperty.bind(this);
        this.validatePropertyFeatures = this.validatePropertyFeatures.bind(this);
        this.validatePropertyPrice = this.validatePropertyPrice.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.accountModal = this.accountModal.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handler = this.handler.bind(this);
        this.getPropertyDocument = this.getPropertyDocument.bind(this);
        this.mapRef = React.createRef();
        // this.geocoderContainerRef = React.createRef();
    }

    componentDidMount() {
        const { searchCriteria, filteredCountry } = this.state;

        Geocode.setApiKey(CONFIG.GeolocationApiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        this.getLookupBrokerTypes();
        this.getLookupPropertyTypes();
        this.getLookupData(filteredCountry);
        var userType = getUserType();
        var brokerType = getBrokerType();

        this.getLookupTerms();
        this.getExistingCards();
        this.getAlternatePriceOption();
        this.getBrokers(searchCriteria);
        this.getLookupCurrency();

        this.setState({ userType, brokerType }, () => {
            if (this.StripePayment()) {
                if (this.props.match.params.propertyId) {
                    this.getPropertyDetail(this.props.match.params.propertyId);

                } else {
                    var skipPayment = userType !== USERTYPES.Admin && brokerType === BROKERTYPES.PremiumBroker ? true : false;

                    // this.setState({ skipPayment });

                    var property = this.initializePropertyObject();
                    this.setState({ property, skipPayment }, () => {
                        this.getLookupData(property);
                        this.getPropertyCode(property);
                        this.setStepper(userType, skipPayment);
                        if (!this.props.match.params.propertyId) {
                            // get user billing detail
                            this.validateEmail(null, getEmail());
                        }

                    });
                    // this.setState({ skipPayment });

                    // this.getPropertyCode(property);
                    // this.setStepper(userType, skipPayment);
                }

            } else {
                //this.getPropertyDetail(this.props.match.params.propertyId)
                let data = {
                    regionid: CONFIG.REGION_ID,
                    PropertyID: this.props.match.params.propertyId,
                    sessionId: this.props.match.params.sessionId,
                    ChangePropertyToStatus: this.props.match.params.statusChangeTo
                }
                let steps = getStepsForBrokerAdd();
                let property = this.initializePropertyObject();
                if (this.props.match.params.isPropertyAdd === "1") property.IsPropertyAdd = true;
                else property.IsPropertyAdd = false;
                property.PropertyName = "Property";
                property.PropertyId = data.PropertyID;
                property.ChangePropertyToStatus = data.ChangePropertyToStatus;
                property.IsStripe = true;
                this.setState({ steps, property });
                if (this.props.match.path.includes("success")) {
                    axios.post(CONFIG.API_URL + 'property/addnewstripe', data)
                        .then(response => {
                            localStorage.removeItem('SURL');
                            if (response.status !== 200) {
                                this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                            }
                        }).catch(
                            err => {
                                if (err.response !== null && err.response !== undefined) {
                                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                                }
                                else {
                                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                                }
                            }
                        )
                    this.setStep(6);
                }
                else {
                    this.setStep(5);
                }

            }
        }
        );
    }

    StripePayment() {
        if (CONFIG.REGION_ID === REGIONS.US) {
            if (this.props.match.path.includes("success") || this.props.match.path.includes("cancel")) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    initGeocoder = (map, maps) => {
        const geocoder = new maps.Geocoder();

        this.setState({
            geocoder: geocoder,
            map: map,
            maps: maps,
        })
    };

    setStepper(userType, skipPayment) {
        var steps = [];

        if (userType === USERTYPES.Admin) {
            steps = getStepsForAdminAdd();
        } else {
            if (skipPayment) {
                steps = getStepsForAdd();
            } else {
                steps = getStepsForBrokerAdd();
            }
        }

        this.setState({ steps });
    }

    setStep(stage) {
        this.setState({ activeStep: stage });
    }

    getAlternatePriceOption() {
        axios.get(CONFIG.API_URL + 'lookup/property/alternateprice').then(response => {
            if (response.status === 200) {
                this.setState({ alternatePriceList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    handler(data) {
        this.setState({ propertyDocument: data });
    }

    getPropertyDocument(propertyId) {
        axios.get(CONFIG.API_URL + 'property/document/' + propertyId).then(response => {
            if (response.status === 200) {
                this.setState({ propertyDocument: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getPropertyCode(property) {
        axios.get(CONFIG.API_URL + 'property/code').then(response => {
            if (response.status === 200) {
                property.PropertyCode = response.data;
                this.setState({ property });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getLookupBrokerTypes() {
        axios.get(CONFIG.API_URL + 'lookup/broker/types').then(response => {
            if (response.status === 200) {
                this.setState({ userTypes: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }
    getAddressDetails() {
        const { selectedAddress, isAddressChanged } = this.state;
        let CountryListData = this.state.selectedAddress.CountryCodeAlpha2 || this.state.selectedAddress.State !== undefined ? this.state.selectedAddress.CountryCodeAlpha2 : this.state.property.UserDetail.BillingCountry;
        let Selectcountry = CountryListData;
        Selectcountry = this.state.countryData.filter(x => x.CountryCode === CountryListData).map(x => x.CountryCode)[0];
        let stateDetails = [];
        if (Selectcountry !== undefined && Selectcountry !== null && Selectcountry !== '') {
            // State's country code based country details
            stateDetails = this.state.stateData.filter(function (data) {
                return data.CountryCode === Selectcountry;
            });
        }
        this.setState({ isLoading: true, filteredStates: stateDetails, showStates: stateDetails && stateDetails.length > 0 ? CountryListData === 'England' || CountryListData === 'Scotland' || CountryListData === 'Northern Ireland' || CountryListData === 'Wales' || CountryListData === 'GB' ? false : true : false });
        let token = localStorage.getItem("accessKey");
        // setAddressCall([]);
        if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }
        axios
            .get(CONFIG.API_URL + "vault/customer/detail/" + CONFIG.REGION_ID)
            .then(response => {
                if (response.status === 200) {
                    let address = response.data.Addresses;
                    let seleAdd = address.filter(this.state.isAddressChanged && this.state.selectedAddress.Id !== undefined ? x => x.Id === this.state.selectedAddress.Id : x => x.IsDefault === true)[0];
                    this.setState({ addressDetails: address, isLoading: false, addressChecked: seleAdd.Id, selectedAddressD: this.state.selectedAddress });
                    this.setState({
                        selectedAddress: {
                            Id: seleAdd.Id,
                            Address1: seleAdd.Address1,
                            Address2: seleAdd.Address2,
                            City: seleAdd.City,
                            State: seleAdd.State,
                            ZipCode: seleAdd.ZipCode,
                            Country: seleAdd.CountryName,
                            CountryCodeAlpha2: seleAdd.CountryCodeAlpha2
                        }

                    });
                }

            })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getLookupPropertyTypes() {
        axios.get(CONFIG.API_URL + 'lookup/propertytypes').then(response => {
            if (response.status === 200) {
                this.setState({ propertyTypes: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getLookupTerms() {
        axios.get(CONFIG.API_URL + 'lookup/terms').then(response => {
            if (response.status === 200) {
                this.setState({ termList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getLookupCurrency() {
        axios.get(CONFIG.API_URL + 'lookup/currency/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                this.setState({ currencyList: response.data });
            }
        })
            .catch(err => {
            });
    }

    validateEmail(e, email) {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        var valid = e ? re.test(String(e.target.value).toLowerCase()) : re.test(String(email).toLowerCase());

        if (valid) {
            this.props.dispatch(showLoader());

            const { property } = this.state;

            const reqData = {
                UserEmail: e ? e.target.value : email,
                RegionID: CONFIG.REGION_ID,
                SecureId: this.state.userSecureId === '' ? null : this.state.secureId
            };

            axios.post(CONFIG.API_URL + 'marketreport/validatereportemail', reqData)
                .then(response => {
                    if (response.data.User.UserID !== 0) {
                        this.props.dispatch(hideLoader());
                        const data = response.data.User;
                        let UserDetail = property.UserDetail;
                        let countryCodes = this.state.isAddressChanged === true ? this.state.property.UserDetail.BillingCountrys === 'England' ? 'GB' : this.state.property.UserDetail.BillingCountrys : data.BillingCountryCode === 'England' || data.BillingCountryCode === 'Scotland' || data.BillingCountryCode === 'Northern Ireland' || data.BillingCountryCode === 'Wales' ? 'GB' : data.BillingCountryCode;
                        UserDetail["Login"] = getEmail();
                        UserDetail["FirstName"] = data.FirstName;
                        UserDetail["LastName"] = data.LastName;
                        UserDetail["Company"] = data.Company;
                        UserDetail["BillingPhone"] = data.Phone ? normalizeInput(data.Phone) : '';
                        UserDetail["BillingAddress"] = data.BillingAddress;
                        UserDetail["BillingAddress2"] = this.state.property.UserDetail.Billing_Address2 ? this.state.property.UserDetail.Billing_Address2 : data.BillingAddress2;
                        UserDetail["BillingCity"] = data.BillingCity;
                        UserDetail["State"] = data.BillingState;
                        UserDetail["BillingState"] = this.state.showState ? data.BillingState : '';
                        // UserDetail['BillingState'] = this.state.showState ? data.BillingState :'';
                        UserDetail['BillingCountry'] = data.BillingCountry;
                        UserDetail['BillingCountryCode'] = countryCodes;
                        UserDetail["BillingZipCode"] = this.state.property.UserDetail.Bt_Zipcode ? this.state.property.UserDetail.Bt_Zipcode : data.BillingZipCode;
                        UserDetail["SecureID"] = this.state.secureId;
                        UserDetail['PaymentCustomerAddressId'] = this.state.isAddressChanged ? this.state.selectedAddress.Id !== undefined && this.state.selectedAddress.id !== '' ? this.state.selectedAddress.id : null : null;

                        if (data.BillingCountry) {
                            var sc_code = data.BillingCountry;

                            let countryDetails = this.state.countryData.filter(function (data) {
                                return data.CountryID === sc_code;
                            });

                            let stateDetails = this.state.stateData.filter(function (data) {
                                return data.CountryCode === countryDetails[0].CountryCode;
                            });

                            UserDetail.BillingCountry = countryDetails[0].CountryCode;

                            this.setState({ filteredStates: stateDetails, showStates: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false });
                        }

                        this.setState(prevState => ({
                            ...prevState,
                            property: {
                                ...prevState.property,
                                UserDetail: UserDetail
                            }
                        }));
                        // property.UserDetail = UserDetail;
                        // this.setState({ property: property });
                    }

                    this.props.dispatch(hideLoader());

                    // const x = document.getElementById("FirstName");
                    // if (x && x != null) {
                    //     x.focus();
                    // }
                })
                .catch(err => {
                    this.props.dispatch(hideLoader());

                    if (err.response !== null && err.response !== undefined) {
                        property.UserDetail = this.initializeUserObject();

                        this.setState({ property: property });
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
    }

    getLookupData(property) {
        //States and Country
        let stateValue = 1;//CONFIG.REGION_ID === 3 ? 1 : 0;
        axios.get(CONFIG.API_URL + 'marketreport/lookupdata/' + CONFIG.REGION_ID + '/' + stateValue)
            .then(response => {
                let stateData = response.data.lookups.StatesList;
                let countryData = response.data.lookups.CountryList;

                property.CountryCode = countryData.filter(x => x.CountryID === this.state.countryId).map(x => x.CountryCode)[0];
                let stateDetails = [];
                if (property.CountryCode !== undefined && property.CountryCode !== null && property.CountryCode !== '') {
                    // State's country code based country details
                    stateDetails = stateData.filter(function (data) {
                        return data.CountryCode === property.CountryCode;
                    });
                }
                this.setState({ stateData, countryData, filteredCountry: property, filteredState: stateDetails, showState: stateDetails && stateDetails.length > 0 ? property.CountryCode === 'England' || property.CountryCode === 'Scotland' || property.CountryCode === 'Northern Ireland' || property.CountryCode === 'Wales' ? false : true : false })
            })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getExistingCards() {
        let token = localStorage.getItem('accessKey');
        if (token && !this.state.isAdmin && !this.state.skipPayment) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

            axios.get(CONFIG.API_URL + 'vault/cards/' + CONFIG.REGION_ID).then(response => {
                if (response.status === 200) {
                    this.setState({ existingCards: response.data });
                }
            })
                .catch(err => {
                });
        }
    }

    initializePropertyObject() {

        var property = {
            //Info
            RegionID: CONFIG.REGION_ID,
            PropertyId: 0,
            PropertyTypeId: "0",
            PropertyName: '',
            PropertyCode: '',
            Address1: '',
            City: '',
            CountryId: this.state.countryId,
            CountryCode: this.state.filteredCountry ? this.state.filteredCountry : '',
            StateId: '',
            ZipCode: '',
            IsSingleProperty: false,
            IsPortfolio: false,
            //Features
            NSRF: '',
            GSF: '',
            BuildingSize: '',
            CCArea: '',
            YearBuilt: '',
            LotSize: '',
            LotSizeMeasurementId: '',
            UnitCount: '',
            NumberofFloors: '',
            CeilingHeight: '',
            NOI: '',
            CapRate: '',
            IsRoomForExpansion: false,
            Occupancy: null,
            //Description
            Description: '',
            IsSale: false,
            IsAuction: false,
            AlternatePriceOptionDate: null,
            AlternatePriceOptionID: "0",
            AlternatePriceOptionDateStr: null,
            Price: '',
            //
            IsPropertyAdd: true,
            PropertyStatusId: PROPERTYSTATUS.Draft,
            BrokerId: '',
            PaymentDetail: this.initializePaymentObject(),
            UserDetail: this.initializeUserObject(),
            TermId: 0,
            ExpiryDate: '',
            PriceCurrency: CONFIG.REGION_ID === REGIONS.UK ? CURRENCYCODE.UK : CURRENCYCODE.US,
            NOICurrency: CONFIG.REGION_ID === REGIONS.UK ? CURRENCYCODE.UK : CURRENCYCODE.US,
            PrimaryImage: ''
        };
        this.setState({ property });
        return property;
    }

    initializePaymentObject() {
        var obj = {
            CardName: '',
            CardNumber: '',
            ExpiryYear: '',
            ExpiryMonth: '',
            SecurityCode: '',
            Expiry: '',
            "IsDefault": true,
            Token: ''
        }
        return obj;
    }

    initializeUserObject() {
        var obj = {
            Login: '',
            FirstName: '',
            LastName: '',
            Company: '',
            BillingPhone: '',
            BillingAddress: '',
            BillingAddress2: '',
            BillingCity: '',
            BillingState: '',
            BillingZipCode: '',
            BillingCountry: ''
        }
        return obj;
    }

    getPropertyDetail(propertyId) {
        const { userType, brokerType } = this.state;

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'property/review/listing/' + propertyId).then(response => {
            if (response.status === 200) {
                var property = response.data;

                if (this.props.match.params.statusId) {
                    property.ChangePropertyToStatus = this.props.match.params.statusId;
                } else {
                    property.ChangePropertyToStatus = PROPERTYSTATUS.Approved;
                }

                var propertyImages = property.PropertyImages ? Array.from(property.PropertyImages.split(',')) : [];
                property = this.formatProperty(property);
                property.IsPropertyAdd = false;
                property.RegionID = CONFIG.REGION_ID;
                if (property.AlternatePriceOptionID === 5 || property.AlternatePriceOptionID === 6) {
                    this.setState({ isPriceAuction: true })
                }
                //skip payment for admin,premium broker, card details updated broker/unauthorized broker.
                var skipPayment = true;
                if (userType !== USERTYPES.Admin) {
                    if (brokerType !== BROKERTYPES.PremiumBroker) {
                        if (CONFIG.REGION_ID === REGIONS.UK) {
                            if (brokerType === BROKERTYPES.Unauthorized && !property.UserCardToken) {
                                skipPayment = false;
                            }
                        } else {
                            if (brokerType === BROKERTYPES.Unauthorized && !property.UserCardToken) {
                                if (property.IsStripe === true && (property.PropertyStatusId === PROPERTYSTATUS.Approved
                                    || property.PropertyStatusId === PROPERTYSTATUS.PendingApproval)) {
                                    skipPayment = true
                                } else {
                                    skipPayment = false;
                                }
                            }
                        }

                        if (CONFIG.REGION_ID === REGIONS.UK) {
                            if (brokerType === BROKERTYPES.Broker) {
                                if (!property.UserCardToken) {
                                    skipPayment = false;
                                } else if (property.PropertyStatusId === PROPERTYSTATUS.Inactive) {
                                    skipPayment = false;
                                } else if (property.PropertyStatusId === PROPERTYSTATUS.Closed) {
                                    skipPayment = false;
                                }
                            }
                        }
                        else {
                            if (brokerType === BROKERTYPES.Broker) {
                                if (!property.UserCardToken && property.IsStripe !== true) {
                                    skipPayment = false;
                                } else if (property.PropertyStatusId === PROPERTYSTATUS.Inactive) {
                                    skipPayment = false;
                                } else if (property.PropertyStatusId === PROPERTYSTATUS.Closed) {
                                    skipPayment = false;
                                }
                                if (property.PropertyStatusId === PROPERTYSTATUS.Draft && property.IsStripe === true) {
                                    skipPayment = false;
                                }
                            }
                        }
                    }
                }

                if (!property.PaymentDetail) {
                    property.PaymentDetail = this.initializePaymentObject();
                } else {
                    //have to code here for saved card
                }

                if (!property.UserDetail) {
                    property.UserDetail = this.initializeUserObject();
                }
                this.setState({ property, propertyImages, skipPayment }, () => {
                    this.setStepper(userType, skipPayment);
                    // get user billing detail
                    this.validateEmail(null, getEmail());
                });
                this.setState({
                    isClickedOrNotState: true
                }, () => this.updateViewPort(Number(property.Longitude), Number(property.Latitude)));
            }
            if (response.status === 204) {
                this.props.dispatch(setSnackbar(true, "error", "Invalid Request"));
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    submitFileName(event, errors, values) {
        if (values.Title.trim() !== "") {
            let data = {
                ID: this.state.docID,
                PropertyID: this.state.property.PropertyId,
                Title: values.Title
            }
            axios.post(CONFIG.API_URL + 'property/document/title', data)
                .then((res) => {
                    const propertyDocument = this.state.propertyDocument.map((item) =>
                        item.ID === this.state.docID ? { ...item, Title: values.Title } : item
                    )
                    this.setState({ docId: '', docTitle: '', showFile: false, propertyDocument });
                    this.props.dispatch(setSnackbar(true, "success", "Visible name changed successfully"));
                }).catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
    }

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({ activeStep: activeStep - 1 });
        if (activeStep === 1) this.setState({ isClickedOrNotState: true });
    };
    handleClosed() {
        const { selectedAddressD } = this.state;
        this.setState({ aModel: false, activeStep: 5, selectedAddress: selectedAddressD });
    }

    cancelProperty() {
        this.props.dispatch(setSnackbar(false));
        const { userType } = this.state;
        var path = "";
        if (userType === USERTYPES.Broker) {
            path = "/user/properties";
        } else {
            path = "/admin/properties";
        }

        const { from } = { from: { pathname: path } };
        this.props.history.push(from);
    }

    markPrimaryImage(image) {

        const { property } = this.state;

        property.PrimaryImage = image;

        this.setState({ property });
    }

    removePropertyImages(image) {

        var propertyImages = this.state.propertyImages;
        const { property, DeletedImage } = this.state;

        DeletedImage.push(image);

        var indexOfPropertyImages = propertyImages.indexOf(image);
        if (indexOfPropertyImages > -1) {
            propertyImages.splice(indexOfPropertyImages, 1);
        } else {
            propertyImages.push(image);
        }

        if (image === property.PrimaryImage) {
            if (propertyImages.length > 0) {
                property.PrimaryImage = propertyImages[0];
            } else {
                property.PrimaryImage = '';
            }
        }
        this.setState({ propertyImages, property, DeletedImage });
    }

    onAuctionDateChange(date) {
        var today = new Date(date);
        const { property, errors } = this.state;
        property.AlternatePriceOptionDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        errors["AlternatePriceOptionDate"] = "";
        this.setState({ property, errors });
    }

    onKeyChange(e) {
        let property = this.state.property;
        let errors = this.state.errors;
        if (e.target.name === "Address1" || e.target.name === "City" || e.target.name === "ZipCode") {
            this.setState({ checkCoordinatesValidOrNot: false, isClickedOrNotState: false, AddressChange: true });
        }
        property[e.target.name] = e.target.value;
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        this.setState({
            property, errors
        });
    }

    ShowInfo() {
        confirmAlert({
            message: 'Exact address not found. The center point is based on the best match. Use the map to adjust the pinpoint if required.',
            buttons: [
                {
                    label: 'OK',
                }
            ]
        });
    }

    compareStrings(str1, str2) {
        const m = str1.length;
        const n = str2.length;
        const dp = [];
        for (let i = 0; i <= m; i++) {
            dp[i] = [];
            dp[i][0] = i;
        }
        for (let j = 0; j <= n; j++) {
            dp[0][j] = j;
        }
        for (let i = 1; i <= m; i++) {
            for (let j = 1; j <= n; j++) {
                if (str1.charAt(i - 1) === str2.charAt(j - 1)) {
                    dp[i][j] = dp[i - 1][j - 1];
                } else {
                    dp[i][j] = Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]) + 1;
                }
            }
        }
        const threshold = Math.floor(Math.max(m, n) * 0.33);
        return dp[m][n] <= threshold;
    }

    handleSearchResult = (result) => {
        let features = result.result;
        let streetAddress = "";
        if (features.place_type[0] === "address") streetAddress = result.result.place_name.split(', ').shift();
        else if (features.properties.address !== undefined) streetAddress = features.properties.address;
        let city = '';
        let state = '';
        let zipcode = '';
        let country = '';
        for (let i = 0; i < features.context.length; i++) {
            let context = features.context[i];
            if (context.id.startsWith('place')) {
                city = context.text;
            } else if (context.id.startsWith('region')) {
                state = context.text;
            } else if (context.id.startsWith('postcode')) {
                zipcode = context.text;
            } else if (context.id.startsWith('country')) {
                country = context.short_code;
            }
        }

        if (country === "us" || country === "gb") {
            let latLng = result.result.center;
            this.updateViewPort(Number(latLng[0]), Number(latLng[1]), "");
            state = this.state.filteredState.find(x => x.State === state)?.StateCode;
            let property = this.state.property;
            property.Address1 = streetAddress;
            property.City = city;
            property.ZipCode = zipcode;
            property.StateId = state;
            this.setState({ property, AddressChange: true, GeocoderActive: false });
        } else {
            setTimeout(() => {
                this.resetViewPort();
            }, 1000);
        }
    }
    handleViewportChange = (viewport) => {
        this.setState({ viewport });
    }
    handleGeocoderViewportChange = (newViewport) => {
        this.setState({ viewport: newViewport });
    };

    handleSearch(value) {
        let { property, isClickedOrNotState, bestMatchInfo, AddressChange } = this.state;
        if (value === "makeTheSearch") {
            isClickedOrNotState = false;
            AddressChange = false;
        }
        let state = "";
        let search_text = "";
        let country = "us";
        //let errorTextForIncorrectAddress = "Exact address not found. The center point is based on the best match. Use the map to adjust the pinpoint if required.";
        if (CONFIG.REGION_ID !== REGIONS.US) {
            search_text = property.Address1 + ',' + property.City + ',' + property.ZipCode;
            search_text = search_text.trim().toLowerCase();
            country = "gb";
        } else {
            if (property.stateId !== "0") state = this.state.filteredState.find(x => x.StateCode === property.StateId);
            search_text = property.Address1 + ',' + property.City + ',' + state?.State + ',' + property.ZipCode;
            search_text = search_text.trim().toLowerCase();
        };
        let city = property.City.trim().toLowerCase();
        let zipCode = property.ZipCode.trim().toLowerCase();
        let Address = property.Address1.trim().toLowerCase();
        let StateName = state?.State?.trim().toLowerCase();
        let mapboxUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(search_text)}.json?country=${country}&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
        if (this.validateAddressMap()) {
            axios.get(mapboxUrl)
                .then(res => {
                    let { data } = res;
                    let sortedAddressArray = data.features.sort((a, b) => b.relevance - a.relevance);
                    // let filteredDataOnZipNCity = sortedAddressArray.filter(s => s.place_name.toLowerCase().includes(city) && s.place_name.toLowerCase().includes(zipCode)).sort((a, b) => b.relevance - a.relevance)[0];
                    // let filteredDataOnAll = sortedAddressArray.filter(s => s.place_name.toLowerCase().includes(city) && s.place_name.toLowerCase().includes(zipCode) && s.place_name.toLowerCase().includes(Address) && s.place_name.toLowerCase().includes(StateName)).sort((a, b) => b.relevance - a.relevance)[0];

                    let returnedAddressValue = sortedAddressArray.filter(filterPlace => {
                        if (CONFIG.REGION_ID === REGIONS.US) {
                            let fullAddressUS = (this.compareStrings(Address, filterPlace.place_name.split(",").shift().toLowerCase()) && filterPlace.place_name.toLowerCase().includes(StateName) && filterPlace.place_name.toLowerCase().includes(city) && filterPlace.place_name.toLowerCase().includes(zipCode));
                            if (fullAddressUS) {
                                return filterPlace;
                            }
                            else {
                                let matchedTwoInputes = (this.compareStrings(Address, filterPlace.place_name.split(",").shift().toLowerCase()) && filterPlace.place_name.toLowerCase().includes(StateName))
                                    || (this.compareStrings(Address, filterPlace.place_name.split(",").shift().toLowerCase()) && filterPlace.place_name.toLowerCase().includes(city))
                                    || (this.compareStrings(Address, filterPlace.place_name.split(",").shift().toLowerCase()) && filterPlace.place_name.toLowerCase().includes(zipCode))
                                    || (filterPlace.place_name.toLowerCase().includes(city) && filterPlace.place_name.toLowerCase().includes(StateName))
                                    || (filterPlace.place_name.toLowerCase().includes(city) && filterPlace.place_name.toLowerCase().includes(zipCode))
                                    || (filterPlace.place_name.toLowerCase().includes(StateName) && filterPlace.place_name.toLowerCase().includes(zipCode));
                                if (matchedTwoInputes) {
                                    return filterPlace;
                                } else {
                                    if (filterPlace.matching_place_name !== undefined) {
                                        let matchingPlaceName = (this.compareStrings(Address, filterPlace.matching_place_name.split(",").shift().toLowerCase()) && filterPlace.matching_place_name.toLowerCase().includes(StateName))
                                            || (this.compareStrings(Address, filterPlace.matching_place_name.split(",").shift().toLowerCase()) && filterPlace.matching_place_name.toLowerCase().includes(city))
                                            || (this.compareStrings(Address, filterPlace.matching_place_name.split(",").shift().toLowerCase()) && filterPlace.matching_place_name.toLowerCase().includes(zipCode))
                                            || (filterPlace.matching_place_name.toLowerCase().includes(city) && filterPlace.matching_place_name.toLowerCase().includes(StateName))
                                            || (filterPlace.matching_place_name.toLowerCase().includes(city) && filterPlace.matching_place_name.toLowerCase().includes(zipCode))
                                            || (filterPlace.matching_place_name.toLowerCase().includes(StateName) && filterPlace.matching_place_name.toLowerCase().includes(zipCode));
                                        if (matchingPlaceName) return filterPlace;
                                    }
                                }
                            }
                        } else {
                            let fullAddressUK = (this.compareStrings(Address, filterPlace.place_name.split(",").shift().toLowerCase()) && filterPlace.place_name.toLowerCase().includes(city) && filterPlace.place_name.toLowerCase().includes(zipCode));
                            if (fullAddressUK) {
                                return filterPlace;
                            }
                            else {
                                let matchedTwoInputes = (this.compareStrings(Address, filterPlace.place_name.split(",").shift().toLowerCase()) && filterPlace.place_name.toLowerCase().includes(city))
                                    || (this.compareStrings(Address, filterPlace.place_name.split(",").shift().toLowerCase()) && filterPlace.place_name.toLowerCase().includes(zipCode))
                                    || (filterPlace.place_name.toLowerCase().includes(city) && filterPlace.place_name.toLowerCase().includes(zipCode));
                                if (matchedTwoInputes) {
                                    return filterPlace;
                                }
                                else {
                                    if (filterPlace.matching_place_name !== undefined) {
                                        let matchingPlaceName = (this.compareStrings(Address, filterPlace.matching_place_name.split(",").shift().toLowerCase()) && filterPlace.matching_place_name.toLowerCase().includes(city))
                                            || (this.compareStrings(Address, filterPlace.matching_place_name.split(",").shift().toLowerCase()) && filterPlace.matching_place_name.toLowerCase().includes(zipCode))
                                            || (filterPlace.matching_place_name.toLowerCase().includes(city) && filterPlace.matching_place_name.toLowerCase().includes(zipCode));
                                        if (matchingPlaceName) return filterPlace;
                                    }
                                }
                            }
                        }
                        return undefined;
                    }).sort((a, b) => b.relevance - a.relevance)[0];
                    if (returnedAddressValue) {
                        let isMathchedFullAddressOrNot = CONFIG.REGION_ID === REGIONS.US ? (this.compareStrings(Address, returnedAddressValue.place_name.split(",").shift().toLowerCase()) && returnedAddressValue.place_name.toLowerCase().includes(StateName) && returnedAddressValue.place_name.toLowerCase().includes(city) && returnedAddressValue.place_name.toLowerCase().includes(zipCode)) : (this.compareStrings(Address, returnedAddressValue.place_name.split(",").shift().toLowerCase()) && returnedAddressValue.place_name.toLowerCase().includes(city) && returnedAddressValue.place_name.toLowerCase().includes(zipCode));
                        if (!isMathchedFullAddressOrNot) {
                            if (!isClickedOrNotState && !AddressChange) {
                                this.ShowInfo();
                                this.setState({ bestMatchInfo: false });
                            }
                            else if (!bestMatchInfo && AddressChange) {
                                this.ShowInfo();
                                this.setState({ bestMatchInfo: true });
                            }
                            else {
                                this.setState({ bestMatchInfo: false });
                            }
                        }
                        else this.props.dispatch(setSnackbar(false));
                        let { coordinates } = returnedAddressValue.geometry;
                        if (!isClickedOrNotState) {
                            property.Latitude = coordinates[1];
                            property.Longitude = coordinates[0];
                        }
                        this.setState({ checkCoordinatesValidOrNot: true });
                        // if (value === "handleNext") this.handleNext('Next');
                        if (!isClickedOrNotState || value !== "") this.updateViewPort(Number(property.Longitude), Number(property.Latitude), value);
                    }
                    else {
                        this.props.dispatch(hideLoader());
                        this.props.dispatch(setSnackbar(true, "error", "Address not found"));
                        this.setState({ checkCoordinatesValidOrNot: false, isClickedOrNotState: false, bestMatchInfo: false }, () => this.resetViewPort());
                    }
                }).catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
    }

    resetViewPort() {
        this.setState(prevState => ({
            ...prevState,
            viewport: {
                ...prevState.viewport,
                longitude: CONFIG.REGION_ID === REGIONS.UK ? -4.5364 : -107.2985,
                latitude: CONFIG.REGION_ID === REGIONS.UK ? 54.2363 : 41.7517,
                zoom: CONFIG.REGION_ID === REGIONS.UK ? 4 : 2,
            },
            property: {
                ...prevState.property,
                Latitude: undefined,
                Longitude: undefined
            }
        }));
    }

    updateViewPort(lng, lat, isHandleNextOrNot) {
        if ((lng !== undefined && lat !== undefined) && (!isNaN(lng) && !isNaN(lat))) {
            this.setState(prevState => ({
                ...prevState,
                viewport: {
                    ...prevState.viewport,
                    longitude: lng,
                    latitude: lat,
                    zoom: 14,
                },
                property: {
                    ...prevState.property,
                    Latitude: lat,
                    Longitude: lng
                }
            }), () => { if (isHandleNextOrNot === "handleNext") this.handleNext('Next') });
        }
    }
    onKeyNumberChange(e) {
        const { property, errors } = this.state;
        property[e.target.name] = getFormattedInt(e.target.value.replace(/,/g, ''));
        if (property[e.target.name] !== "") {
            errors[e.target.name] = "";
        }
        this.setState({ property, errors });
    }

    onKeyFloatChange(e) {
        const { property, errors } = this.state;
        property[e.target.name] = getFormattedFloat(e.target.value.replace(/,/g, ''));
        if (property[e.target.name] !== "") {
            errors[e.target.name] = "";
        }
        this.setState({ property, errors });
    }

    onKeyPress(e) {
        const re = /[0-9,.]+/g;
        if (e.key !== "Enter") {
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }
    }

    onKeyPressPercentage(e) {
        const re = /[0-9,.]+/g;
        var str = e.target.value;
        if (!re.test(e.key)) {
            e.preventDefault();
        } else {
            str = str + e.key;
            if (str > 100) {
                e.preventDefault();
            }
        }
    }

    onKeyPressOnlyNumber(e) {
        const re = /[0-9]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    }

    onPaste(e) {
        const re = /^[0-9,.]*$/;
        const str = e.clipboardData.getData('Text');
        if (!re.test(str)) {
            e.preventDefault();
        }
    }

    onPastePercentage(e) {
        const re = /^[0-9.]*$/;
        var str = e.clipboardData.getData('Text');
        var val = e.target.value;
        if (!re.test(str)) {
            e.preventDefault();
        } else {
            str = val + str;
            if (str > 100) {
                e.preventDefault();
            }
        }
    }


    onPasteOnlyNumber(e) {
        const re = /^[0-9]*$/;
        const str = e.clipboardData.getData('Text');
        if (!re.test(str)) {
            e.preventDefault();
        }
    }

    onDDLChange(e) {
        let property = this.state.property;
        let errors = this.state.errors;
        property[e.target.name] = e.target.value;

        // if (e.target.value !== "0") {
        //     errors[e.target.name] = "";
        // }
        if (e.target.name === "PropertyTypeId" && e.target.value !== "0") {
            errors["PropertyTypeId"] = "";
            this.setState({ errors });
        }
        if (e.target.name === "StateId" && e.target.value !== "0") {
            errors["StateId"] = "";
            this.setState({ checkCoordinatesValidOrNot: false, isClickedOrNotState: false, AddressChange: true });
            this.setState({ errors });
        }

        if (e.target.name === "CountryCode") {
            var sc_code = e.target.value;
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.CountryCode === sc_code
            });

            // State's country code based country details
            let stateDetails = this.state.stateData.filter(function (data) {
                return data.CountryCode === sc_code
            });

            //Adding Country name
            property['CountryCode'] = stateDetails.length > 0 ? countryDetails[0].CountryCode : '';
            property['StateId'] = this.state.stateData.filter(property.CountryCode === property.StateId).map(x => x.StateCode)[0];
            // property['BillingCountryCode'] =  countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : '';
            this.state.property.CountryId = countryDetails[0].CountryID;
            this.state.property.CountryCode = countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? "GB" : countryDetails[0].CountryCode : countryDetails[0].CountryCode;
            this.setState({
                property, errors, filteredState: stateDetails, showState: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false, CountryId: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryID : null,
            });
            // this.setState({
            //     property, errors
            // });
        } else {
            this.setState({
                property, errors
            },
                // () => {
                //     if (e.target.name === "StateId") {
                //         this.handleInputDelayApiCall();
                //     }
                // }
            );
        };
    }

    alternatePriceOption(e) {
        let property = this.state.property;
        let errors = this.state.errors;
        let isPriceAuction = this.state.isPriceAuction;
        property[e.target.name] = e.target.value;
        isPriceAuction = (e.target.value === "5" || e.target.value === "6") ? true : false;
        property.AlternatePriceOptionDate = null;
        if (e.target.value !== "0") {
            errors["Price"] = "";
        }
        this.setState({
            property, errors, isPriceAuction
        });
    }

    onTermChange(e) {

        let property = this.state.property;

        if (e.target.value === "" || e.target.value === "0" || e.target.value === 0) {
            property["TermId"] = 0;
        }
        else {
            property["TermId"] = parseInt(e.target.value, 10);
        }
        this.setState({
            property
        });
    }

    onSinglePropertyChange(e) {
        let property = this.state.property;
        let errors = this.state.errors;
        property[e.target.name] = !property[e.target.name];

        if (e.target.name === "IsSingleProperty" && property.IsSingleProperty) {
            property.IsPortfolio = false;
        }

        if (e.target.name === "IsPortfolio" && property.IsPortfolio) {
            property.IsSingleProperty = false;
        }

        if (property.IsSingleProperty !== false || property.IsPortfolio !== false) {
            errors["SingleProperty"] = "";
        }
        this.setState({
            property, errors
        });
    }

    onSaleChange(e) {
        let property = this.state.property;
        let errors = this.state.errors;
        property[e.target.name] = !property[e.target.name];

        if (e.target.name === "IsSale" && property.IsSale) {
            property.IsAuction = false;
            property.AlternatePriceOptionDate = null;
        }

        if (e.target.name === "IsAuction" && property.IsAuction) {
            property.IsSale = false;
        }

        if (property.IsSale !== false || property.IsAuction !== false) {
            errors["Auction"] = "";
        }
        this.setState({
            property, errors
        });
    }

    onLotSizeChange(e) {
        const { property, errors } = this.state;
        property.LotSizeMeasurementId = e.target.name === "acres" ? 1 : 2;
        errors["LotSizeMeasurementId"] = "";
        this.setState({ property, errors });
    }

    onTokenChange(e) {
        const { property, errors } = this.state;

        property.PaymentDetail = this.initializePaymentObject();
        property.PaymentDetail.Token = e.target.value;
        errors["token"] = "";
        errors["errCard"] = "";
        errors["CardName"] = "";
        this.setState({ property, errors });
    }

    onRoomExpansionChange(e) {
        const { property } = this.state;
        property.IsRoomForExpansion = !property.IsRoomForExpansion;
        this.setState({ property });
    }

    saveAsDraft() {
        this.setState({ isPropertySaveClose: true }, () => {
            this.handleNext('Next');
        });
    }

    handleNext(action) {
        const { geocoder, maps, activeStep, bestMatchInfo, propertyImages, isPropertySaveClose, userType } = this.state;

        // co-broker
        if ((activeStep === 3 && userType !== USERTYPES.Admin) || (activeStep === 4 && userType === USERTYPES.Admin)) {
            if (isPropertySaveClose) {
                this.cancelProperty();
            }
            else {
                this.setState({ activeStep: activeStep + 1 });
            }
        }
        else {
            var property = this.state.property;

            property.PropertyStep = activeStep;
            property.PropertyImages = propertyImages.toString();

            if (action === 'Submit') {
                if (this.submitPropertyValidate()) {
                    this.addProperty(property, activeStep, isPropertySaveClose);
                }
                else {
                    this.props.dispatch(hideLoader());
                }
            } else if (action === "Payment" || action === "Next") {

                var isValidProperty = false;
                if (action === "Payment") {
                    isValidProperty = this.submitPropertyValidate()
                } else {
                    isValidProperty = true;
                }
                if (isValidProperty) {

                    var isvalid = this.validateProperty(activeStep, userType);

                    if (isvalid) {
                        if (activeStep === 0) {
                            if ((property.Latitude === undefined || property.Longitude === undefined) || (!this.state.checkCoordinatesValidOrNot)) {
                                this.handleSearch("handleNext");
                            } else if (!bestMatchInfo) {
                                this.setState({ checkCoordinatesValidOrNot: false, isClickedOrNotState: false, AddressChange: false });
                                this.addProperty(property, activeStep, isPropertySaveClose);
                                this.props.dispatch(setSnackbar(false));
                            } else {
                                this.setState({ checkCoordinatesValidOrNot: false });
                            }
                            // var geoCodeObject = {};

                            // if (CONFIG.REGION_ID !== REGIONS.UK) {

                            //     geoCodeObject = {
                            //         address: property.Address1 + ', ' + property.City + ', ' + property.State,
                            //         componentRestrictions: {
                            //             country: COUNTRYCODE.US,
                            //             postalCode: property.ZipCode
                            //         }
                            //     }
                            // } else {
                            //     geoCodeObject = {
                            //         address: property.Address1 + ', ' + property.City + ", " + property.ZipCode + ", " + REGIONS.UK
                            //         // componentRestrictions: {
                            //         //     country: COUNTRYCODE.UK,
                            //         //     postalCode: property.ZipCode
                            //         // }
                            //     }
                            // }

                            // geocoder.geocode(geoCodeObject, (results, status) => {
                            //     if (status === maps.GeocoderStatus.OK) {
                            //         var faddr = results[0].formatted_address.split(',')
                            //         var GeocoderCountry = faddr[faddr.length - 1].replaceAll(/\s/g, '');
                            //         if (CONFIG.REGION_ID === REGIONS.US && COUNTRYCODE.US === GeocoderCountry) {
                            //             const { lat, lng } = results[0].geometry.location;
                            //             property.Latitude = lat();
                            //             property.Longitude = lng();
                            //             this.addProperty(property, activeStep, isPropertySaveClose);
                            //         } else if (CONFIG.REGION_ID === REGIONS.UK && COUNTRYCODE.UK === GeocoderCountry) {
                            //             const { lat, lng } = results[0].geometry.location;
                            //             property.Latitude = lat();
                            //             property.Longitude = lng();
                            //             this.addProperty(property, activeStep, isPropertySaveClose);
                            //         }
                            //         else {
                            //             this.props.dispatch(hideLoader());
                            //             this.props.dispatch(setSnackbar(true, "error", "Please enter a valid address."));
                            //         }
                            //     } else {
                            //         console.error("GEOCODING ERROR", { results }, { status });
                            //         if (status === "ZERO_RESULTS") {
                            //             this.props.dispatch(hideLoader());
                            //             this.props.dispatch(setSnackbar(true, "error", "Address not found!."));
                            //         }
                            //     }
                            // });

                            // let state = "";
                            // let search_text = "";
                            // let country = "us";
                            // if (CONFIG.REGION_ID === REGIONS.UK) {
                            //     search_text = property.Address1.replace(/,/g, ' ') + ',' + property.City + ',' + property.ZipCode;
                            //     search_text = search_text.replace(/[^a-zA-Z0-9, ]/g, " ");
                            //     country = "gb";
                            // }
                            // if (CONFIG.REGION_ID === REGIONS.US) {
                            //     state = this.state.filteredState.find(x => x.StateCode === property.StateId);
                            //     search_text = property.Address1.replace(/,/g, ' ') + ',' + property.City + ',' + state.State + ',' + property.ZipCode;
                            //     search_text = search_text.replace(/[^a-zA-Z0-9, ]/g, " ");
                            // }
                            // let city = property.City.trim().toLowerCase();
                            // let zipCode = property.ZipCode.trim().toLowerCase();
                            // axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${search_text}.json?country=${country}&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`)
                            //     .then(res => {
                            //         let { data } = res;
                            //         if (CONFIG.REGION_ID === REGIONS.US) {
                            //             let addressArray = data.features.filter(x => x.place_type[0] === 'address');
                            //             let fullAddress = addressArray.sort((a, b) => b.relevance - a.relevance)[0].place_name.toLowerCase();
                            //             let secondRelevance = addressArray.length > 1 ? addressArray.sort((a, b) => b.relevance - a.relevance)[1].place_name.toLowerCase() : "";
                            //             if (fullAddress.includes(city) && fullAddress.includes(state.State.toLowerCase()) && fullAddress.includes(zipCode.slice(0, 5))) {
                            //                 const { coordinates } = data.features.filter(x => x.place_type[0] === 'address').sort((a, b) => b.relevance - a.relevance)[0].geometry;
                            //                 property.Latitude = coordinates[1];
                            //                 property.Longitude = coordinates[0];
                            //                 this.addProperty(property, activeStep, isPropertySaveClose);
                            //             }
                            //             else if (secondRelevance.includes(city) && secondRelevance.includes(state.State.toLowerCase()) && secondRelevance.includes(zipCode.slice(0, 5))) {
                            //                 const { coordinates } = data.features.filter(x => x.place_type[0] === 'address').sort((a, b) => b.relevance - a.relevance)[1].geometry;
                            //                 property.Latitude = coordinates[1];
                            //                 property.Longitude = coordinates[0];
                            //                 this.addProperty(property, activeStep, isPropertySaveClose);
                            //             }
                            //             else {
                            //                 this.props.dispatch(hideLoader());
                            //                 this.props.dispatch(setSnackbar(true, "error", "Please enter a valid address."));
                            //             }
                            //         }
                            //         if (CONFIG.REGION_ID === REGIONS.UK) {
                            //             let fullAddress = data.features.sort((a, b) => b.relevance - a.relevance)[0].place_name.toLowerCase();
                            //             let secondRelevance = data.features.length > 1 ? data.features.sort((a, b) => b.relevance - a.relevance)[1].place_name.toLowerCase() : "";
                            //             if (fullAddress.includes(city) && fullAddress.replace(/\s/g, "").includes(zipCode.replace(/\s/g, ""))) {
                            //                 const { coordinates } = data.features.sort((a, b) => b.relevance - a.relevance)[0].geometry;
                            //                 property.Latitude = coordinates[1];
                            //                 property.Longitude = coordinates[0];
                            //                 this.addProperty(property, activeStep, isPropertySaveClose);
                            //             }
                            //             else if (secondRelevance.includes(city) && secondRelevance.replace(/\s/g, "").includes(zipCode.replace(/\s/g, ""))) {
                            //                 const { coordinates } = data.features.sort((a, b) => b.relevance - a.relevance)[1].geometry;
                            //                 property.Latitude = coordinates[1];
                            //                 property.Longitude = coordinates[0];
                            //                 this.addProperty(property, activeStep, isPropertySaveClose);
                            //             }
                            //             else {
                            //                 axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${city + ',' + zipCode}.json?country=${country}&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`)
                            //                     .then(res => {
                            //                         let { data } = res;
                            //                         let fullAddress = data.features.sort((a, b) => b.relevance - a.relevance)[0].place_name.toLowerCase();
                            //                         let secondRelevance = data.features.length > 1 ? data.features.sort((a, b) => b.relevance - a.relevance)[1].place_name.toLowerCase() : "";
                            //                         if (fullAddress.replace(/\s/g, "").includes(zipCode.replace(/\s/g, ""))) {
                            //                             const { coordinates } = data.features.sort((a, b) => b.relevance - a.relevance)[0].geometry;
                            //                             property.Latitude = coordinates[1];
                            //                             property.Longitude = coordinates[0];
                            //                             this.addProperty(property, activeStep, isPropertySaveClose);
                            //                         }
                            //                         else if (secondRelevance.replace(/\s/g, "").includes(zipCode.replace(/\s/g, ""))) {
                            //                             const { coordinates } = data.features.sort((a, b) => b.relevance - a.relevance)[1].geometry;
                            //                             property.Latitude = coordinates[1];
                            //                             property.Longitude = coordinates[0];
                            //                             this.addProperty(property, activeStep, isPropertySaveClose);
                            //                         }
                            //                         else {
                            //                             this.props.dispatch(hideLoader());
                            //                             this.props.dispatch(setSnackbar(true, "error", "Please enter a valid address."));
                            //                         }
                            //                     }).catch(err => {
                            //                         this.props.dispatch(hideLoader());
                            //                         this.props.dispatch(setSnackbar(true, "error", "Address not found!."));
                            //                     });
                            //             }
                            //         }
                            //     })
                            //     .catch(err => {
                            //         this.props.dispatch(hideLoader());
                            //         this.props.dispatch(setSnackbar(true, "error", "Address not found!."));
                            //     });

                        } else {
                            this.addProperty(property, activeStep, isPropertySaveClose);
                        }
                    }
                    else {
                        this.props.dispatch(hideLoader());
                    }
                }
                else {
                    this.props.dispatch(hideLoader());
                }
            }
        }
    }
    accountModal() {
        this.getAddressDetails();
        this.setState({ aModel: true, activeStep: 5 });

    }
    handleSelectChange({ target: { value } }) {
        this.setState({ focusAfterClose: JSON.parse(value) });
    }
    toggle = (data) => {

        let selData = {
            Id: data.Id,
            Address1: data.Address1,
            Address2: data.Address2,
            City: data.City,
            State: data.State,
            ZipCode: data.ZipCode,
            Country: data.CountryName,
            CountryCodeAlpha2: data.CountryCodeAlpha2
        };
        this.setState({
            selectedAddress: selData, addressChecked: data.Id
        });
    }
    onChangeAddress = () => {
        this.setState({ isAddressChanged: true, aModel: false, activeStep: 5 });
        let property = this.state.property;
        let UserDetail = property.UserDetail;
        let stateId = this.state.selectedAddress.State !== null && this.state.selectedAddress.State !== '' ? this.state.selectedAddress.State : 'England';
        let billingSt;
        var sc_code;//stateId !== null ? stateId.split(", ")[1] : stateId;
        if (stateId.indexOf(',') > -1) {
            sc_code = stateId.split(',')[1].trim();
            billingSt = stateId;
        } else {
            sc_code = stateId;
            billingSt = '';
        }
        let countryDetails = this.state.countryData.filter(function (data) {
            return data.CountryCode === sc_code
        });
        let stateDetails = this.state.stateData.filter(function (data) {
            return data.CountryCode === sc_code
        });
        this.state.property.UserDetail.BillingCountrys = countryDetails[0] !== undefined ? countryDetails[0].CountryCode : null;

        this.setState({ showState: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false, filteredState: stateDetails, showStates: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false, filteredStates: stateDetails, UserDetail: { ...UserDetail, Bt_Zipcode: this.state.selectedAddress.ZipCode, BillingCity: this.state.selectedAddress.City, Billing_Address2: this.state.selectedAddress.Address2, BillingAddress: this.state.selectedAddress.Address1, BillingState: stateId, BillingCountrys: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : null } });

        // let property = this.state.property;
        // let UserDetail = property.UserDetail;
        // let stateId = this.state.selectedAddress.State !== null && this.state.selectedAddress.State !== '' ? this.state.selectedAddress.State : 'England';
        // let billingSt;
        // var sc_code;//stateId !== null ? stateId.split(", ")[1] : stateId;
        // if (stateId.indexOf(',') > -1) {
        //     sc_code = stateId.split(',')[1].trim();
        //     billingSt = stateId;
        // } else {
        //     sc_code = stateId;
        //     billingSt = '';
        // }
        // let countryDetails = this.state.countryData.filter(function (data) {
        //     return data.CountryCode === sc_code
        // });
        // let stateDetails = this.state.stateData.filter(function (data) {
        //     return data.CountryCode === sc_code
        // });
        // this.state.property.UserDetail.BillingCountrys = countryDetails[0] !== undefined ? countryDetails[0].CountryCode : null;

        // this.setState({isAddressChanged: true,aModel: false, activeStep: 5, showState: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false, filteredState: stateDetails, showStates: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false, filteredStates: stateDetails, UserDetail: { ...UserDetail, Bt_Zipcode: this.state.selectedAddress.ZipCode, BillingCity: this.state.selectedAddress.City, Billing_Address2: this.state.selectedAddress.Address2, BillingAddress: this.state.selectedAddress.Address1, BillingState: stateId, BillingCountrys: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : null, selectedAddressD : this.state.selectedAddress } });

    }
    switchStepper(index) {
        var property = this.state.property;
        property.NSRF = property.NSRF ? property.NSRF.replace(/,/g, '') : '';
        property.GSF = property.GSF ? property.GSF.replace(/,/g, '') : '';
        property.LotSize = property.LotSize ? property.LotSize.replace(/,/g, '') : '';
        property.CeilingHeight = property.CeilingHeight ? property.CeilingHeight.replace(/,/g, '') : '';
        property.NOI = property.NOI ? property.NOI.replace(/,/g, '') : '';
        property.CapRate = property.CapRate ? property.CapRate.replace(/,/g, '') : '';
        property.Occupancy = property.Occupancy ? property.Occupancy.replace(/,/g, '') : '';

        property.UnitCount = property.UnitCount ? property.UnitCount.replace(/,/g, '') : '';
        property.NumberofFloors = property.NumberofFloors ? property.NumberofFloors.replace(/,/g, '') : '';
        property.Price = property.Price ? property.Price.replace(/,/g, '') : '';
        property.BuildingSize = property.BuildingSize ? property.BuildingSize.replace(/,/g, '') : '';
        property.CCArea = property.CCArea ? property.CCArea.replace(/,/g, '') : '';
        property = this.formatProperty(property);
        this.setState({ activeStep: index, property });
    }

    addProperty(property, activeStep, isPropertySaveClose) {
        const { DeletedImage } = this.state;
        if (activeStep === 1) {
            this.getPropertyDocument(property.PropertyId);
        }

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        var data = {};
        property.RegionID = CONFIG.REGION_ID;
        data = property;
        data.NSRF = data.NSRF ? data.NSRF.replace(/,/g, '') : '';
        data.GSF = data.GSF ? data.GSF.replace(/,/g, '') : '';
        data.LotSize = data.LotSize ? data.LotSize.replace(/,/g, '') : '';
        data.CeilingHeight = data.CeilingHeight ? data.CeilingHeight.replace(/,/g, '') : '';
        data.NOI = data.NOI ? data.NOI.replace(/,/g, '') : '';
        data.CapRate = data.CapRate ? data.CapRate.replace(/,/g, '') : '';
        data.Occupancy = data.Occupancy ? data.Occupancy.replace(/,/g, '') : '';

        data.UnitCount = data.UnitCount ? data.UnitCount.replace(/,/g, '') : '';
        data.NumberofFloors = data.NumberofFloors ? data.NumberofFloors.replace(/,/g, '') : '';
        data.Price = data.Price ? data.Price.replace(/,/g, '') : '';
        data.BuildingSize = data.BuildingSize ? data.BuildingSize.replace(/,/g, '') : '';
        data.CCArea = data.CCArea ? data.CCArea.replace(/,/g, '') : '';

        data.AlternatePriceOptionID = data.AlternatePriceOptionID === "0" ? null : data.AlternatePriceOptionID;
        if (property.AlternatePriceOptionID != 5 && property.AlternatePriceOptionID != 6) {
            data.AlternatePriceOptionDate = null;
            data.AlternatePriceOptionDateStr = null;
        }
        if (property.AlternatePriceOptionDate !== null) {
            data.AlternatePriceOptionDateStr = data.AlternatePriceOptionDate.toLocaleDateString('en-US');
        }

        if (data.PaymentDetail.Token !== "") {
            data.PaymentDetail.SecurityCode = this.state.SecurityCode2;
        }
        //data.PriceCurrency = data.Price ? data.PriceCurrency : '';
        data.DeletedImage = DeletedImage.toString();

        //Adding Country ID
        let countryDetails = null;
        property.UserDetail['BillingCountry'] = this.state.isAddressChanged ? property.UserDetail.BillingCountrys : property.UserDetail.BillingCountry;
        if (property.UserDetail['BillingCountry']) {
            countryDetails = this.state.countryData.filter(function (data) {
                return data.CountryCode === property.UserDetail['BillingCountry'];
            });

            if (countryDetails.length) {
                data.UserDetail['BillingCountry'] = countryDetails[0].CountryID;
                data.UserDetail['BillingCountryCode'] = countryDetails[0].CountryCode === 'England' ? "GB" : countryDetails[0].CountryCode;
            }
        }

        if (this.state.isAddressChanged === true) {
            data.UserDetail['BillingAddress'] = this.state.selectedAddress.Address1;
            data.UserDetail['BillingAddress2'] = this.state.selectedAddress.Address2;
            data.UserDetail['BillingCity'] = this.state.selectedAddress.City;
            data.UserDetail['BillingZipCode'] = this.state.selectedAddress.ZipCode;
            data.UserDetail['BillingState'] = this.state.selectedAddress.State;
            data.UserDetail['State'] = this.state.selectedAddress.State;
            data.UserDetail['PaymentCustomerAddressId'] = this.state.selectedAddress.Id !== undefined && this.state.selectedAddress.Id !== '' && this.state.selectedAddress.Id !== null ? this.state.selectedAddress.Id : null;

            if (property.UserDetail.BillingCountryCode === 'England') {
                data.UserDetail['BillingState'] = '';
                data.UserDetail['BillingStateName'] = '';
                data.UserDetail['StateCode'] = '';
                data.UserDetail['State'] = '';
            }
        }
        else {
            data.UserDetail['BillingAddress'] = data.UserDetail.BillingAddress;
            data.UserDetail.BillingAddress2 = data.UserDetail.BillingAddress2;
            data.UserDetail.BillingCity = data.UserDetail.BillingCity;
            data.UserDetail.BillingZipCode = data.UserDetail.BillingZipCode;
            data.UserDetail.BillingState = data.UserDetail.BillingState;
            data.UserDetail.BillingCountry = data.UserDetail.BillingCountry;

            if (property.UserDetail.BillingCountryCode === 'England') {
                data.UserDetail['BillingState'] = '';
                data.UserDetail['BillingStateName'] = '';
                data.UserDetail['StateCode'] = '';
                data.UserDetail['State'] = '';
            }
        }
        axios.post(CONFIG.API_URL + 'property/add', data)
            .then(response => {
                if (response.status === 200) {
                    property.PropertyId = response.data;

                    if (property.IsPropertyAdd && activeStep === 0) {
                        this.addMarketViewData(property, token);
                    }

                    //Adding Country ID
                    if (property.UserDetail['BillingCountry']) {
                        if (countryDetails) {
                            property.UserDetail['BillingCountry'] = countryDetails[0].CountryCode;
                            property.UserDetail['BillingCountryCode'] = countryDetails[0].CountryCode === 'England' ? "GB" : countryDetails[0].CountryCode;
                        }
                    }

                    if (isPropertySaveClose) {
                        this.cancelProperty();
                    } else {

                        property = this.formatProperty(property);
                        if (activeStep === 0) {//Zipcode value is binded in occupancy issue fix.
                            property.Occupancy = property.Occupancy ? property.Occupancy : '';
                        }
                        this.setState({ property }, () => {
                            this.setState({ activeStep: activeStep + 1 });
                        });
                    }
                }
            })
            .catch(err => {
                this.props.dispatch(setSnackbar(true, "error", "Something went wrong please reach out to technical team for assistance."));
            });
    }

    addMarketViewData(property, token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(property)
        };

        fetch(CONFIG.API_URL + 'property/add/marketview', requestOptions)
            .then(
                (result) => {
                },
                (error) => {
                }
            )
    }

    handleChange(e) {
        let property = this.state.property;
        let SecurityCode2 = this.state.SecurityCode2;
        if (e.target.name === "SecurityCode2") {
            SecurityCode2 = e.target.value;
        }
        else {
            property.PaymentDetail[e.target.name] = e.target.value;
            property.UserDetail[e.target.name] = e.target.value;
        }

        if (!e.target.name.includes("Billing")) {
            this.setState({ property, SecurityCode2 }, () => {
                this.ValidateCard()
            });
        }

        // Country based state;
        if (this.state.property.UserDetail.BillingCountry !== undefined && this.state.property.UserDetail.BillingCountry !== '' && e.target.name === 'BillingCountry') {
            //spliting the country code from state id
            var sc_code = e.target.value;
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.CountryCode === sc_code
            });

            // State's country code based country details
            let stateDetails = this.state.stateData.filter(function (data) {
                return data.CountryCode === sc_code
            });

            //Adding Country name
            this.state.property.UserDetail['BillingCountry'] = stateDetails.length > 0 ? countryDetails[0].CountryID : '';
            this.state.property.UserDetail['BillingCountryCode'] = stateDetails.length > 0 ? countryDetails[0].CountryCode : '';
            // this.state.property.UserDetail.BillingState = '';
            // const billingState = { ...this.state.property.UserDetail }
            // // billingState.BillingState = '';
            // billingState.BillingCountry = countryDetails[0].CountryID;
            // billingState.BillingCountryCode = countryDetails[0].CountryCode;
            this.setState({
                countryId: countryDetails[0] !== undefined ? countryDetails[0].CountryID : null, StateCountrys: stateDetails.length > 0 ? countryDetails[0].Country : '', filteredStates: stateDetails, showStates: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false

            });
        }
        if (e.target.value !== '' && e.target.name === 'BillingCountrys') {
            //spliting the country code from state id
            let sc_code = e.target.value;
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.CountryCode === sc_code
            });
            // State's country code based country details
            let stateDetails = this.state.stateData.filter(function (data) {
                return data.CountryCode === sc_code
            });
            this.setState({ countryId: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryID : null, StateCountrys: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : null, filteredState: stateDetails, showState: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false, stateAbb: sc_code });
        }
    }

    submitPropertyValidate() {
        const { userType } = this.state;
        if (this.validatePropertyInfo()) {//validate info
            if (this.validatePropertyFeatures()) {//validate features
                if (this.validatePropertyPrice()) {//validte price 
                    if (userType === USERTYPES.Admin) {
                        if (this.validatePropertyBrokerTag()) {//validate broker tag
                            return true;
                        } else {
                            this.setState({ activeStep: 3 });
                        }
                    } else {
                        return true;
                    }
                } else {
                    this.setState({ activeStep: 2 });
                }
            } else {
                this.setState({ activeStep: 1 });
            }
        } else {
            this.setState({ activeStep: 0 });
        }
    }

    validateProperty(activeStep, userType) {
        switch (activeStep) {
            case 0://property info
                return this.validatePropertyInfo();
            case 1://property features
                return this.validatePropertyFeatures();
            case 2://property price
                return this.validatePropertyPrice();
            case 3://tag broker for admin, co-broker for others
                return userType === USERTYPES.Admin ? this.validatePropertyBrokerTag() : true;
            case 4://co-broker for admin, review and submit for others
                return true;
            case 5://review for admin, validate card for broker or unauthorized broker.
                return userType === USERTYPES.Admin ? true : this.ValidateCard();

            default:
                break;
        }
    }

    validateAddressMap() {
        const { property, errors } = this.state;
        var count = 0;

        if (!property.Address1 || property.Address1.trim() === "") {
            count = count + 1;
            errors["Address1"] = "Address is required";
        }
        if (!property.City || property.City.trim() === "") {
            count = count + 1;
            errors["City"] = CONFIG.REGION_ID === REGIONS.UK ? "Town/City is required" : "City is required";
        }
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            if (property.CountryId === "0") {
                count = count + 1;
                errors["CountryId"] = "Country is required";
            }
            if (!property.StateId || property.StateId === "0") {
                count = count + 1;
                errors["StateId"] = "State is required";
            }
        }
        if (!property.ZipCode || property.ZipCode.trim() === "") {
            count = count + 1;
            errors["ZipCode"] = CONFIG.REGION_ID === REGIONS.UK ? "Postcode is required" : "Zip Code is required";
        }

        var result = count > 0 ? false : true;
        this.setState({ errors });
        return result;
    }

    validatePropertyInfo() {
        const { property } = this.state;
        var count = 0;
        var errors = {};

        if (!property.PropertyName || property.PropertyName.trim() === "") {
            count = count + 1;
            errors["PropertyName"] = "Property Name is required";
        }
        if (!property.Address1 || property.Address1.trim() === "") {
            count = count + 1;
            errors["Address1"] = "Address is required";
        }
        if (!property.City || property.City.trim() === "") {
            count = count + 1;
            errors["City"] = CONFIG.REGION_ID === REGIONS.UK ? "Town/City is required" : "City is required";
        }
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            if (property.CountryId === "0") {
                count = count + 1;
                errors["CountryId"] = "Country is required";
            }
            if (!property.StateId || property.StateId === "0") {
                count = count + 1;
                errors["StateId"] = "State is required";
            }
        }
        if (!property.ZipCode || property.ZipCode.trim() === "") {
            count = count + 1;
            errors["ZipCode"] = CONFIG.REGION_ID === REGIONS.UK ? "Postcode is required" : "Zip Code is required";
        }
        if (property.IsSingleProperty === false && property.IsPortfolio === false) {
            count = count + 1;
            errors["SingleProperty"] = "Single Property or Portfolio is required";
        }
        if (property.PropertyTypeId === "0") {
            count = count + 1;
            errors["PropertyTypeId"] = "Property Type is required";
        }

        if (property.PropertyImages === "") {
            count = count + 1;
            errors["imagesError"] = "Property image is required";
        }

        var result = count > 0 ? false : true;
        this.setState({ errors, uploadError: false });
        return result;
    }

    validatePropertyFeatures() {
        const { property, errors } = this.state;
        var count = 0;

        if (property.YearBuilt) {
            var yearBuilt = getFormattedInt(property.YearBuilt);
            if (yearBuilt !== "" && (parseInt(yearBuilt.replace(/,/g, ''), 10) > parseInt(new Date().getFullYear(), 10))) {
                errors["YearBuilt"] = "Year Built should be less than or equal to current year";
                count = count + 1;
            }
        }

        if (property.LotSize) {
            if (!property.LotSizeMeasurementId) {
                errors["LotSizeMeasurementId"] = "Please select Acres or SF";
                count = count + 1;
            }
        }

        var result = count > 0 ? false : true;
        this.setState({ errors });
        return result;
    }

    validatePropertyPrice() {
        var count = 0;
        const { property } = this.state;
        var errors = {};

        if ((!property.Price || property.Price.trim() === "") && (property.AlternatePriceOptionID === "0" || property.AlternatePriceOptionID == null)) {
            count = count + 1;
            errors["Price"] = "Price or Price Alternative is required";
        }

        if ((property.AlternatePriceOptionID == 5 || property.AlternatePriceOptionID == 6) && property.AlternatePriceOptionDate === null) {
            count = count + 1;
            errors["AlternatePriceOptionDate"] = "Date is required";
        }

        this.setState({ errors });
        var result = count > 0 ? false : true;
        return result;
    }

    validatePropertyBrokerTag() {
        var result = true;
        var count = 0;
        const { property } = this.state;
        var errors = {};

        if (!property.BrokerId) {
            count = count + 1;
            errors["brokerTag"] = "Please select broker";
        }
        this.setState({ errors });
        result = count > 0 ? false : true;
        return result;
    }

    ValidateCard() {
        var count = 0;
        const { property, SecurityCode2 } = this.state;
        var errors = {};

        if (property.PaymentDetail.Token !== "") {
            if (!SecurityCode2) {
                errors["SecurityCode2"] = "CVV is required";
                count = count + 1;
            }
            else {
                if (SecurityCode2.length === 3 || SecurityCode2.length === 4) {
                } else {
                    errors["SecurityCode2"] = "Please enter valid CVV";
                    count = count + 1;
                }
            }
        }
        else {
            if (property.PaymentDetail.CardName === "") {
                errors["CardName"] = "Card Name is required";
                count = count + 1;
            }

            if (!property.PaymentDetail.SecurityCode || !property.PaymentDetail.Expiry) {
                errors["errCard"] = "Credit card details required";
                count = count + 1;
            }
            else if (property.PaymentDetail.Expiry) {
                //Expiry date check for Card
                var getPaymonthyear = property.PaymentDetail.Expiry.toString().split('/');
                let ExpiryMonth = getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim(), 10) : '';
                let ExpiryYear = getPaymonthyear.length > 1 ? getPaymonthyear[1].trim() : '';
                var CurrentMonth = new Date().getMonth() + 1;
                var CurrentYear = new Date().getFullYear().toString().substr(-2);

                property.PaymentDetail.ExpiryMonth = ExpiryMonth;
                property.PaymentDetail.ExpiryYear = ExpiryYear;

                if (CurrentYear > ExpiryYear) {
                    errors["errCard"] = "Please enter valid expiry year";
                    count = count + 1;
                }
                else {
                    var cvv = property.PaymentDetail.SecurityCode;

                    if (CurrentYear === ExpiryYear) {
                        if (CurrentMonth > ExpiryMonth) {
                            errors["errCard"] = "Please enter valid expiry month";
                            count = count + 1;
                        }
                        else {
                            if (cvv) {
                                if (cvv.length === 3 || cvv.length === 4) {
                                } else if (cvv.length > 0) {
                                    errors["errCard"] = "Please enter valid CVV";
                                    count = count + 1;
                                }
                            }
                        }
                    }
                    else {
                        if (cvv) {
                            if (cvv.length === 3 || cvv.length === 4) {
                            } else if (cvv.length > 0) {
                                errors["errCard"] = "Please enter valid CVV";
                                count = count + 1;
                            }
                        }
                    }
                }
            }
        }
        this.setState({ property, errors });

        return count === 0 ? true : false;
    }

    // reFormatProperty(property) {
    //     property.NSRF = property.NSRF ? property.NSRF.replace(/,/g, '') : '';
    //     property.GSF = property.GSF ? property.GSF.replace(/,/g, '') : '';
    //     property.LotSize = property.LotSize ? property.LotSize.replace(/,/g, '') : '';
    //     property.CeilingHeight = property.CeilingHeight ? property.CeilingHeight.replace(/,/g, '') : '';
    //     property.UnitCount = property.UnitCount ? property.UnitCount.replace(/,/g, '') : '';
    //     property.Price = property.Price ? property.Price.replace(/,/g, '') : '';
    //     property.BuildingSize = property.BuildingSize ? property.BuildingSize.replace(/,/g, '') : '';
    //     property.CCArea = property.CCArea ? property.CCArea.replace(/,/g, '') : '';
    //     property.AlternatePriceOptionID = property.AlternatePriceOptionID === "0" ? null : property.AlternatePriceOptionID;
    //     return property;
    // }

    formatProperty(property) {
        property.BrokerId = property.BrokerId ? property.BrokerId : 0;

        property.NSRF = property.NSRF ? getFormattedFloat(property.NSRF) : '';
        property.GSF = property.GSF ? getFormattedFloat(property.GSF) : '';
        property.BuildingSize = property.BuildingSize ? getFormattedFloat(property.BuildingSize) : '';
        property.CCArea = property.CCArea ? getFormattedFloat(property.CCArea) : '';
        property.LotSize = property.LotSize ? getFormattedFloat(property.LotSize) : '';
        property.CapRate = property.CapRate ? getFormattedFloat(property.CapRate) : '';
        property.Occupancy = property.Occupancy ? getFormattedFloat(property.Occupancy) : '';

        property.CeilingHeight = property.CeilingHeight ? getFormattedInt(property.CeilingHeight) : '';
        property.NOI = property.NOI ? getFormattedInt(property.NOI) : '';
        property.UnitCount = property.UnitCount ? getFormattedInt(property.UnitCount) : '';
        property.NumberofFloors = property.NumberofFloors ? getFormattedInt(property.NumberofFloors) : '';

        property.Price = property.Price ? getFormattedInt(property.Price.toString().replace(/,/g, '')) : '';
        if (property.AlternatePriceOptionDate) {
            var today = new Date(property.AlternatePriceOptionDate);
            property.AlternatePriceOptionDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        }
        return property;
    }

    resetSearch() {
        this.formUsers && this.formUsers.reset();
        const { searchCriteria } = this.state;
        searchCriteria.Name = "";
        searchCriteria.BrokerTypeID = "0";
        searchCriteria.Page = 1;
        this.setState({ searchCriteria }, () => {
            this.getBrokers(searchCriteria);
        });
    }

    getBrokers(data) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.post(CONFIG.API_URL + 'property/users', data)
            .then(response => {
                let result = response.data;
                this.setState({
                    users: result.Users,
                    pagination: result.Pagination,
                    pageNumUser: Math.ceil(result.Pagination.TotalRecords / 10)
                });
            })
    }

    setBroker(userID) {
        const { property } = this.state;
        property.BrokerId = userID;
        this.setState({ property });
    }

    handleUserChangePage = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            this.getBrokers(searchCriteria);
        });

    };

    searchUsers(event, errors, values) {
        const { searchCriteria } = this.state;
        searchCriteria.Name = values.Name;
        searchCriteria.BrokerTypeID = values.BrokerTypeID === "" ? 0 : values.BrokerTypeID;
        searchCriteria.Page = 1;
        this.setState({ searchCriteria }, () => {
            this.getBrokers(searchCriteria);
        });
    }

    toggleModalClose() {
        this.setState({ isPropertyReview: false });
    }

    toggleFileClose() {
        this.setState({ showFile: false });
    }

    toggleModalOpen() {
        const { isPropertyReview } = this.state;
        this.setState({ isPropertyReview: !isPropertyReview });
    }

    toggleFileOpen(docId, docTitle) {
        this.setState({ showFile: true, docID: docId, docTitle: docTitle });
    }

    handleStripe(id) {
        let data = {
            regionid: CONFIG.REGION_ID,
            PropertyID: id,
            ChangePropertyToStatus: this.state.property.ChangePropertyToStatus,
            IsPropertyAdd: this.state.property.IsPropertyAdd
        }
        let URL = localStorage.getItem('SURL');
        if (URL == null) {
            axios.post(CONFIG.API_URL + 'account/checkoutsession', data).then(res => {
                if (res.status === 200) {
                    localStorage.setItem('SURL', res.data)
                    window.location.replace(res.data);
                }
            }).catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
        } else {
            window.location.replace(URL);
        }
    }

    openPropertyViewPage(property) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.get(CONFIG.API_URL + 'property/permalink/' + property.PropertyId).then(response => {
            if (response.status === 200) {
                var url = getPropertyViewUrl(response.data);

                // const { from } = { from: { pathname: "/property/" + property.PropertyId + "/" + prepareStringForUrl(property.PropertyName) } };
                // this.props.history.push(from);
                window.open(url, "_blank");
            }
        })
            .catch(err => {
            });
    }

    approveProperty(propertyId) {
        this.props.dispatch(showLoader());
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.put(CONFIG.API_URL + 'property/approve/' + propertyId + "/" + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                const { from } = { from: { pathname: "/admin/properties" } };
                this.props.history.push(from);
                this.props.dispatch(setSnackbar(true, "success", "Property Approved Successfully"));
            }
        })
            .catch(err => {
            });
    }

    onDescriptionChange(articleDescription, editor) {
        const { property } = this.state;
        property.Description = articleDescription
        this.setState({ property });
    }

    saveImage = (file) => {
        let errors = this.state.errors;
        errors["imagesError"] = '';
        this.setState({ errors }, () => {
            if (file.length > 0) {
                file = file[0];
                const i = new Image()
                i.onload = () => {
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => {
                        let lastUploadedFile = {
                            name: file.name,
                            contentType: file.type,
                            content: reader.result,
                            preview: URL.createObjectURL(file),
                            width: i.width,
                            height: i.height,
                            naturalWidth: i.naturalWidth,
                            naturalHeight: i.naturalHeight
                        };

                        let imgWidth = lastUploadedFile.width <= CONFIG.PropertyImageWidth ? lastUploadedFile.width : CONFIG.PropertyImageWidth;
                        let imgHeight = lastUploadedFile.height <= CONFIG.PropertyImageHeight ? lastUploadedFile.height : CONFIG.PropertyImageHeight;

                        this.setState({
                            crop: { width: imgWidth, height: imgHeight, aspect: this.props.crop.aspect, keepSelection: this.props.keepSelection },
                            uploadError: false
                        });

                        if (this.state.imageToCrop) {
                            let { imageToCropList } = this.state;
                            imageToCropList.push(lastUploadedFile);
                            this.setState({ imageToCropList: imageToCropList });
                        } else {
                            this.setState({ imageToCrop: lastUploadedFile, open: true });
                        }
                    };

                    reader.onabort = () => {
                        this.setState({
                            uploadError: true
                        });
                    };

                    reader.onerror = () => {
                        this.setState({
                            uploadError: true
                        });
                    }
                }
                i.src = URL.createObjectURL(file)
            } else {
                this.setState({
                    uploadError: true
                });
            }
        });
    }

    renderAcceptedFileTypes = () => {
        let typesStr = '';
        if (this.props.allowedTypes) {
            this.props.allowedTypes.forEach((type /*, index*/) => {
                typesStr += `${type}, `;
            });
        }
        return typesStr;
    };

    /** Cropper Methods Start **/
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onChange = (pixel, crop) => {
        if (crop.height === 0 || crop.width === 0) {
            return;
        }
        this.setState({ crop: crop, pixelCrop: pixel });
    };
    handleEditBack(type) {
        if (type === 1) {
            this.setState({ aModel: false });
        } else {
            this.setState({ setStep: 1 });
        }

    }
    addNewAddress = () => {
        this.setState({ editAddress: {}, setStep: 3, StateCountrys: '', showState: false });
    }
    onAccept = () => {
        const { pixelCrop } = this.state;
        this.makeClientCrop(pixelCrop);
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const { imageToCrop } = this.state;

            const blob = await this.getCroppedImg(
                this.imageRef,
                crop,
                imageToCrop.name
            );
            var lthis = this;

            var croppedImageUrl = window.URL.createObjectURL(blob);
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                var formData = {
                    Base64StringFile: base64data.split(",")[1],
                    Name: imageToCrop.name,
                    RegionID: CONFIG.REGION_ID
                }

                let token = localStorage.getItem('accessKey');

                if (token) {
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                }

                if (!CONFIG.ISDEV) {
                    axios.post(CONFIG.API_URL + 'property/upload/image', formData).then(response => {
                        if (response.status === 200) {
                            var result = response.data;
                            var propertyImages = lthis.state.propertyImages;
                            const { property } = lthis.state;

                            if (propertyImages.length === 0) {
                                property.PrimaryImage = result;
                            }
                            propertyImages.push(result);

                            lthis.setState({ croppedImageUrl, propertyImages, property, imageToCrop: null, imageToCropList: [], open: false, crop: lthis.props.crop });
                        }
                    });
                } else {
                    axios.get(CONFIG.API_URL + 'home/upload/image/test').then(response => {
                        if (response.status === 200) {
                            var result = response.data;
                            var propertyImages = lthis.state.propertyImages;

                            const { property } = lthis.state;

                            if (propertyImages.length === 0) {
                                property.PrimaryImage = result;
                            }
                            propertyImages.push(result);

                            lthis.setState({ croppedImageUrl, propertyImages, property, imageToCrop: null, imageToCropList: [], open: false, crop: lthis.props.crop });

                        }
                    });
                }
            }
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    // onAccept = () => {
    //     if (this.imageRef) {
    //         const { pixelCrop, imageToCrop, crop } = this.state;
    //         var image = imageToCrop;
    //         const imgName = image.name;
    //         const canvas = document.createElement('canvas');
    //         canvas.width = pixelCrop.width;
    //         canvas.height = pixelCrop.height;
    //         const ctx = canvas.getContext('2d');
    //         const scaleX = image.naturalWidth / image.width;
    //         const scaleY = image.naturalHeight / image.height;
    //         var ratio = Math.min(canvas.width / image.width, canvas.height / image.height);
    //         //Math.min(image.width / image.height, canvas.width / canvas.height);

    //         //ctx.drawImage(this.imageRef, 0, 0, image.width * ratio, image.height * ratio);
    //         //ctx.drawImage(this.imageRef, 0, 0, image.width, image.height);
    //         // ctx.drawImage(
    //         //     this.imageRef,
    //         //     pixelCrop.x,
    //         //     pixelCrop.y,
    //         //     pixelCrop.width,
    //         //     pixelCrop.height,
    //         //     0,
    //         //     0,
    //         //     pixelCrop.width,
    //         //     pixelCrop.height,
    //         // );

    //         ctx.drawImage(
    //             this.imageRef,
    //             pixelCrop.x * scaleX,
    //             pixelCrop.y * scaleY,
    //             pixelCrop.width * scaleX,
    //             pixelCrop.height * scaleY,
    //             0,
    //             0,
    //             pixelCrop.width,
    //             pixelCrop.height,
    //         );

    //         // ctx.drawImage(
    //         //     this.imageRef,
    //         //     crop.x,
    //         //     crop.y,
    //         //     crop.width,
    //         //     crop.height,
    //         //     0,
    //         //     0,
    //         //     crop.width,
    //         //     crop.height,
    //         // );

    //         const nameArr = imgName.split(".");
    //         let name = "";
    //         let extension = "";
    //         nameArr.forEach((n, i) => {
    //             if (i === nameArr.length - 1 && nameArr.length > 1) {
    //                 extension = n;
    //             } else {
    //                 name += n;
    //             }
    //         });
    //         var type = 'image/' + extension;

    //         const base64Image = canvas.toDataURL('image/jpeg');


    //         const ts = new Date().getTime();
    //         const img = {
    //             id: image.id,
    //             name: `${name}_${ts}.${extension}`,
    //             contentType: image.contentType,
    //             content: base64Image.split(",")[1],
    //             preview: base64Image
    //         };

    //         this.setState({ imgFinal: img });

    //         var formData = {
    //             Base64StringFile: img.content,
    //             Name: img.name,
    //         }

    //         let token = localStorage.getItem('accessKey');
    //         if (token) {
    //             axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    //         }
    //         if (!CONFIG.ISDEV) {
    //             axios.post(CONFIG.API_URL + 'property/upload/image', formData).then(response => {
    //                 if (response.status === 200) {
    //                     var result = response.data;
    //                     var propertyImages = this.state.propertyImages;
    //                     const { property } = this.state;

    //                     if (propertyImages.length === 0) {
    //                         property.PrimaryImage = result;
    //                     }
    //                     propertyImages.push(result);

    //                     this.setState({ propertyImages, property, imageToCrop: null, imageToCropList: [], open: false, crop: this.props.crop });
    //                 }
    //             });
    //         } else {
    //             axios.get(CONFIG.API_URL + 'home/upload/image/test').then(response => {
    //                 if (response.status === 200) {
    //                     var result = response.data;
    //                     var propertyImages = this.state.propertyImages;

    //                     const { property } = this.state;

    //                     if (propertyImages.length === 0) {
    //                         property.PrimaryImage = result;
    //                     }
    //                     propertyImages.push(result);

    //                     this.setState({ propertyImages, property, imageToCrop: null, imageToCropList: [], open: false, crop: this.props.crop });
    //                 }
    //             });
    //         }
    //     }
    // };
    handleEditSubmit(event, errors, values) {
        if (errors.length === 0) {
            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            this.setState({ isLoading: true });
            let countryCodes = this.state.countryData.filter(x => x.CountryID === this.state.countryId).map(x => x.CountryCode)[0];
            let data = {
                Id: this.state.setStep === 2 ? this.state.editAddress.Id : null,
                Address1: values.BillingAddress,
                Address2: values.Billing_Address2,
                City: values.BillingCity,
                State: values.State !== undefined ? values.State : '',
                IsDefault: this.state.setStep === 2 ? this.state.editAddress.IsDefault : false,
                CountryId: this.state.countryId,
                CountryCodeAlpha2: countryCodes === 'England' || countryCodes === 'Scotland' || countryCodes === 'Northern Ireland' || countryCodes === 'Wales' ? 'GB' : countryCodes,
                ZipCode: values.Bt_Zipcode,
                RegionID: CONFIG.REGION_ID,
            }
            axios.post(CONFIG.API_URL + 'vault/customer/address', data)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({ isLoading: false, setStep: 1 }, () => {
                            this.getAddressDetails();
                        });
                    }
                }).catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response.status === 401) {

                    }
                });
        }
    }
    deleteAddresss(Id) {
        confirmAlert({
            message: 'Are you want to delete this address ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ isLoading: true });
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        axios.delete(CONFIG.API_URL + 'vault/customer/address/' + Id + '/' + CONFIG.REGION_ID)
                            .then(response => {
                                this.getAddressDetails();
                                this.setState({ isLoading: false });
                            }).catch((err) => {
                                this.setState({ isLoading: false });
                                if (err.response.status === 401) {

                                }
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    DeleteDocument(Id) {
        confirmAlert({
            message: 'Are you want to delete this document ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ isLoading: true });
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        axios.delete(CONFIG.API_URL + 'property/document/' + Id)
                            .then(response => {
                                this.setState({
                                    propertyDocument: this.state.propertyDocument.filter((pd) => {
                                        return pd.ID !== Id;
                                    })
                                });
                                this.setState({ isLoading: false });
                            }).catch((err) => {
                                this.setState({ isLoading: false });
                                if (err.response.status === 401) {

                                }
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    loadcountryData = (stateValue) => {
        //spliting the country code from state id
        // var billing_state = stateValue.toString().split(",");
        // var sc_code = billing_state.length > 0 ? billing_state[1].trim() : "";
        if (stateValue !== null && stateValue !== '') {
            var sc_code;
            if (stateValue.indexOf(',') > -1) {
                sc_code = stateValue.split(',')[1].trim();
            } else {
                sc_code = stateValue;
            }
            // State's country code based country details
            let stateDetails = this.state.stateData.filter(function (data) {
                return data.CountryCode === sc_code
            });
            // State's country code based country details
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.CountryCode === sc_code
            });
            this.setState({ filteredState: stateDetails, showState: countryDetails[0].CountryCode === "England" ? false : true, StateCountrys: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : '' });
        } else {
            if (this.state.property.userDetail.BillingAddress !== null) {
                let countryDetails = this.state.countryData.filter(function (data) {
                    return data.CountryCode == "England";
                });
                this.setState({ filteredState: {}, showState: countryDetails[0].CountryCode === "England" ? false : true, StateCountrys: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : '' });
            }
        }
    };
    editMarket = (data) => {
        if (data.State !== null && data.State !== '' && data.State !== undefined) {
            this.loadcountryData(data.State);
        } else {
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.CountryCode === "England";
            });
            this.setState({ showState: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === "England" ? false : true : false, StateCountrys: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : null, countryId: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryID : null });
        }
        this.setState({
            editAddress: {
                Id: data.Id,
                Address1: data.Address1,
                Address2: data.Address2,
                City: data.City,
                State: data.State,
                ZipCode: data.ZipCode,
                IsDefault: data.IsDefault,
                Country: data.Country,
            }
        });
        this.setState({ setStep: 2 });
    }
    onCancel = () => {
        const { property, errors } = this.state;

        if (property.PropertyImages === "") {
            errors["imagesError"] = "Property image is required";
        }
        this.setState({ errors, imageToCrop: null, imageToCropList: [], open: false, crop: this.props.crop });
    };
    /** Cropper Methods End **/

    onclickMarker = (event) => {
        if (event.target.className === "overlays") {
            axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + event.lngLat[0] + ',' + event.lngLat[1] + '.json?types=country&access_token=' + process.env.REACT_APP_MAPBOX_TOKEN)
                .then(response => {
                    if (response.data.features.length > 0) {
                        let country = response.data.features[0].text;
                        if ((CONFIG.REGION_ID === REGIONS.US && country === "United States") || (CONFIG.REGION_ID === 3 && country === "United Kingdom")) {
                            this.setState({
                                isClickedOrNotState: true
                            }, () => this.updateViewPort(Number(event.lngLat[0].toFixed(5)), Number(event.lngLat[1].toFixed(5))));
                        }
                    }
                })
                .catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
    }

    onMarkerDragEnd = (event) => {
        let prevLat = this.state.property.Latitude;
        let prevLng = this.state.property.Longitude;
        this.setState(prevState => ({
            ...prevState,
            property: {
                ...prevState.property,
                Latitude: Number(event.lngLat[1].toFixed(5)),
                Longitude: Number(event.lngLat[0].toFixed(5))
            },
            isClickedOrNotState: true
        }));
        axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + event.lngLat[0] + ',' + event.lngLat[1] + '.json?types=country&access_token=' + process.env.REACT_APP_MAPBOX_TOKEN)
            .then(response => {
                let country = response.data.features[0].text;
                if ((CONFIG.REGION_ID === REGIONS.US && country === "United States") || (CONFIG.REGION_ID === 3 && country === "United Kingdom")) {
                    this.setState(prevState => ({
                        ...prevState,
                        property: {
                            ...prevState.property,
                            Latitude: Number(event.lngLat[1].toFixed(5)),
                            Longitude: Number(event.lngLat[0].toFixed(5))
                        },
                        isClickedOrNotState: true
                    }));
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        property: {
                            ...prevState.property,
                            Latitude: Number(prevLat),
                            Longitude: Number(prevLng)
                        },
                        isClickedOrNotState: true
                    }));
                }
            }).catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    this.setState(prevState => ({
                        ...prevState,
                        property: {
                            ...prevState.property,
                            Latitude: Number(prevLat),
                            Longitude: Number(prevLng)
                        },
                        isClickedOrNotState: true
                    }));
                }
            });
    };

    render() {
        const { activeStep, steps, countryList, stateList, property, propertyImages, errors, existingCards, termList, SecurityCode2,
            users, userType, brokerType, userTypes, isPropertyReview, propertyTypes, skipPayment, viewport, propertyDocument, showFile, docTitle,
            alternatePriceList, pageNumUser, currencyList, imageToCrop, open, crop, countryData, filteredState, showState, filteredStates, showStates, addressChecked, setStep, editAddress, addressDetails, isLoading, isAddressChanged, selectedAddress, isAddressFound } = this.state;
        const isAdmin = userType === USERTYPES.Admin ? true : false;
        const isUnAuthBroker = brokerType === BROKERTYPES.Unauthorized ? true : false;
        const isPremiumBroker = brokerType === BROKERTYPES.PremiumBroker ? true : false;
        const stepsLength = steps.length;
        const isStatusValue = property.PropertyStatusId === 1 || property.PropertyStatusId === 7 || property.PropertyStatusId === 3 ? true : false;
        // document.title = CONFIG.PAGE_TITLE + " " + (property.PropertyId > 0 ? "Edit" : "Add") + " Property";
        let addorEdit = (property.PropertyId > 0 ? "Edit" : "Add") + " Property";

        return (
            property.IsPropertyAdd || (!property.IsPropertyAdd && property.PropertyName) ?
                <div className={`vendor-profile-header form-back-shadow col-sm-7 marginall-auto margin-t-15 vendor-profile-width ${activeStep !== 0 ? "" : "property-expand-popup"}`}>
                    <Helmet
                        title={addorEdit}
                        addPostfixTitle={true}
                        keywords="Add Property, Edit Property, Search, Property"
                    />
                    <div className="displaynone">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: CONFIG.MapAPIKey }}
                            defaultCenter={{
                                lat: parseFloat(30.338745408984124),
                                lng: parseFloat(-81.75199789894742)
                            }}
                            defaultZoom={15}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.initGeocoder(map, maps)}
                        />
                    </div>
                    <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={isPropertyReview} toggle={this.toggleModalClose.bind(this)} className="vendor2-assign-table">
                        <ModalHeader toggle={this.toggleModalClose.bind(this)}>Property Review
                        </ModalHeader>
                        <ModalBody className="overflow-scroll">
                            <PropertyReView propertyId={property.PropertyId} isAdmin={isAdmin} />
                        </ModalBody>
                    </Modal>
                    <Row>
                        <Col sm={12}>
                            <Container fluid>
                                <div className="stepper-box">
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label, index) => {
                                            return (
                                                (!property.IsPropertyAdd && skipPayment && index < 5) ?
                                                    <Step key={label} className="cursor-pointer" onClick={this.switchStepper.bind(this, index)}>
                                                        <StepLabel completed={false}>{label}</StepLabel>
                                                    </Step>
                                                    :
                                                    <Step key={label}>
                                                        <StepLabel completed={false}>{label}</StepLabel>
                                                    </Step>
                                            )
                                        })}
                                    </Stepper>
                                </div>
                                <div>
                                    {isAdmin && !property.IsPropertyAdd && property.BrokerTypeId === BROKERTYPES.Broker &&
                                        property.PropertyStatusId === PROPERTYSTATUS.PendingApproval ?
                                        <div className="text-align-right"> <Button className="approved-btn"
                                            onClick={() => this.approveProperty(property.PropertyId)}
                                        ><FontAwesomeIcon icon={faCheckCircle} /> Approve</Button></div> : ''}</div>
                                <div className="stepper-content property-content">
                                    {activeStep === 0 ?
                                        <Fragment>
                                            <Row className='mt-3'>
                                                <Col md={6}>
                                                    <AvForm ref={c => (this.infoForm = c)}>
                                                        <AvField name="PropertyName" label="Property Name:" type="text" maxLength="100"
                                                            value={property.PropertyName} onChange={(e) => this.onKeyChange(e)} />
                                                        <span className="errorMessage error-top-position"> {errors.PropertyName}</span>

                                                        <Row>
                                                            <div className='search-field-col'>
                                                                <Row className='m-0'>
                                                                    <Col md={12}>
                                                                        <AvField name="Address1" className="height52" label="Address:" type="textarea" maxLength="1000"
                                                                            value={property.Address1} onChange={(e) => this.onKeyChange(e)} />
                                                                        <span className="errorMessage error-top-position"> {errors.Address1}</span>
                                                                    </Col>
                                                                    <Col md={6} className="pr-0">
                                                                        <AvField name="City" label={CONFIG.REGION_ID !== REGIONS.UK ? "City:" : "Town/City:"} type="text" maxLength="1000"
                                                                            value={property.City} onChange={(e) => this.onKeyChange(e)} />
                                                                        <span className="errorMessage error-top-position3"> {errors.City}</span>
                                                                    </Col>
                                                                    {/* {CONFIG.REGION_ID !== REGIONS.UK ? <Col md={6}>
                                                    <Label>State:</Label>
                                                    <AvField type="select" name="StateId" value={property.StateId}
                                                        onChange={(e) => this.onDDLChange(e)}
                                                    >
                                                        <option value="0">--Select--</option>
                                                        {stateList ? stateList.map(n => {
                                                            return (
                                                                <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                                    disabled={n.Id === '' ? true : false}
                                                                    value={n.Id}>
                                                                    {n.Id}
                                                                </option>
                                                            );
                                                        }) : ''}
                                                    </AvField>
                                                    <span className="errorMessage error-top-position3"> {errors.StateId}</span>
                                                </Col>
                                                    : ''}

                                                {CONFIG.REGION_ID !== REGIONS.UK ?
                                                    <Col md={6}>
                                                        <Label>Country:</Label>
                                                        <AvField type="select" name="CountryId" value={property.CountryId}
                                                            onChange={(e) => this.onDDLChange(e)} disabled
                                                        >
                                                            <option value="0">--Select--</option>
                                                            {countryList ? countryList.map(n => {
                                                                return (
                                                                    <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                                        disabled={n.Id === '' ? true : false}
                                                                        value={n.Id}>
                                                                        {n.Code}
                                                                    </option>
                                                                );
                                                            }) : ''}
                                                        </AvField>
                                                        <span className="errorMessage error-top-position3"> {errors.CountryId}</span>
                                                    </Col>
                                                    : ''}  */}

                                                                    <Col md="6">
                                                                        <Label>Country</Label>
                                                                        <AvField type="select" name="CountryCode" className="bs" validate={{
                                                                            required: { value: true, errorMessage: 'Country is required' }
                                                                        }} disabled onChange={(e) => this.onDDLChange(e)} value={property.CountryCode}>
                                                                            <option value='' >--Select Country--</option>
                                                                            {countryData && countryData.length > 0 ? countryData.map((state) => {
                                                                                if (state.Country !== "Scotland" && state.Country !== "Wales" && state.Country !== "Northern Ireland")
                                                                                    return (
                                                                                        <option key={state.Country} value={state.CountryCode}>{state.CountryCode === "England" ? 'UK' : state.CountryCode}</option>
                                                                                    )
                                                                            }) : ''}
                                                                        </AvField>
                                                                    </Col>

                                                                    {filteredState && filteredState.length > 0 && showState && selectedAddress.State !== null ?
                                                                        <Col md="6" className="pr-0 billing-state-province">
                                                                            <Label> State</Label>
                                                                            <AvField type="select" name="StateId" className='bs' validate={{
                                                                                required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.US ? 'State/Province is required' : 'State is required' }
                                                                            }} onChange={(e) => this.onDDLChange(e)} value={property.StateId}>
                                                                                <option value='0'>{CONFIG.REGION_ID === REGIONS.US ? "--Select State/Province--" : "--Select State--"}</option>
                                                                                {filteredState ? filteredState.filter(state => state.StateID !== '').map((state) => {
                                                                                    return (
                                                                                        <option key={state.StateID} className={state.StateID === '' ? "optHead disabled-states" : ''} disabled={state.StateID === '' ? true : false} value={state.StateCode}>{state.StateCode}</option>
                                                                                    )
                                                                                }) : ''}
                                                                            </AvField>
                                                                            <span className="errorMessage error-top-position"> {errors.StateId}</span>
                                                                            <span className="errorMessage error-top-position"> {errors.CountryId}</span>
                                                                        </Col>
                                                                        : ''}
                                                                    <Col md={6} className={CONFIG.REGION_ID === REGIONS.UK ? "zipcode-field pr-0" : "zipcode-field"}>
                                                                        <AvField name="ZipCode" label={CONFIG.REGION_ID === REGIONS.UK ? "Postcode:" : "Zip Code:"} type="text" maxLength="20"
                                                                            value={property.ZipCode} onChange={(e) => this.onKeyChange(e)} />
                                                                        <div onClick={() => this.handleSearch("makeTheSearch")} title="Search for coordinates" className='search-icon-btn'><FontAwesomeIcon icon={faSearch} /> Search</div>
                                                                        <span className="errorMessage error-top-position3"> {errors.ZipCode}</span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <Col md={6} className={CONFIG.REGION_ID === REGIONS.UK ? "" : "pr-0"}>
                                                                <AvField type="select" name="PropertyTypeId"
                                                                    label="Property Type:"
                                                                    onChange={(e) => this.onDDLChange(e)}
                                                                    value={property.PropertyTypeId}>
                                                                    <option value="0">--Select--</option>
                                                                    {propertyTypes ? propertyTypes.map(n => {
                                                                        return (
                                                                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                                disabled={n.ID === '' ? true : false}
                                                                                value={n.ID}>
                                                                                {n.Name}
                                                                            </option>
                                                                        );
                                                                    }) : ''}
                                                                </AvField>
                                                                <span className="errorMessage  error-bottom-position"> {errors.PropertyTypeId}</span>
                                                            </Col>
                                                            <Col md={6} className={CONFIG.REGION_ID === REGIONS.UK ? "align-uk-error-msg" : ""}>
                                                                <div className="row-flex">
                                                                    <Col md={9}><AvField type="radio" name="IsSingleProperty" checked={property.IsSingleProperty}
                                                                        onChange={(e) => this.onSinglePropertyChange(e)} label="Single Property"></AvField></Col>
                                                                    <Col><AvField type="radio" name="IsPortfolio" checked={property.IsPortfolio}
                                                                        onChange={(e) => this.onSinglePropertyChange(e)} label="Portfolio"></AvField></Col>
                                                                </div>
                                                                <span className="errorMessage error-bottom-position-4"> {errors.SingleProperty}</span>
                                                            </Col>
                                                        </Row>
                                                        <div className="property-img-admin">
                                                            <Label className='image-label'>Property Images:</Label>
                                                            <div className="multiple-background-vendors marign-l-5">

                                                                <React.Fragment>
                                                                    <React.Fragment>
                                                                        <div>
                                                                            {open ?
                                                                                <Modal isOpen={open} className="image-cropper">
                                                                                    <ModalHeader>Image cropper
                                                                                    </ModalHeader>
                                                                                    <ModalBody>
                                                                                        <div basic style={{ margin: "0px" }} textAlign="center">
                                                                                            <ReactCrop
                                                                                                src={imageToCrop.preview}
                                                                                                crop={crop}
                                                                                                onImageLoaded={this.onImageLoaded}
                                                                                                onChange={this.onChange}
                                                                                            />
                                                                                        </div>
                                                                                    </ModalBody>
                                                                                    <ModalFooter>
                                                                                        <Button className="reset-btn margin-r-0" onClick={() => { this.onCancel() }}>Cancel</Button>
                                                                                        <Button className="accept-btn" onClick={this.onAccept}>Accept</Button>
                                                                                    </ModalFooter>
                                                                                </Modal>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                        {this.state.uploadError || this.props.uploadError ? (
                                                                            <p className="margin-b-9">
                                                                                <p className="margin-b-3 error-msg"><em> Accepted file types: {this.props.allowedTypes.toString()}</em></p>

                                                                                <p className="margin-b-3 error-msg"><em>File size must not exceed <b>{this.props.maxFileSize} KB.</b></em></p>
                                                                            </p>
                                                                        ) : null}
                                                                        <Dropzone

                                                                            disableClick={true}
                                                                            onDrop={this.saveImage.bind(this)}
                                                                            className='dropzone'
                                                                            activeClassName='active-dropzone'
                                                                            multiple={false}
                                                                            maxSize={this.props.maxFileSize * 1000}
                                                                            accept={this.renderAcceptedFileTypes()}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <section>
                                                                                    <div {...getRootProps()} className="drop-file-select">
                                                                                        <input {...getInputProps()} />
                                                                                        <p><FontAwesomeIcon icon={faFileUpload} /> Drag and drop files here or click to select files</p>
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </Dropzone>
                                                                    </React.Fragment>
                                                                    {CONFIG.REGION_ID === REGIONS.US ?
                                                                        <span className="errorMessage error-bottom-position-6">{errors.imagesError}</span>
                                                                        : CONFIG.REGION_ID === REGIONS.UK ?
                                                                            <span className="errorMessage error-bottom-position-7">{errors.imagesError}</span>
                                                                            : ""}

                                                                    <span className="or-span-position">or</span>
                                                                    <span className="email-position">Email images to {CONFIG.SUPPORT_EMAIL} using <b>Property Code: {property.PropertyCode}</b> as subject</span>
                                                                </React.Fragment>
                                                            </div>

                                                        </div>

                                                        <Row className="property-img-admin margin-t-10 margin-b-10">
                                                            {propertyImages.length > 0 && propertyImages.map(image => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <Col sm={3}>
                                                                            <div className="type-container star-position" onClick={() => this.markPrimaryImage(image)}><img className="cursor-pointer" src={property.PrimaryImage === image ? fstar : pstar} alt="Primary Image" /></div>
                                                                            <div className="display-size">
                                                                                <img src={'/PropertyImages/' + image} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} className="width-100 height-100" alt="Property Images" />
                                                                            </div>
                                                                            <div className="type-container" onClick={() => this.removePropertyImages(image)}><img className="cursor-pointer" src={deleteicon} alt="Delete and Submit" /></div>
                                                                        </Col>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                            }

                                                        </Row>
                                                        {/* <Row>
                                                {this.state.croppedImageUrl ?
                                                    <img style={{ maxWidth: '100%' }} src={this.state.croppedImageUrl} /> : ''}
                                            </Row> */}
                                                    </AvForm>
                                                </Col>
                                                <Col md={6} className={CONFIG.REGION_ID === REGIONS.UK ? "right-col-map UK-right-col-map" : "right-col-map"}>
                                                    <div className=''><p className='map-information'><FontAwesomeIcon icon={faExclamationCircle} />Please use the map to correct the pin-point to update the GPS coordinates for the listing. The address entered will be used as the label.</p></div>
                                                    <MapGL
                                                        {...viewport}
                                                        ref={this.mapRef}
                                                        onViewportChange={this.handleViewportChange}
                                                        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                                                        mapStyle={"mapbox://styles/mapbox/streets-v11"}
                                                        attributionControl={false}
                                                        onClick={(e) => this.onclickMarker(e)}
                                                    // onClick={(e) => this.updateViewPort(Number(e.lngLat[0].toFixed(5)), Number(e.lngLat[1].toFixed(5)), "clicked")}
                                                    >
                                                        <Geocoder
                                                            mapRef={this.mapRef}
                                                            // ref={this.geocoderContainerRef}
                                                            onViewportChange={this.handleGeocoderViewportChange}
                                                            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                                                            position="top-left"
                                                            onResult={this.handleSearchResult}
                                                            marker={null}
                                                            reverseGeocode={true}
                                                            countries={CONFIG.REGION_ID !== REGIONS.US ? "GB" : "US,CA"}
                                                        />
                                                        <NavigationControl className='zoom-plus-minus' showCompass={false} />
                                                        {((property.Longitude !== undefined && property.Latitude !== undefined) && (!isNaN(property.Longitude) && !isNaN(property.Latitude))) &&
                                                            <Marker
                                                                longitude={Number(property.Longitude)}
                                                                latitude={Number(property.Latitude)}
                                                                draggable={true}
                                                                onDragEnd={(e) => this.onMarkerDragEnd(e)}
                                                                offsetLeft={-10} offsetTop={-50 / 2}
                                                            >
                                                                <div className='Property-image-pin'></div>
                                                            </Marker>}
                                                    </MapGL>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                        : activeStep === 1 ?
                                            <AvForm ref={c => (this.featureForm = c)}>
                                                <Row>
                                                    <Col sm={6} className="disply-flex input-field inputwidth">
                                                        <AvField name="NSRF" label={CONFIG.REGION_ID === REGIONS.UK ? "Net Rentable Sq. Ft.:" : "NRSF:"} type="text"
                                                            value={property.NSRF} onChange={(e) => this.onKeyFloatChange(e)} onKeyPress={(e) => this.onKeyPress(e)} onPaste={(e) => this.onPaste(e)} maxLength="13"
                                                        />
                                                        <span className="extra-lable">SF</span>
                                                        <span className="errorMessage error-top-position3"> {errors.NSRF}</span>
                                                    </Col>
                                                    <Col sm={6} className="disply-flex input-field inputwidth">
                                                        <AvField name="GSF" label={CONFIG.REGION_ID === REGIONS.UK ? "Gross Sq. Ft.:" : "GSF:"} type="text"
                                                            value={property.GSF} onChange={(e) => this.onKeyFloatChange(e)} onKeyPress={(e) => this.onKeyPress(e)} onPaste={(e) => this.onPaste(e)} maxLength="13" />
                                                        <span className="extra-lable">SF</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} className="disply-flex input-field inputwidth">
                                                        <AvField name="BuildingSize" label="Building Area:" type="text"
                                                            value={property.BuildingSize} onChange={(e) => this.onKeyFloatChange(e)} onKeyPress={(e) => this.onKeyPress(e)} onPaste={(e) => this.onPaste(e)} maxLength="13" />
                                                        <span className="extra-lable">SF</span>
                                                    </Col>
                                                    <Col sm={6} className="disply-flex input-field inputwidth">
                                                        <AvField name="CCArea" label={CONFIG.REGION_ID === REGIONS.UK ? "Climate Controlled Area:" : "CC Area:"} type="text"
                                                            value={property.CCArea} onChange={(e) => this.onKeyFloatChange(e)} onKeyPress={(e) => this.onKeyPress(e)} maxLength="13" onPaste={(e) => this.onPaste(e)} />
                                                        <span className="extra-lable">SF</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <AvField name="YearBuilt" label="Year Built:" type="text" maxLength="4"
                                                            value={property.YearBuilt} onChange={(e) => this.onKeyChange(e)} onKeyPress={(e) => this.onKeyPressOnlyNumber(e)} onPaste={(e) => this.onPasteOnlyNumber(e)} />
                                                        <span className="errorMessage error-top-position3"> {errors.YearBuilt}</span>
                                                    </Col>
                                                    <Col sm={6} className="disply-flex lot-size">
                                                        <AvField name="LotSize" label="Lot Size:" className="input-size" type="text"
                                                            value={property.LotSize} onChange={(e) => this.onKeyFloatChange(e)} onKeyPress={(e) => this.onKeyPress(e)} onPaste={(e) => this.onPaste(e)} maxLength="13" />
                                                        <div className="display-acres-lot">
                                                            <Label>
                                                                <AvField type="radio" name="acres" checked={property.LotSizeMeasurementId === 1 ? true : false}
                                                                    onChange={(e) => this.onLotSizeChange(e)} /><span>Acres</span>
                                                            </Label>
                                                        </div>
                                                        <div className="display-acres-lot2">
                                                            <Label>
                                                                <AvField type="radio" name="sqft" checked={property.LotSizeMeasurementId === 2 ? true : false}
                                                                    onChange={(e) => this.onLotSizeChange(e)} /><span>SF</span>
                                                            </Label>
                                                        </div>
                                                        <span className="errorMessage error-top-position3"> {errors.LotSize}</span>
                                                        <span className="errorMessage error-top-position3"> {errors.LotSizeMeasurementId}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <AvField name="UnitCount" label="Unit Count:" type="text"
                                                            value={property.UnitCount} onChange={(e) => this.onKeyNumberChange(e)} onKeyPress={(e) => this.onKeyPressOnlyNumber(e)} onPaste={(e) => this.onPaste(e)} maxLength="13" />
                                                        <span className="errorMessage error-top-position3"> {errors.UnitCount}</span>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <AvField name="NumberofFloors" label="Number of Floors:" type="text"
                                                            value={property.NumberofFloors} onChange={(e) => this.onKeyNumberChange(e)} onKeyPress={(e) => this.onKeyPressOnlyNumber(e)} onPaste={(e) => this.onPaste(e)} maxLength="13" />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} className="disply-flex input-field inputwidth">
                                                        <AvField name="CeilingHeight" label="Ceiling Height:" type="text"
                                                            value={property.CeilingHeight} onChange={(e) => this.onKeyNumberChange(e)} onKeyPress={(e) => this.onKeyPressOnlyNumber(e)} onPaste={(e) => this.onPaste(e)} maxLength="13" />
                                                        <span className="extra-lable">Feet</span>
                                                    </Col>
                                                    <Col sm={3} className="pr-0">
                                                        <Label className="noi-change">{CONFIG.REGION_ID === REGIONS.UK ? "Net Operating Income:" : "NOI:"}</Label>
                                                        <AvField type="select" name="NOICurrency" value={property.NOICurrency}
                                                            onChange={(e) => this.onDDLChange(e)}
                                                        >
                                                            {currencyList ? currencyList.map(n => {
                                                                return (
                                                                    <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                        disabled={n.ID === '' ? true : false}
                                                                        value={n.ID}
                                                                    >
                                                                        {n.Name}
                                                                    </option>
                                                                );
                                                            }) : ''}
                                                        </AvField>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <label></label>
                                                        <AvField name="NOI" type="text" label=""
                                                            value={property.NOI} onChange={(e) => this.onKeyNumberChange(e)} onKeyPress={(e) => this.onKeyPressOnlyNumber(e)} onPaste={(e) => this.onPaste(e)} maxLength="13" />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} className="disply-flex input-field inputwidth">
                                                        <AvField name="CapRate" label="Cap Rate:" type="text"
                                                            value={property.CapRate} onChange={(e) => this.onKeyChange(e)} onKeyPress={(e) => this.onKeyPressPercentage(e)} onPaste={(e) => this.onPastePercentage(e)} maxLength="5" />
                                                        <span className="extra-lable">%</span>
                                                    </Col>
                                                    <Col sm={6} className="disply-flex input-field inputwidth">
                                                        <AvField name="Occupancy" label="Occupancy:" type="text"
                                                            value={property.Occupancy} onChange={(e) => this.onKeyChange(e)} onKeyPress={(e) => this.onKeyPressPercentage(e)} onPaste={(e) => this.onPastePercentage(e)} maxLength="5" />
                                                        <span className="extra-lable">%</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Label>
                                                            <input type="checkbox" className="checkbox-align" name="IsRoomForExpansion" checked={property.IsRoomForExpansion}
                                                                onChange={(e) => this.onRoomExpansionChange(e)} /> <span className="room-of-expansion">Room for Expansion</span>
                                                        </Label>
                                                    </Col>
                                                    <Col sm={6}></Col>
                                                </Row>

                                            </AvForm>
                                            : activeStep === 2 ?
                                                <AvForm ref={c => (this.descriptionForm = c)}>
                                                    <Row className="description-content">
                                                        <Col sm={12} className="description-stepper">
                                                            <label>Description:</label>
                                                            {/* <AvField type="textarea" name="Description" value={property.Description} onChange={(e) => this.onKeyChange(e)} /> */}
                                                            <Editor
                                                                value={property.Description}
                                                                apiKey={CONFIG.TINY_MCE_KEY}
                                                                init={{
                                                                    height: 300,
                                                                    width: 500,
                                                                    menubar: true,
                                                                    plugins: "link",
                                                                }}
                                                                onEditorChange={this.onDescriptionChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="pricestepper">
                                                        <Col sm={2}>
                                                            <label>Price:</label>
                                                            <AvField type="select" name="PriceCurrency" value={property.PriceCurrency}
                                                                onChange={(e) => this.onDDLChange(e)}
                                                            >
                                                                {currencyList ? currencyList.map(n => {
                                                                    return (
                                                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                            disabled={n.ID === '' ? true : false}
                                                                            value={n.ID}
                                                                        >
                                                                            {n.Name}
                                                                        </option>
                                                                    );
                                                                }) : ''}
                                                            </AvField>
                                                            <span className="errorMessage price-error"> {errors.Price}</span>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <label></label>
                                                            <AvField name="Price" value={property.Price} onChange={(e) => this.onKeyNumberChange(e)} onKeyPress={(e) => this.onKeyPress(e)} onPaste={(e) => this.onPaste(e)} maxLength="13"></AvField>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <label>Price Alternative:</label>
                                                            <AvField type="select" name="AlternatePriceOptionID" value={property.AlternatePriceOptionID}
                                                                onChange={(e) => this.alternatePriceOption(e)}
                                                            >
                                                                <option value="0">--Select--</option>
                                                                {alternatePriceList ? alternatePriceList.map(n => {
                                                                    return (
                                                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                            disabled={n.ID === '' ? true : false}
                                                                            value={n.ID}>
                                                                            {n.Name}
                                                                        </option>
                                                                    );
                                                                }) : ''}
                                                            </AvField>
                                                            {this.state.isPriceAuction ?
                                                                <div className="auction-date">
                                                                    <DatePicker className="is-touched is-pristine av-valid form-control" placeholderText="Date" selected={property.AlternatePriceOptionDate} dateFormat={CONFIG.REGION_ID === REGIONS.US ? "MM-dd-yyyy" : "dd-MM-yyyy"} onChange={this.onAuctionDateChange} minDate={new Date()} />
                                                                    <span className="errorMessage "> {errors.AlternatePriceOptionDate}</span>

                                                                </div>
                                                                : ''}
                                                        </Col>
                                                        <Col className='mb' sm={3}>
                                                            <label className="desupload">Documents (PDFs):</label>
                                                            <FileUpload reqUrl="property/upload/document" parentMethod={this.handler} PropertyID={property.PropertyId} isNewDoc={true} />
                                                        </Col>
                                                        {propertyDocument && propertyDocument.length > 0 ?
                                                            <Col sm={12} className="mb-3">
                                                                <div className='tablethreescroll'>
                                                                    <Table className='destableborder'>
                                                                        <TableHead className='destable-head'>
                                                                            <TableRow>
                                                                                <TableCell>File Name</TableCell>
                                                                                <TableCell>Visible Name</TableCell>
                                                                                <TableCell >Action</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody className='destable' >
                                                                            {propertyDocument.map((doc) =>
                                                                                <TableRow>
                                                                                    <TableCell className='text-redus'>
                                                                                        {doc.ActualFileName && doc.ActualFileName.length > 30 ?
                                                                                            <span title={doc.ActualFileName}>{doc.ActualFileName.substring(0, 30) + "....pdf"}</span> : doc.ActualFileName}
                                                                                    </TableCell>
                                                                                    <TableCell className='text-redus'>
                                                                                        {doc.Title && doc.Title.length > 30 ?
                                                                                            <span title={doc.Title}>{doc.Title.substring(0, 30) + "..."}</span> : doc.Title}
                                                                                        <span className='pdftitleedt'>{<img src={edit} type="edit" alt="edit" className="cursor-pointer icon-table" title="Edit" onClick={() => this.toggleFileOpen(doc.ID, doc.Title)} />}</span>
                                                                                    </TableCell>
                                                                                    <TableCell className='twobtn'>
                                                                                        <FileUpload reqUrl="property/upload/document" parentMethod={this.handler} PropertyID={property.PropertyId} documentId={doc.ID} />
                                                                                        <Button className="noopadd desdeletedbtn" onClick={() => this.DeleteDocument(doc.ID)}>Delete</Button>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                            :
                                                            ""}
                                                    </Row>
                                                </AvForm>
                                                : activeStep === 3 ?
                                                    <div>
                                                        {isAdmin ?
                                                            <React.Fragment>
                                                                <div className="filters-search">
                                                                    <AvForm onSubmit={this.searchUsers.bind(this)} ref={c => (this.formUsers = c)}>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <AvField name="Name" label="Name:" type="text" />
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <AvField type="select" name="BrokerTypeID"
                                                                                    label="Broker Type:">
                                                                                    <option value="0">--Select--</option>
                                                                                    {userTypes ? userTypes.map(n => {
                                                                                        if (n.ID !== null) {
                                                                                            return (
                                                                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                                                    disabled={n.ID === '' ? true : false}
                                                                                                    value={n.ID}>
                                                                                                    {n.Name}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                        else {
                                                                                            return null;
                                                                                        }
                                                                                    }) : ''}
                                                                                </AvField>
                                                                            </Col>
                                                                            <Col md={12} className="text-align-right">

                                                                                <Button className="search-btn" ><FontAwesomeIcon icon={faSearch} /> Search</Button>
                                                                                <Button className="reset-btn" onClick={this.resetSearch.bind(this)}>Reset</Button>
                                                                            </Col>
                                                                            <span className="errorMessage error-tag-select-broker"> {errors.brokerTag}</span>
                                                                        </Row>
                                                                    </AvForm>
                                                                </div>
                                                                <div className="table-scroll tag-broker-table">
                                                                    {users.length > 0 ?
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell> Broker Name</TableCell>
                                                                                    <TableCell className="email-width">Broker Type</TableCell>
                                                                                    <TableCell className="width-80seller">Tag</TableCell>

                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {users.map((user) =>
                                                                                    <TableRow>
                                                                                        <TableCell>{user.FirstName} {user.LastName}</TableCell>
                                                                                        <TableCell className="email-width">{user.BrokerTypeName}</TableCell>
                                                                                        <TableCell className="width-80seller">
                                                                                            <input type="radio" name="BrokerID" checked={property.BrokerId === user.UserID ? true : false}
                                                                                                onChange={() => this.setBroker(user.UserID)} />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                                }
                                                                            </TableBody>
                                                                            <TableFooter>
                                                                                <TableRow>
                                                                                    <TableCell colSpan="3"><Pagination className="seaarch-pagination table-pagination-model" count={pageNumUser} showFirstButton showLastButton onChange={this.handleUserChangePage} />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableFooter>
                                                                        </Table>
                                                                        :
                                                                        <Table className="no-border">
                                                                            <TableRow className="no-border">
                                                                                <TableCell className="no-border">
                                                                                    <h6 className="no-records-found">No brokers found</h6>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </Table>
                                                                    }
                                                                </div>
                                                            </React.Fragment>
                                                            : isAdmin && property.BrokerTypeId === BROKERTYPES.Unauthorized ?
                                                                <React.Fragment>
                                                                    <div className="table-scroll tag-broker-table2">
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell> Broker Name</TableCell>
                                                                                    <TableCell className="email-width">Broker Type</TableCell>
                                                                                    <TableCell className="width-80seller">Tag</TableCell>

                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell>{property.BrokerName}</TableCell>
                                                                                    <TableCell className="email-width">Unauthorized</TableCell>
                                                                                    <TableCell className="width-80seller">
                                                                                        <input type="radio" name="BrokerID" checked={true}
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <CoBroker propertyId={property.PropertyId} brokerId={getUserID()} />
                                                        }
                                                    </div>
                                                    : activeStep === 4 ?
                                                        <div>
                                                            {isAdmin ?
                                                                <CoBroker propertyId={property.PropertyId} brokerId={property.BrokerId} />
                                                                :
                                                                <React.Fragment>
                                                                    <div className="review-box">
                                                                        <div className="list">
                                                                            <div className="list-item padding-bottom-remove">
                                                                                <p className="width-length">Review Listing:</p>
                                                                                <div className="list-content">
                                                                                    <p className="">Property <i><Link onClick={() => this.toggleModalOpen()}>{property.PropertyName} - click here</Link></i></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                        : activeStep === 5 ?
                                                            isAdmin ?
                                                                <React.Fragment>
                                                                    <div className="review-box">

                                                                        <div className="list">
                                                                            <div className="list-item padding-bottom-remove">
                                                                                <p className="width-length">Review Listing:</p>
                                                                                <div className="list-content">
                                                                                    <p className="">Property <i><Link onClick={() => this.toggleModalOpen()}>{property.PropertyName}- click here</Link></i></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment> :
                                                                skipPayment ?
                                                                    <React.Fragment>
                                                                        {property.IsPropertyAdd ?
                                                                            isPremiumBroker ?
                                                                                <p className="text-align-center margin-t-15 lineheight25 1">Thank you for uploading your property for sale.</p>
                                                                                :
                                                                                property.PropertyStatusId === 1 || property.PropertyStatusId === 7 ?
                                                                                    <p className="text-align-center margin-t-15">Your property has been successfully added! To view the property listing
                                                                                        <span className="font-italic color-link cursor-pointer" onClick={() => this.openPropertyViewPage(property)}> click here
                                                                                        </span>.</p> :
                                                                                    <p className="text-align-center margin-t-15 lineheight25 1">Thank you for uploading your property for sale. We are reviewing your account and will activate your listing as soon as possible.</p>
                                                                            :
                                                                            isPremiumBroker ?
                                                                                <p className="text-align-center margin-t-15 lineheight25 1">Thank you for uploading your property for sale.</p>
                                                                                :
                                                                                property.PropertyStatusId === 1 || property.PropertyStatusId === 7 ?
                                                                                    <p className="text-align-center margin-t-15">Your property has been successfully updated! To view the property listing
                                                                                        <span className="font-italic color-link cursor-pointer" onClick={() => this.openPropertyViewPage(property)}> click here
                                                                                        </span>.</p> :
                                                                                    <p className="text-align-center margin-t-15 lineheight25 1">Thank you for uploading your property for sale. We are reviewing your account and will activate your listing as soon as possible.</p>
                                                                        }
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment >
                                                                        <div className="card-frame">
                                                                            {CONFIG.REGION_ID === REGIONS.US ?
                                                                                (property.IsStripe || property.IsPropertyAdd) && isUnAuthBroker ?
                                                                                    <React.Fragment>
                                                                                        <p style={{ fontSize: '13px' }}>
                                                                                            Your account is not authorized by List Self Storage admin. Please proceed with submitting your billing and card details in the next step and your card will be charged with <b>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}39</b> for your listing once your account is authorized.
                                                                                        </p>
                                                                                        <Button onClick={() => this.handleStripe(property.PropertyId)} className='button-back-cancel purchase-link'>Purchase</Button>
                                                                                        <div className='clear'></div>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    (property.IsStripe || property.IsPropertyAdd) ?
                                                                                        <React.Fragment>
                                                                                            <p style={{ fontSize: '13px' }}>
                                                                                                Your card will be charged with <b>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}39/month</b> for publishing your
                                                                                                property on List Self Storage.
                                                                                            </p>
                                                                                            <Button onClick={() => this.handleStripe(property.PropertyId)} className='button-back-cancel purchase-link'>Purchase</Button>
                                                                                            <div className='clear'></div>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        <p style={{ fontSize: '13px' }}>
                                                                                            Your card will be charged with <b>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}39/month</b> for publishing your
                                                                                            property on List Self Storage. These charges will be recurring until the time range you have selected for publishing your property.
                                                                                        </p>
                                                                                :
                                                                                <p style={{ fontSize: '13px' }}>
                                                                                    Your card will be charged with <b>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}39/month</b> for publishing your
                                                                                    property on List Self Storage. These charges will be recurring until the time range you have selected for publishing your property.
                                                                                </p>
                                                                            }
                                                                            {CONFIG.REGION_ID === REGIONS.US && (property.IsStripe || property.IsPropertyAdd) ?
                                                                                "" :
                                                                                <AvForm ref={c => (this.myPaymentFormRef = c)}>
                                                                                    <Row className="classrow">
                                                                                        <Col sm={6} className="noterm">
                                                                                            <Label>
                                                                                                <AvField className="" type="radio" name="NoTerm" checked={(!property.TermId || 0 === property.TermId) ? true : false}
                                                                                                    onChange={(e) => this.onTermChange(e)} value={0} /><span>Run until Cancelled</span>
                                                                                            </Label>

                                                                                        </Col>
                                                                                        <Col sm={6}>
                                                                                            <Label>Run for fixed Period:</Label>
                                                                                            <AvField type="select" name="TermId" value={property.TermId}
                                                                                                onChange={(e) => this.onTermChange(e)}
                                                                                            >
                                                                                                <option value={0}>--Select--</option>
                                                                                                {termList ? termList.map(n => {
                                                                                                    return (
                                                                                                        <option key={n.ID} className={n.ID === 0 ? "optHead" : ''}
                                                                                                            disabled={n.ID === 0 ? true : false}
                                                                                                            value={n.ID}>
                                                                                                            {n.Name}
                                                                                                        </option>
                                                                                                    );
                                                                                                }) : ''}
                                                                                            </AvField>
                                                                                            <span className="errorMessage error-top-position3"> {errors.StateId}</span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <React.Fragment>
                                                                                        <p className="savedcardheading billing-address-heading">Billing Address</p>{isAddressFound ? null : <Button
                                                                                            onClick={() => this.accountModal()}
                                                                                            className="add_edit_btn">Add/Edit</Button>}
                                                                                        <Row className="mt-2">
                                                                                            <Col md="6" className="pr-0">
                                                                                                <Label>Address Line 1</Label>
                                                                                                <AvField name="BillingAddress"
                                                                                                    className={isAddressFound ? "" : "disabled-select"}
                                                                                                    onChange={this.handleChange} validate={{
                                                                                                        required: { value: true, errorMessage: 'Address Line 1 is required' },
                                                                                                        maxLength: { value: 150, errorMessage: 'Your BillingAddress must be 150 characters' }
                                                                                                    }} value={isAddressChanged && selectedAddress.Address1 || editAddress.IsDefault === true ? selectedAddress.Address1 : property.UserDetail.BillingAddress || ''} />
                                                                                            </Col>
                                                                                            <Col md="6">
                                                                                                <Label> Address Line 2</Label>
                                                                                                <AvField name="BillingAddress2"
                                                                                                    className={isAddressFound ? "" : "disabled-select"}
                                                                                                    onChange={this.handleChange} value={isAddressChanged && selectedAddress.Address2 || editAddress.IsDefault === true ? selectedAddress.Address2 !== null ? selectedAddress.Address2 : '' : property.UserDetail.BillingAddress2 || ''}
                                                                                                    validate={{ maxLength: { value: 150, errorMessage: 'Your BillingAddress2 must be 150 characters' } }} />
                                                                                            </Col>
                                                                                            <Col md="6" className="pr-0">
                                                                                                {CONFIG.REGION_ID === REGIONS.US ?
                                                                                                    <Label> City</Label>
                                                                                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                                                                                        <Label>City/Town</Label>
                                                                                                        : ""}

                                                                                                <AvField name="BillingCity" onChange={this.handleChange}
                                                                                                    className={isAddressFound ? "" : "disabled-select"}
                                                                                                    validate={{
                                                                                                        required: { value: true, errorMessage: CONFIG.REGION_ID !== REGIONS.UK ? "Town/City is required" : "City is required" },
                                                                                                        maxLength: { value: 100, errorMessage: 'Your BillingCity must be 100 characters' }
                                                                                                    }} value={isAddressChanged && selectedAddress.City || editAddress.IsDefault === true ? selectedAddress.City : property.UserDetail.BillingCity || ''} />
                                                                                            </Col>

                                                                                            <Col md="6">
                                                                                                <Label>Country</Label>
                                                                                                <AvField type="select" name="BillingCountry" className={isAddressFound ? "bs" : "bs disabled-select"} validate={{
                                                                                                    required: { value: true, errorMessage: 'Country is required' }
                                                                                                }} onChange={this.handleChange} value={isAddressChanged && property.UserDetail.BillingCountrys ? property.UserDetail.BillingCountrys : property.UserDetail.BillingCountry}>
                                                                                                    <option value='' >--Select Country--</option>
                                                                                                    {countryData && countryData.length > 0 ? countryData.map((state) => {
                                                                                                        if (state.Country !== "Scotland" && state.Country !== "Wales" && state.Country !== "Northern Ireland")
                                                                                                            return (
                                                                                                                <option key={state.Country} value={state.CountryCode}>{state.Country === "England" ? 'United Kingdom' : state.Country}</option>
                                                                                                            )
                                                                                                    }) : ''}
                                                                                                </AvField>
                                                                                            </Col>

                                                                                            {filteredStates && filteredStates.length > 0 && showStates ?
                                                                                                <Col md="6" className="pr-0 billing-state-province">
                                                                                                    <Label> {CONFIG.REGION_ID === REGIONS.US ? "State/Province" : "State"}</Label>
                                                                                                    <AvField type="select" name="BillingState" className={isAddressFound ? 'bs' : "bs disabled-select"} validate={{
                                                                                                        required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.US ? 'State/Province is required' : 'State is required' }
                                                                                                    }} onChange={this.handleChange} value={isAddressChanged && selectedAddress.State || editAddress.IsDefault === true ? selectedAddress.State : property.UserDetail.BillingState}>
                                                                                                        <option value='' >{CONFIG.REGION_ID === REGIONS.US ? "--Select State/Province--" : "--Select State--"}</option>
                                                                                                        {filteredStates ? filteredStates.filter(state => state.StateID !== '').map((state) => {
                                                                                                            return (
                                                                                                                <option key={state.StateID} className={state.StateID === '' ? "optHead disabled-states" : ''} disabled={state.StateID === '' ? true : false} value={state.StateID}>{state.State}</option>
                                                                                                            )
                                                                                                        }) : ''}
                                                                                                    </AvField>
                                                                                                </Col>
                                                                                                : ''}

                                                                                            {CONFIG.REGION_ID === REGIONS.UK ?
                                                                                                <Col md="6">
                                                                                                    <Label>Zip/Postcode</Label>
                                                                                                    <AvField name="BillingZipCode" onChange={this.handleChange}
                                                                                                        className={isAddressFound ? "" : "disabled-select"}
                                                                                                        validate={{
                                                                                                            required: { value: true, errorMessage: 'Zip/Postcode is required' },
                                                                                                            pattern: { value: '^[A-Za-z0-9]+$', errorMessage: 'Zip/Postcode is invalid ' },
                                                                                                            maxLength: { value: 10, errorMessage: 'Your Zip/Postcode must below 10 characters' }
                                                                                                        }} value={isAddressChanged && selectedAddress.ZipCode || editAddress.IsDefault === true ? selectedAddress.ZipCode : property.UserDetail.BillingZipCode || ''} />
                                                                                                </Col>
                                                                                                :
                                                                                                <Col md="6">
                                                                                                    <Label>Zip/Postcode</Label>
                                                                                                    <AvField name="BillingZipCode" onChange={this.handleChange}
                                                                                                        className={isAddressFound ? "" : "disabled-select"}
                                                                                                        validate={{
                                                                                                            required: { value: true, errorMessage: 'Zip/Postcode is required' },
                                                                                                            pattern: { value: '^[A-Za-z0-9]+$', errorMessage: 'Zip/Postcode is invalid ' },
                                                                                                            maxLength: { value: 10, errorMessage: 'Your Zip/Postcode must below 10 characters' }
                                                                                                        }} value={isAddressChanged && selectedAddress.ZipCode || editAddress.IsDefault === true ? selectedAddress.ZipCode : property.UserDetail.BillingZipCode || ''} />
                                                                                                </Col>
                                                                                            }
                                                                                        </Row>
                                                                                    </React.Fragment>
                                                                                    {
                                                                                        existingCards && existingCards.length > 0 ?
                                                                                            <React.Fragment>
                                                                                                <p className="savedcardheading">Saved Card Details</p>
                                                                                                {
                                                                                                    existingCards.map((card, idx) => {
                                                                                                        return (
                                                                                                            <Row className="new-c-card2">
                                                                                                                <Col sm={12}>
                                                                                                                    <Label>
                                                                                                                        <AvField className="checkbox-align" type="radio" name={"token" + idx} checked={card.Token === property.PaymentDetail.Token ? true : false}
                                                                                                                            onChange={(e) => this.onTokenChange(e)} value={card.Token} /><span><FontAwesomeIcon icon={faCreditCard} /> {'Card: ' + card.MaskedNumber}</span>
                                                                                                                    </Label>
                                                                                                                    {card.Token === property.PaymentDetail.Token ?
                                                                                                                        <React.Fragment>
                                                                                                                            <Row className="set-btm-mr">
                                                                                                                                <Col sm={2}>
                                                                                                                                    <AvField name="SecurityCode2" id="SecurityCode2" placeholder="CVV" onKeyPress={(e) => this.onKeyPressOnlyNumber(e)}
                                                                                                                                        onChange={this.handleChange} value={SecurityCode2} maxLength="4" onPaste={(e) => this.onPasteOnlyNumber(e)} />
                                                                                                                                    <span className="errorMessage price-error2">{errors.SecurityCode2}</span>
                                                                                                                                </Col>

                                                                                                                                <Col sm={10}>
                                                                                                                                    <Button variant="contained" className="next-btn" onClick={this.handleNext.bind(this, 'Payment')}>
                                                                                                                                        Next
                                                                                                                                    </Button>
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </React.Fragment>
                                                                                                                        : ''
                                                                                                                    }
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </React.Fragment>
                                                                                            : ''
                                                                                    }

                                                                                    <Row className="new-c-card">
                                                                                        <Col sm={12} className="newcardstepper">
                                                                                            <Label>
                                                                                                <AvField className="checkbox-align" type="radio" name="newtoken" checked={"" === property.PaymentDetail.Token ? true : false}
                                                                                                    onChange={(e) => this.onTokenChange(e)} value={""} /><span>New Card</span>
                                                                                            </Label>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row className="set-btm-mr">
                                                                                        <Col sm={12}>
                                                                                            <Label>Name on Card</Label>
                                                                                            <AvField name="CardName" id="Name"
                                                                                                onChange={this.handleChange} value={property.PaymentDetail.CardName} />
                                                                                            <span className="errorMessage price-error2">{errors.CardName}</span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row className="card-fields card-fields-new">
                                                                                        <Col sm={12}>
                                                                                            <div className='cards-new-fiels'>
                                                                                                <Label>Card Credentials</Label>
                                                                                                <CreditCardInput
                                                                                                    customTextLabels={{
                                                                                                        invalidCvc: "CVV is invalid"
                                                                                                    }}
                                                                                                    cardNumberInputProps={{ value: property.PaymentDetail.CardNumber, name: "CardNumber", placeholder: 'Card Number', onChange: this.handleChange }}
                                                                                                    cardExpiryInputProps={{ value: property.PaymentDetail.Expiry, name: "Expiry", placeholder: 'MM/YY', onChange: this.handleChange }}
                                                                                                    cardCVCInputProps={{ value: property.PaymentDetail.SecurityCode, name: "SecurityCode", placeholder: 'CVV', onChange: this.handleChange }}
                                                                                                    fieldClassName={errors.errCard ? "input is-invalid" : "input"}
                                                                                                />
                                                                                            </div>
                                                                                            <span className="errorMessage price-error2">{errors.errCard}</span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvForm>}
                                                                        </div>
                                                                    </React.Fragment>
                                                            : activeStep === 6 ?
                                                                <React.Fragment>
                                                                    {property.IsPropertyAdd ?
                                                                        isUnAuthBroker ?
                                                                            <p className="text-align-center margin-t-15 lineheight25">Thank you for uploading your property for sale. We are reviewing your account and will activate your listing as soon as possible.</p>
                                                                            :
                                                                            <p className="text-align-center margin-t-15">{isAdmin && isStatusValue === false ? "Property has been successfully added!" : isAdmin ? "Property has been successfully added!" : CONFIG.REGION_ID === REGIONS.UK ? "Your property has been successfully added!" : "Your payment is done successfully and your property has been added!"} {isAdmin && isStatusValue === false ? '' : <>To view the property listing
                                                                                <span className="font-italic color-link cursor-pointer" onClick={() => this.openPropertyViewPage(property)}> click here
                                                                                </span></>}.</p> :
                                                                        isUnAuthBroker ?
                                                                            <p className="text-align-center margin-t-15 lineheight25">Thank you for uploading your property for sale. We are reviewing your account and will activate your listing as soon as possible.</p>
                                                                            :
                                                                            <p className="text-align-center margin-t-15">{isAdmin && isStatusValue === false ? "Property has been successfully added!" : isAdmin ? "Property has been successfully added!" : "Your property has been successfully added!"} {isAdmin && isStatusValue === false ? '' : <>To view the property listing
                                                                                <span className="font-italic color-link cursor-pointer" onClick={() => this.openPropertyViewPage(property)}> click here
                                                                                </span></>}.</p>}
                                                                </React.Fragment>
                                                                : ''}
                                </div>
                                {activeStep !== stepsLength - 1 ?
                                    <div className="text-align-right">
                                        {activeStep === 0 ?
                                            <Button className="button-back-cancel"
                                                onClick={this.cancelProperty.bind(this)}
                                            >  Cancel</Button>
                                            : activeStep !== 5 || (activeStep === 5 && skipPayment) || isAdmin ?
                                                <Button className="button-back-cancel"
                                                    onClick={this.handleBack.bind(this)}
                                                >  Back  </Button>
                                                : ''
                                        }
                                        {activeStep === 4 ?//co broker, Review 
                                            isAdmin ?
                                                <Button variant="contained" className="next-btn" onClick={this.handleNext.bind(this, 'Next')}>
                                                    Next
                                                </Button> :
                                                <Button variant="contained" className="next-btn" onClick={this.handleNext.bind(this, skipPayment ? 'Submit' : 'Next')}>
                                                    {skipPayment ? 'Submit' : 'Next'}
                                                </Button>
                                            : activeStep === 5 ?//Payment ,Review
                                                (property.IsStripe || (CONFIG.REGION_ID === REGIONS.US && property.IsPropertyAdd)) && isAdmin === false ?
                                                    "" :
                                                    <Button variant="contained" className="next-btn" onClick={this.handleNext.bind(this, skipPayment ? 'Submit' : 'Payment')}>
                                                        Submit
                                                    </Button>
                                                : //info, features, price, tag broker
                                                <Button variant="contained" className="next-btn" onClick={this.handleNext.bind(this, 'Next')}>
                                                    Next
                                                </Button>
                                        }
                                        {activeStep !== stepsLength - 2 ?
                                            property.IsPropertyAdd ?
                                                <Button variant="contained" className="search-btn" onClick={this.saveAsDraft.bind(this)}>
                                                    Save As Draft </Button>
                                                : <Button variant="contained" className="search-btn" onClick={this.saveAsDraft.bind(this)}>
                                                    Save and Close</Button>
                                            : ''}
                                    </div>
                                    : ''}


                            </Container>

                            <Modal isOpen={this.state.aModel} backdrop="static" toggle={this.handleClosed.bind(this)} className="custom-popup">
                                <ModalHeader toggle={this.handleClosed.bind(this)}
                                // toggle={this.accountModal.bind(this)}
                                >Billing Details
                                </ModalHeader>

                                <ModalBody>   {setStep === 1 ? <div>
                                    <div className="add_edit_popup_body">
                                        <div className="max-height-address">
                                            <FormControl
                                                className="ld_fs"
                                                component="fieldset"
                                                style={{ width: "100%", marginTop: "3px" }}
                                            >

                                                {addressDetails && addressDetails.length > 0
                                                    ? addressDetails.map((data, index) => {
                                                        return (
                                                            <>
                                                                <div key={data.Id} className="radio-btns">
                                                                    <FormControlLabel
                                                                        htmlFor="IsDefault"
                                                                        className="radio_cls"
                                                                        value={data.IsDefault}
                                                                        checked={data.Id === addressChecked ? true : false}
                                                                        control={<Radio onClick={() => this.toggle(data)}

                                                                        />}
                                                                        label={`${data.Address1 + ', '} ${data.Address2 !== null ? data.Address2 + ', ' : ''} ${data.City + ', '} ${data.State !== null ? data.State : ''} ${data.ZipCode}`}
                                                                    />

                                                                    <div className="btns-btm">
                                                                        <Button onClick={(e) => this.editMarket(data)} className="edit-address"><FontAwesomeIcon icon={faEdit} />Edit</Button>
                                                                        <Button disabled={data.IsDefault === true ? true : false} onClick={(e) => this.deleteAddresss(data.Id)} className="remove-address"><FontAwesomeIcon icon={faTrashAlt} />delete</Button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })
                                                    : <div className="text-center"> {isLoading ? '' :
                                                        <p className="text-danger">No data found</p>} </div>}
                                            </FormControl>
                                            <div className="footer-btns-address">
                                                <Button onClick={() => this.addNewAddress()} className="add-new-address-btn float-left">Add New Address</Button>
                                                <Button onClick={() => this.onChangeAddress()} className="select-address-btn float-right">Select</Button>
                                                <div className="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <AvForm onSubmit={this.handleEditSubmit.bind(this)}>
                                    <Row>
                                        <Col md="12">
                                            <Row className="enter-acc-details add-or-edit">
                                                <Col md="12">
                                                    <Label> Address Line 1</Label>
                                                    <AvField
                                                        name="BillingAddress"
                                                        // className="disabled-select"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: "Address Line 1 is required",
                                                            },
                                                            maxLength: {
                                                                value: 150,
                                                                errorMessage:
                                                                    "Your BillingAddress must be 150 characters",
                                                            },
                                                        }}
                                                        // onChange={handleChange}
                                                        value={setStep === 2 ? editAddress.Address1 || '' : ""}
                                                    />
                                                </Col>
                                                <Col md="12">
                                                    <Label> Address Line 2</Label>
                                                    <AvField
                                                        name="Billing_Address2"
                                                        // className="disabled-select"
                                                        // onChange={handleChange}

                                                        value={setStep === 2 ? editAddress.Address2 || '' : ""}
                                                        validate={{
                                                            maxLength: {
                                                                value: 150,
                                                                errorMessage:
                                                                    "Your BillingAddress2 must be 150 characters",
                                                            },
                                                        }}
                                                    />
                                                </Col>
                                                <Col md="6" className="pr-2">
                                                    <Label> City</Label>
                                                    <AvField
                                                        name="BillingCity"
                                                        // className="disabled-select"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: "City is required",
                                                            },
                                                            maxLength: {
                                                                value: 100,
                                                                errorMessage:
                                                                    "Your BillingCity must be 100 characters",
                                                            },
                                                        }}
                                                        // onChange={handleChange}
                                                        value={setStep === 2 ? editAddress.City || '' : ""}
                                                    />
                                                </Col>

                                                <Col md="6" className="pl-2">
                                                    <Label>Country</Label>
                                                    <AvField type="select" name="BillingCountrys" className="bs" validate={{
                                                        required: { value: true, errorMessage: 'Country is required' }
                                                    }} disabled={editAddress.IsDefault === true ? true : false} onChange={this.handleChange} value={setStep === 2 ? this.state.StateCountrys : ''}>
                                                        <option value='' >--Select Country--</option>
                                                        {countryData && countryData.length > 0 ? countryData.map((state) => {
                                                            if (state.Country !== "Scotland" && state.Country !== "Wales" && state.Country !== "Northern Ireland")
                                                                return (
                                                                    <option key={state.Country} value={state.CountryCode}>{state.Country === "England" ? 'United Kingdom' : state.Country}</option>
                                                                )
                                                        }) : ''}
                                                    </AvField>
                                                </Col>

                                                {filteredState && filteredState.length > 0 && showState ?
                                                    <Col md="6" className="pr-2 billing-state-province">
                                                        <Label> {CONFIG.REGION_ID === REGIONS.US ? "State/Province" : "State"}</Label>
                                                        <AvField type="select" name="State" className="bs" validate={{
                                                            required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.US ? 'State/Province is required' : 'State is required' }
                                                        }} onChange={this.handleChange} value={setStep === 2 ? editAddress.State || '' : ""}>
                                                            <option value='' >{CONFIG.REGION_ID === REGIONS.US ? "--Select State/Province--" : "--Select State--"}</option>
                                                            {filteredState ? filteredState.filter(state => state.StateID !== '').map((state) => {
                                                                return (
                                                                    <option key={state.StateID} className={state.StateID === '' ? "optHead disabled-states" : ''} disabled={state.StateID === '' ? true : false} value={state.StateID}>{state.State}</option>
                                                                )
                                                            }) : ''}
                                                        </AvField>
                                                    </Col>
                                                    : ''}

                                                {/* {CONFIG.Region_Id === Regions.UK ?
                                                                                <Col md={filteredState && filteredState.length > 0 && showStates === true ? 6 : 12}>
                                                                                    <Label>Zip/Postal Code</Label>
                                                                                    <AvField name="BillingZipCode"  validate={{
                                                                                        required: { value: true, errorMessage: REGION_ID === REGIONS.UK ? CONFIG.Region_Zip + ' is required' : 'Zip/Postal Code is required' },
                                                                                        //   pattern: { value: '^[A-Za-z0-9]+$', errorMessage: CONFIG.Region_Id === Regions.UK ? CONFIG.Region_Zip +' is invalid ':  'Zip Code is invalid ' },
                                                                                        pattern: { value: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$', errorMessage: REGION_ID === REGIONS.UK ? CONFIG.Region_Zip + ' is invalid ' : 'Zip Code is invalid ' },
                                                                                        maxLength: { value: 10, errorMessage: REGION_ID === REGIONS.UK ? 'Your ' + CONFIG.Region_Zip + ' must below 10 characters' : 'Your Zip/Postal Code must below 10 characters' }
                                                                                    }} onChange={this.handleChange} value={setStep === 2 ? editAddress.zipCode || '' : ""} />
                                                                                </Col>
                                                                                :
                                                                                <Col md={filteredState && filteredState.length > 0 && showStates === true ? 6 : 12}>
                                                                                    <Label>Zip/Postal Code</Label>
                                                                                    <AvField name="BillingZipCode"  validate={{
                                                                                        required: { value: true, errorMessage: 'Zip/Postal Code is required' },
                                                                                        pattern: { value: '^[A-Za-z0-9]+$', errorMessage: 'Zip/Postal Code is invalid ' },
                                                                                        maxLength: { value: 10, errorMessage: 'Your Zip/Postal Code must below 10 characters' }
                                                                                    }} onChange={this.handleChange} value={setStep === 2 ? editAddress.zipCode || '' : ""} />
                                                                                </Col>
                                                                            } */}
                                                {CONFIG.REGION_ID === REGIONS.UK ?
                                                    <Col md={filteredState && filteredState.length > 0 && showState === true ? 6 : 6}>
                                                        <Label>Zip/Postcode</Label>
                                                        <AvField name="Bt_Zipcode" onChange={this.handleChange} validate={{
                                                            required: { value: true, errorMessage: 'Zip/Postcode is required' },
                                                            pattern: { value: '^[A-Za-z0-9]+$', errorMessage: 'Zip/Postcode is invalid ' },
                                                            maxLength: { value: 10, errorMessage: 'Your Zip/Postcode must below 10 characters' }
                                                        }} value={setStep === 2 ? editAddress.ZipCode || '' : ""} />
                                                    </Col>
                                                    :
                                                    <Col md={6} className={filteredState && filteredState.length > 0 && showState ? 'pl-2' : 'pr-2'}>
                                                        <Label>Zip/Postcode</Label>
                                                        <AvField name="Bt_Zipcode" onChange={this.handleChange} validate={{
                                                            required: { value: true, errorMessage: 'Zip/Postcode is required' },
                                                            pattern: { value: '^[A-Za-z0-9]+$', errorMessage: 'Zip/Postcode is invalid ' },
                                                            maxLength: { value: 10, errorMessage: 'Your Zip/Postcode must below 10 characters' }
                                                        }} value={setStep === 2 ? editAddress.ZipCode || '' : ""} />
                                                    </Col>
                                                }

                                            </Row>
                                        </Col>
                                    </Row>
                                    <Button type="submit" className="save-btn add-edit-popup-btn">  Save  </Button>
                                    <Button color="secondary" onClick={() => this.handleEditBack(setStep)} className="cancel-btn add-edit-popup-btn" >Cancel</Button>
                                    <div className="clear"></div>
                                </AvForm>}</ModalBody>
                            </Modal>
                            <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={showFile} toggle={this.toggleFileClose.bind(this)} className="vendor-assign-table">
                                <AvForm onSubmit={this.submitFileName.bind(this)} ref={c => (this.formFiles = c)}>
                                    <ModalHeader toggle={this.toggleFileClose.bind(this)}>Change Visible Name</ModalHeader>
                                    <ModalBody className="overflow-scroll popup-overflow">
                                        <div className="filters-search user-management-form">
                                            <div>
                                                <React.Fragment>
                                                    <h2 className="account-sapce">Visible Name</h2>
                                                    <AvField name="Title" type="text" value={docTitle} validate={{
                                                        required: { value: true, errorMessage: 'Visible Name is required' }
                                                    }} />
                                                </React.Fragment>
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Row className="float-right">
                                            <div >
                                                <Button id="btnSave" className="save-btn" type="submit" >Save</Button>
                                                <Button className="reset-btn " onClick={this.toggleFileClose.bind(this)} >Cancel</Button>
                                            </div>
                                        </Row>
                                    </ModalFooter>
                                </AvForm>
                            </Modal>
                        </Col></Row>
                </div >
                : '');
    }
}

export default connect()(AddProperty);