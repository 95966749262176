import React, { Component } from 'react';
import { Row, Col, Button, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faSearch } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from "react-router-dom";
import { CONFIG } from '../../../../Utils/config';
import { PROPERTYSTATUS, BROKERTYPES, getDateFormat, REGIONS } from '../../../../Utils/utils';
import axios from 'axios';
import { connect } from 'react-redux';
import { setSnackbar } from '../../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../../redux/ducks/application';
import edit from '../../../../Assets/Img/edit.png';
import archive from '../../../../Assets/Img/archive.png';
import unarchive from '../../../../Assets/Img/unarchive.png';
import approve from '../../../../Assets/Img/pending.png';
import changestatus from '../../../../Assets/Img/change-status.png';
import copy from '../../../../Assets/Img/copy.png';
import './SearchProperty.css';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Helmet from '../../../../Utils/Helmet';

class SearchProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            stateList: [],
            newsTypes: [],
            propertyStatus: [],
            pagination: {},
            newsTypeIds: [],
            searchCriteria: {
            },
            isChangeStatus: false,
            propertyIdStatusChange: '',
            pageNum: 0,
            IsArchived: 0,
            brokerList: [],
            isArchiveHide: false,
            selectedBroker: null,
            IsShowArchived: false
        };
        this.onBrokerSelect = this.onBrokerSelect.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getLookupStatus();
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            this.getLookupCountry();
        }
        this.getBrokerLogin();
        this.InitialSearchValue();
        localStorage.removeItem('SURL');
    }

    InitialSearchValue() {
        var searchCriteria = {
            PropertyCode: '',
            PropertyName: '',
            PropertyStatusId: 0,
            City: '',
            StateID: 0,
            BrokerId: null,
            RegionID: CONFIG.REGION_ID,
            CountryID: CONFIG.REGION_ID === REGIONS.US ? CONFIG.US_CountryId : '',

            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: '',
            SortOrder: '',
        }
        this.setState({ searchCriteria, isArchiveHide: false, selectedBroker: null, IsShowArchived: false }, () => {
            this.getProperties(searchCriteria);
            if (CONFIG.REGION_ID !== REGIONS.UK) {
                this.getLookupState();
            }
        });
    }

    getLookupStatus() {
        axios.get(CONFIG.API_URL + 'lookup/property/status').then(response => {
            if (response.status === 200) {
                this.setState({ propertyStatus: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    getLookupState() {
        const { searchCriteria } = this.state;

        axios.get(CONFIG.API_URL + 'lookup/state/' + searchCriteria.CountryID).then(response => {
            if (response.status === 200) {
                this.setState({ stateList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getBrokerLogin() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'admin/brokerlogin/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                this.setState({ brokerList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getLookupCountry() {
        axios.get(CONFIG.API_URL + 'lookup/country/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                this.setState({ countryList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getProperties(data) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        this.props.dispatch(showLoader());
        axios.post(CONFIG.API_URL + 'admin/search/property', data).then(response => {
            if (response.status === 200) {
                this.props.dispatch(hideLoader());
                this.setState({
                    searchResult: response.data.Property, pagination: response.data.Pagination,
                    pageNum: Math.ceil(response.data.Pagination.TotalRecords / 10)
                });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    onSearchTextChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria[e.target.name] = e.target.value;
        this.setState({ searchCriteria })
    }

    handleChangePage = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            this.getProperties(searchCriteria);
        });

    };


    onSearchStatusChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.PropertyStatusId = e.target.value;
        if (parseInt(searchCriteria.PropertyStatusId) === 8) this.setState({ isArchiveHide: true })
        else this.setState({ isArchiveHide: false })
        this.setState({ searchCriteria })
    }

    onPropertyStatusChange(e, record) {
        var statusId = e.target.value;
        var id = record.PropertyId;
        var propertyStatus = record.PropertyStatusId;
        var statusName = e.target.selectedOptions[0].innerHTML;
        const { searchCriteria } = this.state;
        if (statusName !== "--Select--") {
            confirmAlert({
                message: 'Are you sure to change this status?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            if (propertyStatus === PROPERTYSTATUS.Closed) {
                                const { from } = { from: { pathname: "/admin/editproperty/" + id + "/" + statusId } };
                                this.props.history.push(from);
                            }
                            else {
                                let token = localStorage.getItem('accessKey');
                                if (token) {
                                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                                }
                                this.setState({ isLoading: true });
                                let url = 'property/status/' + id + '/' + statusId + '/' + record.PropertyStatusId + '/' + CONFIG.REGION_ID
                                if (CONFIG.REGION_ID === REGIONS.US && record.IsStripe === true) {
                                    url = 'property/stripestatus/' + id + '/' + statusId + '/' + record.PropertyStatusId + '/' + CONFIG.REGION_ID
                                }

                                axios.put(CONFIG.API_URL + url)
                                    .then(res => {
                                        let searchResult = this.state.searchResult;
                                        const idx = searchResult.findIndex(property => property.PropertyId === id);
                                        searchResult[idx].Status = statusName;
                                        this.setState({ isChangeStatus: false, propertyIdStatusChange: '', searchResult });
                                        this.getProperties(searchCriteria);
                                        this.props.dispatch(setSnackbar(true, "success", "Property status updated successfully"));
                                    })
                                    .catch(err => {
                                        this.setState({ isLoading: false });
                                        this.props.dispatch(setSnackbar(true, "error", "Something went wrong please reach out to technical team for assistance."));
                                    });
                            }
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        }
    }

    onStateChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.StateID = e.target.value;
        this.setState({ searchCriteria });
    }

    onCountryChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.CountryID = e.target.value;
        this.setState({
            searchCriteria
        });

    }

    onBrokerSelect = (event, selectedList, selectedItem) => {
        const { searchCriteria } = this.state;
        if (selectedList) searchCriteria.BrokerId = selectedList.ID;
        else searchCriteria.BrokerId = null;
        let selectedBroker = selectedList;
        this.setState({
            searchCriteria, selectedBroker
        });
    }


    searchSubmit() {
        const { searchCriteria } = this.state;
        searchCriteria.Page = 1;
        this.setState({ searchCriteria }, () => {
            this.getProperties(searchCriteria);
        });
    }

    resetSearch() {
        this.InitialSearchValue();
    }

    filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 300,
    });

    editProperty(property) {
        const { from } = { from: { pathname: "/admin/editproperty/" + property.PropertyId } };
        this.props.history.push(from);
    }

    handleArchived(e) {
        let { searchCriteria, IsShowArchived } = this.state;
        if (e.target.checked === false) {
            searchCriteria.IsArchived = false;
            IsShowArchived = false;
        }
        else {
            searchCriteria.IsArchived = true;
            IsShowArchived = true;
        }
        this.setState({ IsShowArchived })
        //this.getProperties(searchCriteria);
    }

    archiveProperty(property) {
        var id = property.PropertyId;
        const { searchCriteria } = this.state;
        var propertyStatus = property.PropertyStatusId;
        let message = propertyStatus === PROPERTYSTATUS.Archived ? 'Are you sure to unarchive this property?' : 'Are you sure to archive this property?';
        confirmAlert({
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (propertyStatus === PROPERTYSTATUS.Archived) {
                            let token = localStorage.getItem('accessKey');
                            if (token) {
                                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                            }
                            axios.post(CONFIG.API_URL + 'admin/unarchive/' + property.PropertyId)
                                .then(res => {
                                    this.getProperties(searchCriteria);
                                    this.props.dispatch(setSnackbar(true, "success", "Property unarchived successfully"));
                                })
                                .catch(err => {
                                    this.setState({ isLoading: false });
                                    this.props.dispatch(setSnackbar(true, "error", "Something went wrong please reach out to technical team for assistance."));
                                });
                        }
                        else {
                            axios.put(CONFIG.API_URL + 'property/status/' + property.PropertyId + '/' + PROPERTYSTATUS.Archived + '/' + property.PropertyStatusId + '/' + CONFIG.REGION_ID)
                                .then(res => {
                                    this.getProperties(searchCriteria);
                                    this.props.dispatch(setSnackbar(true, "success", "Property archived successfully"));
                                })
                                .catch(err => {
                                    this.setState({ isLoading: false });
                                    this.props.dispatch(setSnackbar(true, "error", "Something went wrong please reach out to technical team for assistance."));
                                });

                        }
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    addFeaturedListing(id) {
        const { searchCriteria } = this.state;

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.put(CONFIG.API_URL + 'property/' + id + '/' + CONFIG.REGION_ID)
            .then(res => {
                this.getProperties(searchCriteria);
                this.props.dispatch(setSnackbar(true, "success", "Property updated as featured listings"));
            })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    removeFeaturedListing(id) {
        const { searchCriteria } = this.state;
        confirmAlert({
            message: 'Are you sure to remove this property from featured listings?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        this.setState({ isLoading: true });

                        axios.delete(CONFIG.API_URL + 'property/' + id + '/' + CONFIG.REGION_ID)
                            .then(res => {
                                this.getProperties(searchCriteria);
                                this.props.dispatch(setSnackbar(true, "success", "Property removed from featured listings"));
                            })
                            .catch(err => {
                                this.setState({ isLoading: false });
                                if (err.response !== null && err.response !== undefined) {
                                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                                }
                                else {
                                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                                }
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    approveProperty(property) {
        const { searchCriteria } = this.state;

        confirmAlert({
            message: 'Are you sure to approve this property?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (property.BrokerTypeId === BROKERTYPES.Broker) {
                            this.editProperty(property);
                        } else {
                            //Unauthorized user neeed to configure endpoint for payment then approve.
                            let token = localStorage.getItem('accessKey');
                            if (token) {
                                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                            }
                            let url = 'property/approve/' + property.PropertyId + "/" + CONFIG.REGION_ID;
                            if (CONFIG.REGION_ID === REGIONS.US && property.IsStripe === true) {
                                url = 'property/stripeapprove/' + property.PropertyId + "/" + CONFIG.REGION_ID;
                            }

                            axios.put(CONFIG.API_URL + url).then(response => {
                                if (response.status === 200) {
                                    this.getProperties(searchCriteria);
                                    this.props.dispatch(setSnackbar(true, "success", "Property Approved Successfully"));
                                }
                            })
                                .catch(err => {
                                    this.props.dispatch(setSnackbar(true, "error", "Something went wrong please reach out to technical team for assistance."));
                                });
                        }
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    changeStatus(e, id) {
        const { isChangeStatus } = this.state;
        this.setState({ isChangeStatus: !isChangeStatus, propertyIdStatusChange: id });
    }

    render() {
        // document.title = CONFIG.PAGE_TITLE + 'Manage Property Listings';

        const { searchCriteria, searchResult, pagination, propertyStatus, stateList, brokerList,
            isChangeStatus, propertyIdStatusChange, pageNum, selectedBroker, isArchiveHide, IsShowArchived } = this.state;

        return (
            <div className="property-list-table">
                <Helmet
                    title='Manage Property Listings'
                    addPostfixTitle={true}
                    keywords="Manage Property Listings, Property Listings, Listing"
                />
                <div className="header-name-box form-back-shadow addpadding-property margin-t-15">
                    <Row>
                        <Col sm={8}>
                            <h6>Property Listings</h6>
                            <Row className="header-count">
                            </Row>
                        </Col>
                        <Col sm={4}>
                            <div className="add-new-btn">
                                <Link to="/admin/addproperty" ><FontAwesomeIcon icon={faPlusSquare} /> Add new property</Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="form-back-shadow ">
                    <div className="filters-search">
                        <AvForm onSubmit={this.searchSubmit.bind(this)} ref={c => (this.myFormSearch = c)}>
                            <Row>
                                <Col sm={3}>
                                    <AvField name="PropertyName" label="Property Name:" type="text"
                                        value={searchCriteria.PropertyName} onChange={(e) => this.onSearchTextChange(e)} />
                                </Col>
                                <Col sm={3}>
                                    <AvField name="PropertyCode" label="Property Code:" type="text"
                                        value={searchCriteria.PropertyCode} onChange={(e) => this.onSearchTextChange(e)} />
                                </Col>

                                <Col sm={3}>
                                    <AvField name="City" label={CONFIG.REGION_ID !== REGIONS.UK ? "City:" : "Town/City:"} type="text"
                                        value={searchCriteria.City} onChange={(e) => this.onSearchTextChange(e)} />
                                </Col>
                                {/* <Col sm={3}>
                                    <Label>Country:</Label>
                                    <AvField type="select" name="CountryID" value={searchCriteria.CountryID}
                                        onChange={(e) => this.onCountryChange(e)} disabled
                                    >
                                        <option value="0">--Select--</option>
                                        {countryList ? countryList.map(n => {
                                            return (
                                                <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                    disabled={n.Id === '' ? true : false}
                                                    value={n.Id}>
                                                    {n.Code}
                                                </option>
                                            );
                                        }) : ''}
                                    </AvField>
                                </Col> */}
                                {CONFIG.REGION_ID !== REGIONS.UK ?
                                    <Col sm={3}>
                                        <Label>State:</Label>
                                        <AvField type="select" name="StateID" value={searchCriteria.StateID}
                                            onChange={(e) => this.onStateChange(e)}
                                        >
                                            <option value="0">--Select--</option>
                                            {stateList ? stateList.map(n => {
                                                return (
                                                    <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                        disabled={n.Id === '' ? true : false}
                                                        value={n.Id}>
                                                        {n.Id}
                                                    </option>
                                                );
                                            }) : ''}
                                        </AvField>
                                    </Col>
                                    : ''}
                                <Col sm={3}>
                                    <AvField type="select" name="PropertyStatusId" value={searchCriteria.PropertyStatusId}
                                        onChange={(e) => this.onSearchStatusChange(e)}
                                        label="Status:">
                                        <option value="0">--Select--</option>
                                        {propertyStatus ? propertyStatus.map(n => {
                                            return (
                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                    disabled={n.ID === '' ? true : false}
                                                    value={n.ID}>
                                                    {n.Name}
                                                </option>
                                            );
                                        }) : ''}
                                    </AvField>
                                </Col>
                                <Col sm={3}>
                                    <div>
                                        <Label>Broker Name:</Label>
                                        <div className="width-100 statescroll">
                                            <Autocomplete
                                                options={brokerList}
                                                id="tags-standard"
                                                value={selectedBroker}
                                                getOptionLabel={(option) => option ? option.Name : ''}
                                                filterOptions={this.filterOptions}
                                                onChange={this.onBrokerSelect}
                                                renderInput={(params) => (
                                                    <div className="text-state bremail">
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </Col>

                                {isArchiveHide ?
                                    '' :
                                    <Col sm={3}>
                                        <div className='showarch'>
                                            <input type="checkbox" className="checkbox-align" name="IsShowArchived" checked={IsShowArchived}
                                                onChange={this.handleArchived.bind(this)} />
                                            <label className='showtext'>Include Archived Properties</label>
                                        </div>
                                    </Col>
                                }
                                {CONFIG.REGION_ID === REGIONS.US ?
                                    <Col sm={isArchiveHide ? 6 : 3} className="text-align-right">
                                        <Button className="search-btn margin-t-20"><FontAwesomeIcon icon={faSearch} />Search</Button>
                                        <Button onClick={this.resetSearch.bind(this)} className="reset-btn margin-t-20">Reset</Button>
                                    </Col>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <Col sm={isArchiveHide ? 9: 6} className="text-align-right mt-3">
                                            <Button className="search-btn "><FontAwesomeIcon icon={faSearch} />Search</Button>
                                            <Button onClick={this.resetSearch.bind(this)} className="reset-btn">Reset</Button>
                                        </Col>
                                        : ""}
                            </Row>
                            <Row>

                            </Row>
                        </AvForm>
                    </div>
                    <Row>
                        <Col md={12} className="total-numb">
                            {searchResult && searchResult.length > 0 ? <p className = {CONFIG.REGION_ID === REGIONS.US ? '' : 'mt-3'}>Total Property Listings: {pagination.TotalRecords}</p> : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="table-model property-table">
                                {searchResult ? searchResult.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="date-width">Created Date</TableCell>
                                                <TableCell className="property-width">Property Name</TableCell>
                                                <TableCell>Property Code</TableCell>
                                                {CONFIG.REGION_ID === REGIONS.US ?
                                                    <TableCell className="property-width-city">City</TableCell>
                                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                                        <TableCell className="property-width-city">Town/City</TableCell>
                                                        : ""}

                                                {CONFIG.REGION_ID !== REGIONS.UK ? <TableCell className="width65">State</TableCell> : ''}
                                                <TableCell >Status</TableCell>
                                                <TableCell className="brokername-break">Broker Name</TableCell>
                                                <TableCell>Broker Type</TableCell>
                                                <TableCell>Approved By</TableCell>
                                                <TableCell className="date-width">Approved Date</TableCell>
                                                <TableCell className="width75">Featured Listings</TableCell>
                                                <TableCell className="width-135">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {searchResult.map((record) =>
                                                <TableRow key={record.PropertyId} >
                                                    <TableCell>{moment(record.CreatedDate).format(getDateFormat())}</TableCell>
                                                    <TableCell><span title={record.PropertyName}>{record.PropertyName.length > 30 ? <span>{record.PropertyName.substring(0, 30) + "..."}</span> : <span> {record.PropertyName}</span>}</span></TableCell>
                                                    <TableCell>{record.PropertyCode}</TableCell>
                                                    <TableCell className="property-width-city">{record.City}</TableCell>
                                                    {CONFIG.REGION_ID !== REGIONS.UK ? <TableCell>{record.StateId}</TableCell> : ''}
                                                    <TableCell> {isChangeStatus && propertyIdStatusChange === record.PropertyId ? <React.Fragment>
                                                        <div className="select-status-property">
                                                            <select type="select" name="PropertyStatusId" value={record.PropertyStatusId}
                                                                onChange={(e) => this.onPropertyStatusChange(e, record)}
                                                                label="Status:">
                                                                {propertyStatus ? propertyStatus.map(n => {
                                                                    if (n.ID !== PROPERTYSTATUS.PendingApproval && n.ID !== PROPERTYSTATUS.Draft && n.ID !== PROPERTYSTATUS.Archived) {
                                                                        return (
                                                                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                                disabled={n.ID === '' ? true : false}
                                                                                value={n.ID}>
                                                                                {n.Name}
                                                                            </option>
                                                                        );
                                                                    }
                                                                    else {
                                                                        return null;
                                                                    }
                                                                }) : ''}
                                                            </select>
                                                        </div>
                                                    </React.Fragment> : record.Status}</TableCell>
                                                    <TableCell className="brokername-break">{record.BrokerName}</TableCell>
                                                    <TableCell>{record.BrokerType}</TableCell>
                                                    <TableCell>{record.ApprovedUserName}</TableCell>
                                                    <TableCell>{record.ApprovedDate ? moment(record.ApprovedDate).format(getDateFormat()) : ''}</TableCell>
                                                    <TableCell className="text-align-center">
                                                        {record.PropertyStatusId === PROPERTYSTATUS.Approved ?
                                                            record.IsFeaturedListing ?
                                                                <input type="checkbox" className="checkbox-align" name="IsFeaturedListings" checked={true}
                                                                    onChange={this.removeFeaturedListing.bind(this, record.PropertyId)} /> :
                                                                <input type="checkbox" className="checkbox-align" name="IsFeaturedListings"
                                                                    onChange={this.addFeaturedListing.bind(this, record.PropertyId)} />
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell className="tablevertical">
                                                        {record.PropertyStatusId === PROPERTYSTATUS.Archived ?
                                                            <img src={unarchive} type="Archive" alt="Archive" className="cursor-pointer icon-table" title="Archive" onClick={() => this.archiveProperty(record)} />
                                                            :
                                                            <React.Fragment>
                                                                <img src={edit} type="edit" alt="edit" className="cursor-pointer icon-table" title="Edit" onClick={() => this.editProperty(record)} />
                                                                {record.PropertyStatusId === PROPERTYSTATUS.PendingApproval ?
                                                                    <img src={approve} onClick={() => this.approveProperty(record)} type="Approve" alt="Approve" className="cursor-pointer icon-table ml-2" title="Approve" />
                                                                    : record.PropertyStatusId !== PROPERTYSTATUS.Draft && record.PropertyStatusId !== PROPERTYSTATUS.Sold ?
                                                                        <img src={changestatus} onClick={(e) => this.changeStatus(e, record.PropertyId)} type="change status" alt="change status" className="ml-2 cursor-pointer icon-table" title="Change Status" />
                                                                        : ''
                                                                }
                                                                <img src={archive} type="Archive" alt="Archive" className="cursor-pointer icon-table pl" title="Archive" onClick={() => this.archiveProperty(record)} />
                                                            </React.Fragment>
                                                        }

                                                        {/* <CopyToClipboard text={process.env.REACT_APP_MVP_URL + "market-access/" + record.PropertyId + "/"
                                                            + (record.Address1 ? prepareStringForUrl(record.Address1) : "empty") + "/" + (record.City ? prepareStringForUrl(record.City) : "empty") + "/"
                                                            + (record.StateId ? record.StateId : "empty") + "/" + (record.ZipCode ? prepareStringForUrl(record.ZipCode) : "empty") + "/lss"}>
                                                            <img src={copy} alt="change status" className="cursor-pointer icon-table ml-2" title="Copy StorTrack market report link"/>                                                            
                                                        </CopyToClipboard> */}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan="12"><Pagination className="seaarch-pagination" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                    : <h6 className="no-records-found">No records found</h6> : ''}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
        );
    }
}

export default connect()(SearchProperty);