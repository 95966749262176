import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import Switch from 'react-switch';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faSearch, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import edit from '../../../../Assets/Img/edit.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from "react-router-dom";
import { CONFIG } from '../../../../Utils/config';
import { STATUS, getDateFormat, REGIONS, VENDORTYPE } from '../../../../Utils/utils';
import axios from 'axios';
import './VendorSearchList.css';
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux';
import { setSnackbar } from '../../../../redux/ducks/snackbarReducer';
import Helmet from '../../../../Utils/Helmet';

class VendorSearchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorTypes: [],
            vendorStatus: [],
            stateList: [],
            countryList: [],
            pagination: {},
            newsTypeIds: [],
            searchCriteria: {
            },
            PaidCount: 0,
            FreeCount: 0,
            pageNum: 0
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getLookupVendorTypes();
        this.getLookupStatus();
        this.InitialSearchValue();
    }

    InitialSearchValue() {
        var searchCriteria = {
            RegionId: CONFIG.REGION_ID,
            CompanyName: '',
            StatusId: 0,
            CountryID: CONFIG.REGION_ID === REGIONS.US ? CONFIG.US_CountryId : '',
            StateID: "",
            VendorTypeID: 0,

            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: '',
            SortOrder: ''
        }
        this.setState({ searchCriteria }, () => {
            this.getVendorList(searchCriteria);
            if (CONFIG.REGION_ID !== REGIONS.UK) {
                this.getLookupState();
            }
        });
    }

    getLookupVendorTypes() {
        axios.get(CONFIG.API_URL + 'lookup/vendor/types').then(response => {
            if (response.status === 200) {
                this.setState({ vendorTypes: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getLookupStatus() {
        axios.get(CONFIG.API_URL + 'lookup/status').then(response => {
            if (response.status === 200) {
                this.setState({ vendorStatus: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }


    getLookupCountry() {
        axios.get(CONFIG.API_URL + 'lookup/country/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                this.setState({ countryList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getLookupState() {
        const { searchCriteria } = this.state;

        axios.get(CONFIG.API_URL + 'lookup/state/' + searchCriteria.CountryID).then(response => {
            if (response.status === 200) {
                this.setState({ stateList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getVendorList(data) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'Vendor/list', data).then(response => {
            if (response.status === 200) {
                this.setState({
                    searchResult: response.data.Vendors, pagination: response.data.Pagination, FreeCount: response.data.FreeCount, PaidCount: response.data.PaidCount,
                    pageNum: Math.ceil(response.data.Pagination.TotalRecords / 10)
                });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    resetSearch() {
        this.InitialSearchValue();
    }

    onSearchCountryChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.CountryID = e.target.value;

        this.setState({ searchCriteria });
    }
    onSearchStateChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.StateID = e.target.value;
        this.setState({ searchCriteria })
    }

    onSearchStatusChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.StatusId = e.target.value;
        this.setState({ searchCriteria })
    }

    onSearchCompanyNameChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.CompanyName = e.target.value;
        this.setState({ searchCriteria })
    }

    onSearchVendorTypeChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.VendorTypeID = e.target.value;
        this.setState({ searchCriteria })
    }

    searchSubmit() {
        const { searchCriteria } = this.state;
        this.getVendorList(searchCriteria);
    }

    editVendor(listing) {
        const { from } = { from: { pathname: "/admin/vendor-profile/" + listing.VendorID } };
        this.props.history.push(from);
    }

    restoreVendor(id) {
        const { searchCriteria } = this.state;
        confirmAlert({
            message: 'Are you sure to make this vendor active?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        axios.put(CONFIG.API_URL + 'Vendor/' + id + '/' + CONFIG.REGION_ID)
                            .then(res => {
                                this.props.dispatch(setSnackbar(true, "success", "The vendor activated successfully"));
                                this.getVendorList(searchCriteria);
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });

                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    deleteVendor(id) {
        const { searchCriteria } = this.state;

        confirmAlert({
            message: 'Are you sure to make this vendor inactive?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        this.setState({ isLoading: true });

                        axios.delete(CONFIG.API_URL + 'Vendor/' + id + '/' + CONFIG.REGION_ID)
                            .then(res => {
                                this.props.dispatch(setSnackbar(true, "success", "The vendor inactivated successfully"));
                                this.getVendorList(searchCriteria);
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });

                            });

                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    approveVendor(record) {
        const { searchCriteria } = this.state;

        axios.put(CONFIG.API_URL + 'Vendor/approve/' + record.VendorID + '/' + CONFIG.REGION_ID)
            .then(res => {
                this.getVendorList(searchCriteria);
            })
            .catch((err) => {
                this.setState({ isLoading: false });

            });
    }

    addFeaturedListing(record) {
        if (record.VendorTypeID === VENDORTYPE.Premium) {
            const { searchCriteria } = this.state;

            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.put(CONFIG.API_URL + 'vendor/add/feature/' + record.VendorID + '/' + CONFIG.REGION_ID)
                .then(res => {
                    this.getVendorList(searchCriteria);
                    this.props.dispatch(setSnackbar(true, "success", "Vendor updated as featured listings"));
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response != null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
        else {
            this.props.dispatch(setSnackbar(true, "error", "Please upgrade this listing to make this a featured listing"));
        }
    }

    removeFeaturedListing(id) {
        const { searchCriteria } = this.state;
        confirmAlert({
            message: 'Are you sure to remove this vendor from featured listings?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        this.setState({ isLoading: true });

                        axios.put(CONFIG.API_URL + 'vendor/remove/feature/' + id + '/' + CONFIG.REGION_ID)
                            .then(res => {
                                this.getVendorList(searchCriteria);
                                this.props.dispatch(setSnackbar(true, "success", "Vendor removed from featured listings"));
                            })
                            .catch(err => {
                                this.setState({ isLoading: false });
                                if (err.response !== null && err.response !== undefined) {
                                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                                }
                                else {
                                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                                }
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    handleChangePage = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            this.getVendorList(searchCriteria);
        });
    };

    render() {
        // document.title = CONFIG.PAGE_TITLE + 'Manage Vendor Profile';

        const { searchCriteria, searchResult, pagination, vendorStatus, vendorTypes,
            stateList, FreeCount, PaidCount, pageNum } = this.state;

        return (
            <div>
                <Helmet
                    title='Manage Vendor Profile'
                    addPostfixTitle={true}
                    keywords="Manage Vendor Profile, Vendors, Company"
                />
                <div className="header-name-box form-back-shadow margin-t-15">
                    <Row>
                        <Col sm={8}>
                            <h6>Vendor Directory</h6>
                            <Row className="header-count">
                                <div className="counter-box">
                                    <h5>Paid Listings</h5>
                                    <h4>{PaidCount}</h4>
                                </div>
                                <div className="counter-box last-box-border">
                                    <h5>Free Listings</h5>
                                    <h4>{FreeCount}</h4>
                                </div>
                            </Row>
                        </Col>
                        <Col sm={4}>
                            <div className="add-new-btn">
                                <Link to="/admin/vendor-profile" ><FontAwesomeIcon icon={faPlusSquare} /> Add new company</Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="form-back-shadow ">
                    <div className="filters-search">
                        <AvForm onSubmit={this.searchSubmit.bind(this)} >
                            <Row>
                                <Col md={4}>
                                    <AvField name="CompanyName" label="Company Name:" type="text"
                                        value={searchCriteria.CompanyName} onChange={(e) => this.onSearchCompanyNameChange(e)} />
                                </Col>
                                {CONFIG.REGION_ID !== REGIONS.UK ?
                                    <Col md={2} >
                                        <AvField type="select" name="StateID" value={searchCriteria.StateID}
                                            onChange={(e) => this.onSearchStateChange(e)}
                                            label="State:">
                                            <option value="">--Select--</option>
                                            {stateList ? stateList.map(n => {
                                                return (
                                                    <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                        disabled={n.Id === '' ? true : false}
                                                        value={n.Id}>
                                                        {n.Id}
                                                    </option>
                                                );
                                            }) : ''}
                                        </AvField>
                                    </Col>
                                    : ''}
                                {CONFIG.REGION_ID === REGIONS.US ?
                                    <Col md={2}>
                                        <AvField type="select" name="StatusId" value={searchCriteria.StatusId}
                                            onChange={(e) => this.onSearchStatusChange(e)}
                                            label="Status:">
                                            <option value="0">--Select--</option>
                                            {vendorStatus ? vendorStatus.map(n => {
                                                if (n.ID === STATUS.Active || n.ID === STATUS.InActive || n.ID === STATUS.DeleteApprove) {
                                                    return (
                                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                            disabled={n.ID === '' ? true : false}
                                                            value={n.ID}>
                                                            {n.Name}
                                                        </option>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            }) : ''}
                                        </AvField>
                                    </Col>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <Col md={3}>
                                            <AvField type="select" name="StatusId" value={searchCriteria.StatusId}
                                                onChange={(e) => this.onSearchStatusChange(e)}
                                                label="Status:">
                                                <option value="0">--Select--</option>
                                                {vendorStatus ? vendorStatus.map(n => {
                                                    if (n.ID === STATUS.Active || n.ID === STATUS.InActive || n.ID === STATUS.DeleteApprove) {
                                                        return (
                                                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                disabled={n.ID === '' ? true : false}
                                                                value={n.ID}>
                                                                {n.Name}
                                                            </option>
                                                        );
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                }) : ''}
                                            </AvField>
                                        </Col>
                                        : ""}
                                {CONFIG.REGION_ID === REGIONS.US ?
                                    <Col md={2}>
                                        <AvField type="select" name="StatusId" value={searchCriteria.VendorTypeID}
                                            onChange={(e) => this.onSearchVendorTypeChange(e)}
                                            label="Listing Type:">
                                            <option value="0">--Select--</option>
                                            {vendorTypes ? vendorTypes.map(n => {
                                                return (
                                                    <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                        disabled={n.ID === '' ? true : false}
                                                        value={n.ID}>
                                                        {n.Name}
                                                    </option>
                                                );
                                            }) : ''}
                                        </AvField>
                                    </Col>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <Col md={3}>
                                            <AvField type="select" name="StatusId" value={searchCriteria.VendorTypeID}
                                                onChange={(e) => this.onSearchVendorTypeChange(e)}
                                                label="Listing Type:">
                                                <option value="0">--Select--</option>
                                                {vendorTypes ? vendorTypes.map(n => {
                                                    return (
                                                        <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                            disabled={n.ID === '' ? true : false}
                                                            value={n.ID}>
                                                            {n.Name}
                                                        </option>
                                                    );
                                                }) : ''}
                                            </AvField>
                                        </Col>
                                        : ""}



                                <Col md={2} className="text-align-right margin-t-20 padding-left-remove">
                                    <Button className="search-btn"><FontAwesomeIcon icon={faSearch} /> Search</Button>
                                    <Button onClick={this.resetSearch.bind(this)} className="reset-btn margin-r-0">Reset</Button>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                    <Row>
                        <Col md={12} className="total-numb">
                            {searchResult && searchResult.length > 0 ? <p>Total Vendor Profiles: {pagination.TotalRecords}</p> : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="table-model vendor-directory-table">
                                {searchResult ? searchResult.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company Name</TableCell>
                                                {CONFIG.REGION_ID === REGIONS.US ?
                                                    <TableCell>City</TableCell>
                                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                                        <TableCell>Town/City</TableCell>
                                                        : ""}
                                                {CONFIG.REGION_ID !== REGIONS.UK ? <TableCell>State</TableCell> : ''}
                                                <TableCell>Status</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Created Date</TableCell>
                                                <TableCell>Modified Date</TableCell>
                                                {/* <TableCell>Approved By</TableCell> */}
                                                {/* <TableCell>Approval Date</TableCell> */}
                                                <TableCell className="width75">Featured Listings</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {searchResult.map((record) =>
                                                <TableRow key={record.VendorId} >
                                                    <TableCell>{record.CompanyName}</TableCell>
                                                    <TableCell>{record.City}</TableCell>
                                                    {CONFIG.REGION_ID !== REGIONS.UK ? <TableCell>{record.StateID}</TableCell> : ''}
                                                    {/* <TableCell>{record.ISActive ? "Active" : "Inactive"}</TableCell> */}
                                                    <TableCell>{record.Status}</TableCell>
                                                    <TableCell>{record.VendorType}</TableCell>
                                                    <TableCell>{moment(record.CreatedDate).format(getDateFormat())}</TableCell>
                                                    <TableCell>{record.ModifiedDate ? moment(record.ModifiedDate).format(getDateFormat()) : ''}</TableCell>
                                                    <TableCell className="text-align-center">
                                                        {record.IsFeaturedListing ?
                                                            <input type="checkbox" className="checkbox-align" name="IsFeaturedListings" checked={true}
                                                                onChange={this.removeFeaturedListing.bind(this, record.VendorID)} /> :
                                                            <input type="checkbox" className="checkbox-align" name="IsFeaturedListings" checked={false}
                                                                onChange={this.addFeaturedListing.bind(this, record)} />
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link type="edit" onClick={() => this.editVendor(record)}><img src={edit} alt="Edit" className="cursor-pointer" /></Link>
                                                        {/* {record.ISActive ?
                                                            <Link type="delete" className="button-common background-publish" onClick={() => this.deleteVendor(record.VendorID)}><img src={deleted} title="Delete" className="cursor-pointer" /></Link>
                                                            :
                                                            <Link type="restore" className="button-common background-publish" onClick={() => this.restoreVendor(record.VendorID)}><img src={restore} title="Restore" className="cursor-pointer" /></Link>
                                                        } */}
                                                        {record.StatusId === STATUS.Active ?
                                                            <Switch className="active-in slide-button" onChange={event => this.deleteVendor(record.VendorID)} checked={true} />
                                                            : ''}
                                                        {record.StatusId === STATUS.InActive ?
                                                            <Switch className="active-in slide-button" onChange={event => this.restoreVendor(record.VendorID)} checked={false} />
                                                            : ''}
                                                        {record.StatusId === STATUS.DeleteApprove ?
                                                            <Link type="restore" className="approve-btn" onClick={() => this.deleteVendor(record.VendorID)}><FontAwesomeIcon icon={faCheckCircle} />Approve</Link>
                                                            : ''}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan="9">
                                                    <Pagination className="seaarch-pagination" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                    : <h6 className="no-records-found">No records found</h6> : ''}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
export default connect()(VendorSearchList);
