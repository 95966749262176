import React, { Component } from 'react';
import { Button, Row, Container, Col, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faPlusSquare, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { CONFIG } from './../../../Utils/config';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Switch from 'react-switch';
import { connect } from 'react-redux';
import add_icon from '../../../Assets/Img/add.png';
import edit from '../../../Assets/Img/edit.png';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import './Categories.css';
import Helmet from '../../../Utils/Helmet';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCategoryPopup: false,
      isChildEnable: null,
      description: '',
      curretnDisplayOrder: '',
      metaKeyword: '',
      treeData: null,
      catLevel: 1,
      data: null,
      selectedNode: null,
      treeAction: '',
      categoryData: [],
      primaryCategoryID: null,
      isButtonDisabled: false
    };
    this.addParentCategory = this.addParentCategory.bind(this);
    this.getChild = this.getChild.bind(this);
    this.descOnChange = this.descOnChange.bind(this);
    this.metaOnChange = this.metaOnChange.bind(this);
  }

  addParentCategory() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/category/displayorder/' + 0 + "/" + CONFIG.REGION_ID)
      .then(res => {
        let categoryData = {
          DisplayOrder: res.data
        }

        this.setState({
          categoryData: categoryData, isCategoryPopup: true, catLevel: 1, description: '',
          metaKeyword: ''
        })

      })
      .catch((err) => {
        this.props.dispatch(hideLoader());
        if (err.response !== null && err.response !== undefined) {
          this.props.dispatch(setSnackbar(true, "error", err.response.data));
        }
        else {
          this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
        }
      })

    // this.setState({ categoryData: [] ,isCategoryPopup: true, catLevel: 1, description: '',
    // metaKeyword: '' });
  }
  toggleClose() {
    this.setState({ isCategoryPopup: false });
  }

  descOnChange(e) {
    var value = e.target.value;
    this.setState({ description: value });
  }
  metaOnChange(e) {
    var value = e.target.value;
    this.setState({ metaKeyword: value });
  }

  getTreeData(colId) {
    //this.props.dispatch(showLoader());
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/category/tree/' + CONFIG.REGION_ID)
      .then(res => {
        this.setState({ data: res.data })
        if (colId !== null) {
          this.getChild(parseInt(colId, 10));
        }
      })
      .catch((err) => {
        if (err.response !== null && err.response !== undefined) {
          this.props.dispatch(setSnackbar(true, "error", err.response.data));
        }
        else {
          this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
        }
      })
  }

  createCategory(event, errors, values) {
    this.props.dispatch(showLoader());
    if (errors.length > 0) {
      this.props.dispatch(hideLoader());
    }
    else {
      let isDisplsyOrderUpdated = false;
      if (this.state.curretnDisplayOrder !== values.Order) {
        isDisplsyOrderUpdated = true;
      }
      const data = {
        "RegionId": CONFIG.REGION_ID,
        "CategoryID": values.ID === "" ? 0 : parseInt(values.ID, 10),
        "CategoryName": values.CategoryName,
        "Level": parseInt(this.state.catLevel, 10),
        "Description": this.state.description,
        "DisplayOrder": parseInt(values.Order, 10),
        "MetaKeyword": this.state.metaKeyword,
        "IsDisplsyOrderUpdated": isDisplsyOrderUpdated,
        "PrimaryCategoryID": this.state.primaryCategoryID !== null ? parseInt(this.state.primaryCategoryID, 10) : null
      }
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }

      axios.post(CONFIG.API_URL + 'admin/category/', data)
        .then(res => {

          this.myFormRef && this.myFormRef.reset();
          if (values.ID === "") {
            //  alert("Category Added.");

            this.props.dispatch(setSnackbar(true, "success", "Category added successfully"));
            if (this.state.primaryCategoryID !== null) {
              this.getTreeData(parseInt(localStorage.getItem('col'), 10));
            }
            else {
              this.getTreeData(null);
            }

          }
          else {

            this.props.dispatch(setSnackbar(true, "success", "Category updated successfully"));

            if (this.state.primaryCategoryID !== null) {

              //  this.getChild();
              this.getTreeData(parseInt(localStorage.getItem('col'), 10));
            }
            else {
              this.getTreeData(null);
            }

          }
          this.props.dispatch(hideLoader());
          this.setState({
            isCategoryPopup: false
          });
        })
        .catch((err) => {

          this.props.dispatch(hideLoader());
          if (err.response !== null && err.response !== undefined) {
            this.props.dispatch(setSnackbar(true, "error", err.response.data));
          }
          else {
            this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
          }
        })
    }
  }

  deleteCategory(categoryId, vendorcount) {
    var message = "";
    if (vendorcount > 0) {
      message = 'Vendor companies assigned to this category will be removed from Vendor Directory if you deactivate. Are you sure to deactivate this category?';
    } else {
      message = 'Are you sure to make this category inactive?';
    }
    confirmAlert({
      message: message,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.dispatch(showLoader());
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.delete(CONFIG.API_URL + 'admin/category/' + categoryId)
              .then(res => {
                this.props.dispatch(setSnackbar(true, "success", "The category inactivated successfully"));
                this.setState({ isButtonDisabled: false });
                if (this.state.primaryCategoryID !== null) {

                  this.getChild(parseInt(localStorage.getItem('col'), 10));
                }
                this.getTreeData();

              }).catch((err) => {
                this.props.dispatch(hideLoader());

              });

          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  restoreCategory(id, isleaf, vendorcount) {
    if (isleaf && vendorcount > 0) {
      confirmAlert({
        message: 'Vendor companies assigned to the Parent category will be removed automatically if you activate this Child category. Are you sure to activate this Child category?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.doRestoreCategory(id);
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }
    else {
      confirmAlert({
        message: 'Are you sure to make this category active?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.doRestoreCategory(id);
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }
  }

  doRestoreCategory(id) {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    this.props.dispatch(showLoader());
    axios.request({
      url: CONFIG.API_URL + 'admin/category/' + id,
      method: 'put',
    })
      .then(res => {
        this.props.dispatch(setSnackbar(true, "success", "The category activated successfully"));
        if (this.state.primaryCategoryID !== null) {

          this.getChild(parseInt(localStorage.getItem('col'), 10));
        }
        this.getTreeData();
      })
      .catch(err => {

        this.props.dispatch(hideLoader());
      });
  }

  componentDidMount() {
    this.getTreeData();
  }

  getChild(id) {
    let isChildEnable = this.state.isChildEnable !== id ? id : null;
    localStorage.setItem('col', id);
    this.setState({ isChildEnable });
  }

  addChild(parentId, isleaf, vendorcount) {
    if (isleaf && vendorcount > 0) {
      confirmAlert({
        message: 'Vendor companies assigned to this Parent category will be removed automatically if you add a Child category. Are you sure to add a Child category?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.doAddChild(parentId);
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }
    else {
      this.doAddChild(parentId);
    }
  }

  doAddChild(parentId) {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/category/displayorder/' + parseInt(parentId, 10) + "/" + CONFIG.REGION_ID)
      .then(res => {

        let categoryData = {
          DisplayOrder: res.data
        }
        this.setState({ categoryData: categoryData, isCategoryPopup: true, description: '', metaKeyword: '', catLevel: 2, primaryCategoryID: parentId })

      })
      .catch((err) => {

        this.props.dispatch(hideLoader());
        if (err.response !== null && err.response !== undefined) {
          this.props.dispatch(setSnackbar(true, "error", err.response.data));
        }
        else {
          this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
        }
      })
  }

  getParentOrder() {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/category/displayorder/' + 0 + "/" + CONFIG.REGION_ID)
      .then(res => {
        let categoryData = {
          DisplayOrder: res.data
        }
        this.setState({ categoryData: categoryData, isCategoryPopup: true, description: '', metaKeyword: '', catLevel: 1 })
      })
      .catch((err) => {
        if (err.response !== null && err.response !== undefined) {
          this.props.dispatch(setSnackbar(true, "error", err.response.data));
        }
        else {
          this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
        }
      })
  }

  viewCategory(categoryId) {
    this.props.dispatch(showLoader());
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/category/' + categoryId + "/" + CONFIG.REGION_ID)
      .then(res => {

        let primaryCategoryID = res.data.PrimaryCategoryID === null ? null : res.data.PrimaryCategoryID;
        let categoryData = res.data;
        let description = res.data.Description;
        let metaKeyword = res.data.MetaKeyword;
        let catLevel = res.data.Level;
        let curretnDisplayOrder = res.data.DisplayOrder;
        //this.props.dispatch(hideLoader());
        this.setState({ categoryData: categoryData, description: description, metaKeyword: metaKeyword, isCategoryPopup: true, catLevel: catLevel, primaryCategoryID: primaryCategoryID, curretnDisplayOrder: curretnDisplayOrder });
      })
      .catch((err) => {

        //this.props.dispatch(hideLoader());
        if (err.response !== null && err.response !== undefined) {
          this.props.dispatch(setSnackbar(true, "error", err.response.data));
        }
        else {
          this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
        }
      })
  }

  render() {
    // document.title = CONFIG.PAGE_TITLE + 'Categories';
    const { isCategoryPopup, description, metaKeyword, isChildEnable, categoryData } = this.state;

    return (
      <div>
        <Helmet
          title='Categories'
          addPostfixTitle={true}
          keywords="Categories, Category Management"
        />
        <div className="header-category-box form-back-shadow margin-t-15">
          <Row className="marign-t-8">
            <Col sm={8}>
              <h6>Category Management</h6>
            </Col>
            <Col sm={4}>
              <div className="add-new-btn">
                <Button onClick={this.addParentCategory}><FontAwesomeIcon icon={faPlusSquare} /> Add category</Button>
              </div>
            </Col>
            <Col sm={12} className="margin-t-15">
              <div className="table-model parent-category">
                {this.state.data && this.state.data.length > 0 ? <Table>

                  <TableHead>
                    <TableRow>
                      <TableCell className="date-width">Category Name</TableCell>
                      <TableCell className="date-width">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data.map((parent) =>
                      <React.Fragment>
                        <TableRow id={parent.id} key={parent.id}>
                          <TableCell className="first-category"><p id={parent.id} onClick={() => this.getChild(parent.id)}><FontAwesomeIcon icon={isChildEnable === parent.id ? faCaretDown : faCaretRight} /> {parent.name}
                            &nbsp;&nbsp;&nbsp;
                          </p>
                          </TableCell>
                          <TableCell className="first-category-buttons">
                            <span id={parent.id} onClick={() => this.addChild(parent.id, parent.isleaf, parent.vendorcount)}><img src={add_icon} alt="Add" className="cursor-pointer" /></span>
                            <span id={parent.id} onClick={() => this.viewCategory(parent.id)}><img src={edit} alt="Edit" className="cursor-pointer" /></span>
                            {parent.isactive === true ?
                              <Switch className="active-in slide-button" id={parent.id} onChange={event => this.deleteCategory(parent.id, parent.vendorcount)} checked={true} />
                              :
                              <Switch className="active-in slide-button" onChange={event => this.restoreCategory(parent.id)} checked={false} />}
                          </TableCell>
                        </TableRow>
                        {parent.children ? <TableRow className={isChildEnable === parent.id ? 'childEnable' : 'childDisable'}>
                          <TableCell colSpan="2" className="second-category"> {
                            parent.children.length > 0 ? parent.children.map((child) =>
                              <div className="display-flex">
                                {/* <p id={child.id}><span ><FontAwesomeIcon icon={faCaretRight} /> {child.name}&nbsp;&nbsp;&nbsp;</span> */}
                                <p id={child.id}><span > {child.name}&nbsp;&nbsp;&nbsp;</span>
                                </p>
                                <span>
                                  <span id={child.id} onClick={() => this.viewCategory(child.id)}><img src={edit} alt="Edit" className="cursor-pointer" /></span>
                                  {child.isactive === true ?
                                    <Switch className="active-in slide-button" id={child.id} onChange={event => this.deleteCategory(child.id, child.vendorcount)} checked={true} />
                                    :
                                    <Switch className="active-in slide-button" onChange={event => this.restoreCategory(child.id, parent.isleaf, parent.vendorcount)} checked={false} />}</span>
                              </div>
                            ) : <p className="no-bottom-margin no-top-margin">no data</p>
                          }</TableCell>
                        </TableRow>
                          : ''}
                      </React.Fragment>
                    )}
                  </TableBody>
                </Table> : ''}

              </div>
            </Col>
          </Row>
        </div>

        <div>

          <Modal size="md" isOpen={isCategoryPopup} toggle={this.toggleClose.bind(this)} className="pop-up-model">
            <ModalHeader toggle={this.toggleClose.bind(this)}>{categoryData && categoryData.CategoryID > 0 ? 'Update Category' : 'Create Category'}
            </ModalHeader>
            <ModalBody>
              <Container className="category-form">
                <AvForm onSubmit={this.createCategory.bind(this)} ref={c => (this.myFormRef = c)}>
                  <Table className="noborder-table">
                    <TableRow>
                      <TableCell><Label>Category Name:*</Label></TableCell>
                      <TableCell><AvField type="hidden" name="ID" value={categoryData ? categoryData.CategoryID : 0} />
                        <AvField type="hidden" name="Level" value={categoryData ? categoryData.Level : 0} />
                        <AvField name="CategoryName" maxLength="255" type="text" value={categoryData.CategoryName ? categoryData.CategoryName : ''} validate={{
                          required: { value: true, errorMessage: 'Category Name is required' }
                        }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><Label>Description:</Label></TableCell>
                      <TableCell> <textarea onChange={(e) => this.descOnChange(e)} value={description} label="Description:" className="property-description" /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><Label>Display Order:*</Label></TableCell>
                      <TableCell> <AvField name="Order" label="" type="number" value={categoryData.DisplayOrder ? categoryData.DisplayOrder : ''} validate={{
                        required: { value: true, errorMessage: 'Display Order is required' }
                      }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><Label>Meta Keywords:</Label></TableCell>
                      <TableCell> <textarea onChange={(e) => this.metaOnChange(e)} value={metaKeyword} label="Meta Keywords:" className="property-description" /></TableCell>
                    </TableRow>
                  </Table>

                  <Row className="save-right-button float-right">
                    <Button id="btn" className="save-btn">Save</Button>
                    <Button className="reset-btn" onClick={this.toggleClose.bind(this)}>Cancel</Button>
                  </Row>
                </AvForm>
              </Container>
            </ModalBody>
          </Modal>

        </div>


      </div>
    );
  }
}

export default connect()(Categories);