import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Row, Col, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import axios from 'axios';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from '@material-ui/lab/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getFormattedInt, getFormattedFloat, USERTYPES, getPropertyStatusClass, getPropertyStatus,
    PRICEOPTION, thumbnailPropertyData, getPropertyTypeClass, REGIONS, getDateFormat, CURRENCY, substringStripHtmlTag
} from './../../Utils/utils';
import { getToken, getUserType, getName } from './../../Utils/localStorage';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CONFIG } from '../../Utils/config';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import fheart from './../../Assets/Img/heart-filled.png';
import pheart from './../../Assets/Img/heart.png';
import downicon from './../../Assets/Img/down-close.png';
import xicon from './../../Assets/Img/close-xx.png';
import feature_home from './../../Assets/Img/ventor-list.png';
import { setSnackbar } from '../../redux/ducks/snackbarReducer';
import './SearchResult.css';
import Geocode from "react-geocode";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Helmet from '../../Utils/Helmet';
import { isDesktop, isTablet } from 'react-device-detect';
import MapGL, { FullscreenControl, Layer, Marker, NavigationControl, Popup, Source, WebMercatorViewport } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const layerStyle = {
    id: "point",
    type: "circle",
    paint: {
        "circle-radius": isDesktop || isTablet ? 5 : 2,
        "circle-color": ["get", "PointColor"],
    },
};

class SearchResult extends Component {
    static defaultProps = {
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + CONFIG.GoogleMapsApiKey + '',
        StoreDetails: [],
        center: {
            lat: CONFIG.REGION_ID === REGIONS.UK ? 51.509865 : 41.850033,
            lng: CONFIG.REGION_ID === REGIONS.UK ? -0.118092 : -87.6500523
        },
        zoom: 3
    }

    constructor(props) {
        super(props);
        this.state = {
            mapZoomCount: 0,
            searchId: 0,
            searchData: [],
            addressError: '',
            isPriceFilter: false,
            isLocationFilter: false,
            isBuildingSize: false,
            isLotSize: false,
            isNrsf: false,
            isOther: false,
            isRoomExpansion: false,
            isPortfolios: false,
            isExcludeProtfolios: false,
            pageCount: 0,
            paginationData: [],
            searchResultData: [],
            mapData: [],
            selectedStates: [],
            selectedMSA: [],
            stateList: [],
            msaList: [],
            selectedValue: null,
            msaId: '',
            showCityList: false,
            cityList: [],
            cityName: '',
            mapView: false,
            token: '',
            sortBy: 2,
            pageNum: 0,
            sortOrder: 'DESC',
            lat: '',
            lng: '',
            priceRangeTypesList: [],
            measurementsList: [],
            MVPMarketPSFAverage: '',
            MVPAveragePopulationPerStore: '',
            MVPCurrentSQFTPerCapita: '',
            priceError: false,
            buildError: false,
            lotError: false,
            nrsfError: false,
            isNoRecords: false,
            dropdownOpen: false,
            imgId: 0,
            propertyTypeList: [],
            noKeyWord: false,
            userInput: '',
            showPropertyNameList: false,
            propertyNameList: [],
            displayValue: 'Property Search',
            propertyTypeIds: '',
            open: false,
            openSearch: false,
            openMSA: false,
            isMSAError: false,
            legendData: [{ id: 1, class: "orange-label", distance: "Traditional Site" },
            { id: 2, class: "dev-label", distance: "Development" },
            { id: 3, class: "con-label", distance: "Conversion" },
            { id: 4, class: "land-label", distance: "Land Only" },
            { id: 5, class: "blue-label", distance: "RV and Boat Storage" }
            ],
            viewport: {
                width: "100%",
                height: "100%",
                longitude: CONFIG.REGION_ID === REGIONS.UK ? -5.440458 : -87.6500523,
                latitude: CONFIG.REGION_ID === REGIONS.UK ? 53.838006 : 41.850033,
                // zoom: CONFIG.REGION_ID === REGIONS.UK ? 5 : 3,
            },
            showPopupInfo: false,
            popupInfoLatlng: {},
            mapSize: { width: null, height: null },
        };
        this.mapRef = React.createRef();
        this.priceFilter = this.priceFilter.bind(this);
        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.onKeyFocus = this.onKeyFocus.bind(this);
        this.handleOrderByChange = this.handleOrderByChange.bind(this);
        this.locationFilter = this.locationFilter.bind(this);
        this.buildingSizeFilter = this.buildingSizeFilter.bind(this);
        this.lotSizeFilter = this.lotSizeFilter.bind(this);
        this.nrsfFilter = this.nrsfFilter.bind(this);
        this.otherFilter = this.otherFilter.bind(this);
        this.roomExpansionOnChange = this.roomExpansionOnChange.bind(this);
        this.portfoliosOnChange = this.portfoliosOnChange.bind(this);
        this.excludeProtfoliosonChange = this.excludeProtfoliosonChange.bind(this);
        this.onSelectMsa = this.onSelectMsa.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.addWishList = this.addWishList.bind(this);
        this.onCityKeyFocus = this.onCityKeyFocus.bind(this);
        this.removeWishList = this.removeWishList.bind(this);
        this.multiselectRef = React.createRef();
        this.onSelectMsa = this.onSelectMsa.bind(this);
        this.onStateSelect = this.onStateSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.signInPopup = this.signInPopup.bind(this);
        this.handleMarkerOnClick = this.handleMarkerOnClick.bind(this);
    }

    getPropertiesType() {
        axios.get(CONFIG.API_URL + 'lookup/propertytypes').then(response => {
            if (response.status === 200) {

                this.setState({ propertyTypeList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    clearPropertyType = () => {
        let fields = this.state.searchData;
        fields.PropertyTypeIds = '';
        this.setState({ fields })
        this.getPropertiesType();
    }

    clearLocationPop = () => {
        let fields = this.state.searchData;
        fields.IsLocation = false;
        fields.Address = '';
        fields.Zipcode = '';
        fields.Proximity = 5;
        fields.CityIds = '';
        fields.MSAListIds = '';
        fields.StateIds = '';
        this.setState({ isMSAError: false, addressError: '', cityName: '', cityList: [], msaList: [], selectedMSA: [], selectedStates: [], fields });
    }

    clearPropertyPop = () => {
        const { propertyTypeList, searchData } = this.state;
        propertyTypeList.forEach(element => {
            element.IsChecked = 0
        });
        searchData.PropertyTypeIds = '';
        this.setState({ imgId: 0, displayValue: 'Property Search', propertyTypeList, searchData });
    }

    handleKeyChange(e) {
        let fields = this.state.searchData;
        fields.SearchKeyword = e.currentTarget.value;
        const userInput = e.currentTarget.value;
        var obj = { PropertyName: userInput };

        this.setState({ fields, userInput: obj, noKeyWord: false });
        if (fields.SearchKeyword.length >= 2) {
            const data = {
                "Keyword": fields.SearchKeyword,
                "PropertyTypeIds": this.state.propertyTypeIds,
                "IsAdvancedSearch": false,
                "IsShowFull": false,
                RegionId: CONFIG.REGION_ID
            };
            axios.post(CONFIG.API_URL + 'search', data).then(response => {
                if (response.status === 200) {

                    this.setState({ propertyNameList: response.data, showPropertyNameList: true });
                }
            })
                .catch(err => {
                    this.setState({ isLoading: false });
                });
        } else {
            if (fields.SearchKeyword.length === 0) {
                this.setState({ propertyNameList: [], showPropertyNameList: true });
            }

        }
    }

    toggle() {

        this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen, isPriceFilter: false, isBuildingSize: false, isLotSize: false, isNrsf: false, isOther: false, isLocationFilter: false }));
    }
    clearPricePop = () => {
        let fields = this.state.searchData;
        fields.MaxPrice = '';
        fields.MinPrice = '';
        fields.IsPrice = false;
        this.setState({ fields, priceError: false })

    }
    clearBuildingPop = () => {
        let fields = this.state.searchData;
        fields.MaxSizeOfBuildings = '';
        fields.MinSizeOfBuildings = '';
        fields.IsBildingSize = false;
        this.setState({ fields })
    }
    clearLotPop = () => {
        let fields = this.state.searchData;
        fields.MaxLotSize = '';
        fields.MinLotSize = '';
        fields.IsLotSize = false;
        this.setState({ fields, lotError: false })
    }
    clearNRSFPop = () => {
        let fields = this.state.searchData;
        fields.MinNRSF = '';
        fields.MaxNRSF = '';
        fields.IsNRSF = false;
        this.setState({ fields, nrsfError: false })
    }

    clearOthersPop = () => {
        let fields = this.state.searchData;
        fields.IsOthers = false;
        fields.ShowOnlyWithRoomOfExpansion = null;
        fields.PortfoliosOnly = null;
        fields.ExcludePortfolios = null;
        fields.MarketPSFAverage = '';
        fields.AvPopulationPerStore = '';
        fields.CurrentSqftPerCapita = '';
        this.setState({ fields })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        Geocode.setApiKey(CONFIG.GeolocationApiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        var token = getToken();
        var userType = getUserType();
        var name = getName();

        this.autoCompleteClearEventListener();
        this.setState({ token, userType, name });
        this.delayedShowMarker();
        this.getMeasurementsTypes();
        this.getPriceRangeTypes();
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            this.getLookupState();
        }
        this.getMarketfeatures();

        if (this.props.match.params.searchId) {
            var searchId = this.props.match.params.searchId;
            this.setState({ searchId }, () => {
                this.getSearch();
            });
        }
        else {
            this.getSearchID().then(response => {
                if (response.status === 200) {
                    let searchId = response.data;
                    this.setState({ searchId }, () => {
                        this.getSearch();
                    });
                }
            })
                .catch(err => {
                });
        }
    }

    async getSearchID() {

        const data = {
            "RegionId": CONFIG.REGION_ID
        }

        return (axios.post(CONFIG.API_URL + 'search/criteria', data))
    }

    getMarketfeatures() {

        axios.get(CONFIG.API_URL + 'lookup/marketfeatures/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {

                let MVPMarketPSFAverage = response.data[0].MVPMarketPSFAverage;
                let MVPAveragePopulationPerStore = response.data[0].MVPAveragePopulationPerStore;
                let MVPCurrentSQFTPerCapita = response.data[0].MVPCurrentSQFTPerCapita;
                this.setState({ MVPMarketPSFAverage: MVPMarketPSFAverage, MVPAveragePopulationPerStore: MVPAveragePopulationPerStore, MVPCurrentSQFTPerCapita: MVPCurrentSQFTPerCapita });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });

    }

    handleChange(e) {
        let fields = this.state.searchData;
        if (e.target !== null) {
            let { name, value } = e.target;
            if (name === "LotsizeMeasurementId" || name === "PriceRangeTypeId" || name === "Proximity") {
                value = parseInt(value, 10);
                fields[name] = value;
            }
            else if (name === "ExpansionAndPortFolio") {
                if (value === "2") {
                    fields.PortfoliosOnly = true;
                    fields.ExcludePortfolios = false;
                    fields.IsOthers = true;
                    this.setState({ fields })
                }
                else if (value === "3") {
                    fields.PortfoliosOnly = false;
                    fields.ExcludePortfolios = true;
                    fields.IsOthers = true;
                    this.setState({ fields })
                }

            }
            else {
                if (name === "ShowOnlyWithRoomOfExpansion") {
                    fields.IsOthers = value !== "" ? true : false;
                }
                if (name === "Address") { fields.IsLocation = value !== "" ? true : false; }
                if (name === "MinPrice" || name === "MaxPrice") {

                    value = getFormattedInt(value.replace(/,/g, ''))
                    if (name === "MinPrice") {
                        if (fields.MaxPrice !== null && fields.MaxPrice !== "" && parseInt(value.replace(/,/g, ''), 10) > fields.MaxPrice) {
                            this.setState({ priceError: true })
                        }
                        else {
                            this.setState({ priceError: false })
                        }
                    }

                    else if (name === "MaxPrice") {
                        if (fields.MinPrice !== null && parseInt(value.replace(/,/g, ''), 10) < fields.MinPrice) {
                            this.setState({ priceError: true })
                        }
                        else {
                            this.setState({ priceError: false })
                        }
                    }

                    fields.IsPrice = value !== "" ? true : false;
                }
                if (name === "MinSizeOfBuildings" || name === "MaxSizeOfBuildings") {


                    value = getFormattedInt(value.replace(/,/g, ''))
                    if (name === "MinSizeOfBuildings") {
                        if (fields.MaxSizeOfBuildings !== null && parseInt(value.replace(/,/g, ''), 10) > fields.MaxSizeOfBuildings) {
                            this.setState({ buildError: true })
                        }
                        else {
                            this.setState({ buildError: false })
                        }
                    }

                    else if (name === "MaxSizeOfBuildings") {
                        if (fields.MinSizeOfBuildings !== null && parseInt(value.replace(/,/g, ''), 10) < fields.MinSizeOfBuildings) {
                            this.setState({ buildError: true })
                        }
                        else {
                            this.setState({ buildError: false })
                        }
                    }

                    fields.IsBildingSize = value !== "" ? true : false;
                }
                if (name === "MinLotSize" || name === "MaxLotSize") {
                    value = getFormattedInt(value.replace(/,/g, ''))
                    if (name === "MinLotSize") {
                        if (fields.MaxLotSize !== null && fields.MaxLotSize !== "" && parseInt(value.replace(/,/g, ''), 10) > fields.MaxLotSize) {
                            this.setState({ lotError: true })
                        }
                        else {
                            this.setState({ lotError: false })
                        }
                    }

                    else if (name === "MaxLotSize") {
                        if (fields.MinLotSize !== null && parseInt(value.replace(/,/g, ''), 10) < fields.MinLotSize) {
                            this.setState({ lotError: true })
                        }
                        else {
                            this.setState({ lotError: false })
                        }
                    }

                    fields.IsLotSize = value !== "" ? true : false;
                }

                if (name === "MinNRSF" || name === "MaxNRSF") {
                    value = getFormattedInt(value.replace(/,/g, ''))
                    if (name === "MinNRSF") {
                        if (fields.MaxNRSF !== null && parseInt(value.replace(/,/g, ''), 10) > fields.MaxNRSF) {
                            this.setState({ nrsfError: true })
                        }
                        else {
                            this.setState({ nrsfError: false })
                        }
                    }

                    else if (name === "MaxNRSF") {
                        if (fields.MinNRSF !== null && parseInt(value.replace(/,/g, ''), 10) < fields.MinNRSF) {
                            this.setState({ nrsfError: true })
                        }
                        else {
                            this.setState({ nrsfError: false })
                        }
                    }

                    fields.IsNRSF = value !== "" ? true : false;
                }

                if (name === "MarketPSFAverage" || name === "AvPopulationPerStore" || name === "CurrentSqftPerCapita") {
                    value = value.replace(/,/g, '')
                    fields.IsOthers = value !== "" ? true : false;
                }

                if (name === "MinPrice" || name === "MaxPrice" || name === "MaxSizeOfBuildings" || name === "MinSizeOfBuildings" || name === "MaxNRSF" || name === "MinNRSF" || name === "MaxLotSize" || name === "MinLotSize") {
                    value = value.replace(/,/g, '')
                    fields[name] = value;
                }
                else {
                    if (name === "ShowOnlyWithRoomOfExpansion") {
                        fields[name] = e.target.checked;
                    }
                    else {
                        fields[name] = value;
                    }
                }

                this.setState({ fields });
            }
        }
    }

    signInPopup() {
        document.getElementById("loginclick").click();
    }

    onStateSelect = (event, selectedList) => {

        const { searchData } = this.state;
        searchData.IsLocation = true;
        searchData.CityIds = '';
        searchData.MSAListIds = '';
        var stateIds = Array.prototype.map.call(selectedList, function (item) { return item.Id; }).join(",");
        var selectedStates = selectedList;
        searchData.StateIds = stateIds;

        this.setState({ selectedMSA: [], msaList: [], cityName: '', cityList: [], searchData, selectedStates });

        if (stateIds !== "") {
            const data = {
                StateIDs: stateIds
            }

            axios.post(CONFIG.API_URL + 'lookup/msa', data).then(response => {
                if (response.status === 200) {

                    this.setState({ msaList: response.data, isMSAError: false });
                }
            })
                .catch(err => {
                    this.setState({ isLoading: false });
                });
        }
    }

    onMSASelect = (event, selectedList, selectedItem) => {
        this.setState({ selectedMSA: selectedList });
    }

    getMeasurementsTypes() {
        axios.get(CONFIG.API_URL + 'lookup/measurements').then(response => {
            if (response.status === 200) {
                this.setState({ measurementsList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    onKeyPress(e) {
        const re = /[0-9,.]+/g;
        if (e.key !== "Enter") {
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }
    }

    handleOrderByChange(e) {
        let orderbyL = e.target.value;
        var oderBySplit = orderbyL.split("-");
        let sortBy = parseInt(oderBySplit[0], 10);
        let sortOrder = oderBySplit[1];
        this.setState({ mapView: true, sortOrder, sortBy }, () => {
            let data = {
                "Keyword": "",
                "PropertyTypeId": '',
                "IsAdvancedSearch": false,
                "IsShowFull": true,
                "SearchId": parseInt(this.state.searchId, 10),
                "Page": 1,
                "PageLength": CONFIG.PAGE_LENGTH,
                "SortBy": parseInt(this.state.sortBy, 10),
                "SortOrder": this.state.sortOrder
            };

            this.getResult(data);
        })
    }

    onSelectMsa(selectedList, selectedItem) {
        let msaId;
        if (selectedList.length === 1) {
            msaId = selectedItem.Id;
            this.setState({ msaId: msaId });
        }
        else {
            msaId = this.state.msaId.concat(',' + selectedItem.Id);
            this.setState({ msaId: msaId })
        }

    }

    getLookupState() {

        axios.get(CONFIG.API_URL + 'lookup/state/' + CONFIG.US_CountryId).then(response => {
            if (response.status === 200) {
                this.setState({
                    stateList: response.data,
                });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({ isMarkerShown: true })
        }, 3000)
    }

    handleMarkerClick = () => {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
    }

    priceFilter() {

        let isPriceFilter = this.state.isPriceFilter;
        this.setState({ isPriceFilter: !isPriceFilter, isBuildingSize: false, isLotSize: false, isNrsf: false, isOther: false, isLocationFilter: false })
    }
    locationFilter() {

        let isLocationFilter = this.state.isLocationFilter;
        this.setState({ isLocationFilter: !isLocationFilter, isPriceFilter: false, isBuildingSize: false, isLotSize: false, isNrsf: false, isOther: false })
    }

    buildingSizeFilter() {
        let isBuildingSize = this.state.isBuildingSize;
        this.setState({ isBuildingSize: !isBuildingSize, isPriceFilter: false, isLotSize: false, isNrsf: false, isOther: false, isLocationFilter: false })
    }
    lotSizeFilter() {
        let isLotSize = this.state.isLotSize;
        this.setState({ isLotSize: !isLotSize, isBuildingSize: false, isPriceFilter: false, isNrsf: false, isOther: false, isLocationFilter: false })
    }
    closePops() {

        this.setState({ isLotSize: false, isBuildingSize: false, isPriceFilter: false, isNrsf: false, isOther: false, isLocationFilter: false })
    }

    nrsfFilter() {
        let isNrsf = this.state.isNrsf;
        this.setState({ isNrsf: !isNrsf, isLotSize: false, isPriceFilter: false, isBuildingSize: false, isOther: false, isLocationFilter: false })
    }
    otherFilter() {
        let isOther = this.state.isOther;
        this.setState({ isOther: !isOther, isNrsf: false, isLotSize: false, isPriceFilter: false, isBuildingSize: false, isLocationFilter: false })
    }

    roomExpansionOnChange(e) {
        let isRoomExpansion = this.state.isRoomExpansion;
        this.setState({ isRoomExpansion: !isRoomExpansion });

    }
    portfoliosOnChange(e) {
        let isPortfolios = this.state.isPortfolios;
        this.setState({ isPortfolios: !isPortfolios })
    }
    excludeProtfoliosonChange(e) {
        let isExcludeProtfolios = this.state.isExcludeProtfolios;
        this.setState({ isExcludeProtfolios: !isExcludeProtfolios })
    }

    onMapChange = (center, zoom, bounds) => {
        const { mapZoomCount } = this.state;
        this.setState({ mapZoomCount: mapZoomCount + 1 });

        if (zoom > 4 && mapZoomCount > 1) {
            const { map, mapData } = this.state;
            var mapBounds = map.getBounds();

            if (mapBounds === null || mapBounds === undefined) {
                return null;
            }
            else {
                var markerIds = '';
                for (var i = 0; i < mapData.length; i++) {
                    if (mapBounds.contains(new window.google.maps.LatLng(mapData[i].Latitude, mapData[i].Longitude))) {
                        markerIds += mapData[i].PropertyId + ',';
                    }
                }
                this.onMapZoomSearch(markerIds);
            }
        }

        if (zoom < 4 && mapZoomCount > 1) {
            this.onMapZoomSearch(null);
        }
    }

    onMapZoomSearch(markerIds) {
        const data = {
            SearchID: parseInt(this.state.searchId, 10),
            RegionId: CONFIG.REGION_ID,
            PolygonPropertyIds: markerIds,
        }

        axios.post(CONFIG.API_URL + 'search/criteria/markerIds', data)
            .then(response => {
                if (response.status === 200) {
                    this.getSearch(true);
                }
            })
            .catch(err => {
                // this.setState({ isLoading: false });
                // if (err.response != null && err.response != undefined) {
                //     this.props.dispatch(setSnackbar(true, "error", err.response.data));
                // }
                // else {
                //     this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                // }
            });
    }

    getSearch(isMapZoomSearch, mapResize) {
        axios.get(CONFIG.API_URL + 'search/criteria/' + parseInt(this.state.searchId, 10))
            .then(res => {
                if (!isMapZoomSearch) {
                    let searchData = res.data.UserSearchCriteria;

                    let selectedStates = res.data.StateList;
                    let propertyTypeList = res.data.PropertyType;
                    this.setState({ propertyTypeList: propertyTypeList })

                    let selectedMSA = res.data.MSAList;
                    let cityName = res.data.CityList.length > 0 ? res.data.CityList[0] : '';
                    let isRoomExpansion = res.data.UserSearchCriteria.ShowOnlyWithRoomOfExpansion;
                    let isPortfolios = res.data.UserSearchCriteria.PortfoliosOnly;
                    let isExcludeProtfolios = res.data.UserSearchCriteria.ExcludePortfolios;

                    if (searchData.StateIds) {
                        const dataMsa = {
                            StateIDs: searchData.StateIds
                        }

                        axios.post(CONFIG.API_URL + 'lookup/msa', dataMsa).then(response => {
                            if (response.status === 200) {

                                this.setState({ msaList: response.data });
                            }
                        })
                            .catch(err => {
                                this.setState({ isLoading: false });
                            });
                    }

                    if (searchData.SearchKeyword) {
                        var userInput = {
                            PropertyName: searchData.SearchKeyword
                        };

                        this.setState({ userInput });
                    }

                    this.setState({ searchData: searchData, isRoomExpansion: isRoomExpansion, isPortfolios: isPortfolios, isExcludeProtfolios: isExcludeProtfolios, selectedStates: selectedStates, selectedMSA: selectedMSA, cityName: cityName, propertyTypeList: propertyTypeList })
                }

                let dataSearch = {
                    "Keyword": this.state.searchData.SearchKeyword,
                    "PropertyTypeId": '1',
                    "IsAdvancedSearch": false,
                    "IsShowFull": true,
                    "SearchId": parseInt(this.state.searchId, 10),
                    "Page": 1,
                    "PageLength": CONFIG.PAGE_LENGTH,
                    "SortBy": parseInt(this.state.sortBy, 10),
                    "SortOrder": this.state.sortOrder
                };

                this.getResult(dataSearch, isMapZoomSearch, mapResize);
            })
            .catch((err) => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            })
    }

    getResult(ip, isMapZoomSearch, mapResize) {
        const data = {
            "Keyword": this.state.searchData.SearchKeyword,
            "PropertyTypeId": '1',
            "IsAdvancedSearch": true,
            "IsShowFull": true,
            "SearchId": parseInt(this.state.searchId, 10),
            "Page": parseInt(ip.Page, 10),
            "PageLength": CONFIG.PAGE_LENGTH,
            "SortBy": parseInt(this.state.sortBy, 10),
            "SortOrder": this.state.sortOrder,
            "RegionId": CONFIG.REGION_ID
        };

        axios.post(CONFIG.API_URL + 'search', data)
            .then(res => {
                let searchResultData = res.data.PropertyList;
                let paginationData = res.data.Pagination;
                let pageNum = Math.ceil(paginationData.TotalRecords / 10);
                window.scrollTo(0, 0);
                this.setState({ searchResultData: searchResultData, paginationData: paginationData, mapView: true, pageNum: pageNum });

                if (!isMapZoomSearch && ip.Page <= 1) {
                    let isNoRecords = searchResultData.length > 0 ? false : true;
                    let mapData = res.data.MapData;
                    let defaultViewport = {
                        ...this.state.viewport,
                        longitude: CONFIG.REGION_ID === REGIONS.UK ? -5.440458 : -87.6500523,
                        latitude: CONFIG.REGION_ID === REGIONS.UK ? 53.838006 : 41.850033,
                        zoom: CONFIG.REGION_ID === REGIONS.UK ? 5 : 3,
                    }
                    if (mapResize && !isNoRecords) this.mapFitBounds(res.data.MapData);
                    if (isNoRecords) this.setState({ viewport: defaultViewport });
                    this.setState({ mapData, isNoRecords });
                }
            })
            .catch(err => {
            });
    }

    handleChangeRowsPerPage = event => {
        this.setState({ pageCount: 0, 10: event.target.value });
    };

    clear() {
        const data = {
            SearchID: parseInt(this.state.searchId, 10),
            SearchKeyword: '',
            PropertyTypeIds: '',
            StateIds: "",
            MSAListIds: "",
            Address: "",
            CityIds: "",
            Zipcode: "",
            Proximity: "5",
            Latitude: "",
            Longitude: "",
            MinPrice: "",
            MaxPrice: "",
            PriceRangeTypeId: "",
            MinSizeOfBuildings: "",
            MaxSizeOfBuildings: "",
            MinLotSize: "",
            MaxLotSize: "",
            LotsizeMeasurementId: "",
            MinNRSF: "",
            MaxNRSF: "",
            ShowOnlyWithRoomOfExpansion: "",
            PortfoliosOnly: "",
            ExcludePortfolios: "",
            MarketPSFAverage: "",
            AvPopulationPerStore: "",
            CurrentSqftPerCapita: "",
            RegionId: CONFIG.REGION_ID
        }

        axios.post(CONFIG.API_URL + 'search/criteria', data)
            .then(response => {
                if (response.status === 200) {
                    this.setState({ selectedValue: null, stateList: [], selectedStates: [], selectedMSA: [], cityName: '', cityList: [], propertyNameList: [], msaList: [] });
                    this.setState({ isMSAError: false, userInput: '', isPriceFilter: false, isBuildingSize: false, isLotSize: false, isNrsf: false, isOther: false, isLocationFilter: false, addressError: '', buildError: false, priceError: false, nrsfError: false, lotError: false, imgId: 0, displayValue: 'Property Search' });

                    this.myFormRef && this.myFormRef.reset();
                    this.getSearch('', true);
                    if (CONFIG.REGION_ID !== REGIONS.UK) {
                        this.getLookupState();
                    }
                }
            })
            .catch(err => {
            });
    }

    handleChangePage = (event, pageCount) => {
        let data = {
            "Keyword": this.state.searchData.SearchKeyword ? this.state.searchData.SearchKeyword : "",
            "PropertyTypeId": this.state.searchData.propertyTypeIds,
            "IsAdvancedSearch": true,
            "IsShowFull": true,
            "SearchId": parseInt(this.state.searchId, 10),
            "Page": pageCount,
            "PageLength": CONFIG.PAGE_LENGTH,
            "SortBy": parseInt(this.state.sortBy, 10),
            "SortOrder": this.state.sortOrder
        };

        this.getResult(data);
    };

    onKeyFocus() {
        const { showPropertyNameList } = this.state;
        this.setState({ showPropertyNameList: !showPropertyNameList });
    }

    onCityKeyFocus() {
        const { showPropertyNameList } = this.state;
        this.setState({ showPropertyNameList: !showPropertyNameList });
    }

    getPriceRangeTypes() {
        axios.get(CONFIG.API_URL + 'lookup/pricerangetypes').then(response => {
            if (response.status === 200) {
                this.setState({ priceRangeTypesList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    handleCityChange(e) {

        const city = e.currentTarget.value;
        const obj = {
            Name: city,
            StateID: '',
        }

        this.setState({ cityName: obj });
        const { searchData } = this.state;


        if (city === '') {
            searchData.CityIds = '';
            this.setState({ searchData, cityList: [] });

        } else {
            const data = {
                Keyword: city,
                StateIDs: searchData.StateIds,
                RegionID: CONFIG.REGION_ID
            }
            axios.post(CONFIG.API_URL + 'search/city', data).then(response => {

                if (response.status === 200) {

                    this.setState({ cityList: response.data, showCityList: true });
                }
            })
                .catch(err => {
                    this.setState({ isLoading: false });
                });
        }
    }

    updateSearch(event, errors, values) {
        if (this.state.priceError === false && this.state.nrsfError === false && this.state.lotError === false && this.state.buildError === false) {
            let newData = this.state.searchData;
            const { cityName, selectedMSA, selectedStates } = this.state;
            var city = cityName ? cityName.Name : '';
            var cityState = cityName ? cityName.StateID : '';
            var cityId = cityName ? cityName.ID : '';
            var msaId = Array.prototype.map.call(selectedMSA, function (item) { return item.ID; }).join(",");
            var stateId = Array.prototype.map.call(selectedStates, function (item) { return item.Id; }).join(",");

            this.setState({ addressError: '', openSearch: false });
            if (newData.Address !== null && newData.Address !== "") {
                if (city !== "") {

                    var address = newData.Address + "," + city + "," + cityState + ",USA";
                    Geocode.fromAddress(address).then(
                        response => {
                            const { lat, lng } = response.results[0].geometry.location;
                            this.setState({ lat: lat, lng: lng })
                            if (errors.length === 0) {
                                const data = {
                                    SearchID: parseInt(this.state.searchId, 10),
                                    SearchKeyword: newData.SearchKeyword,
                                    PropertyTypeIds: newData.PropertyTypeIds,
                                    StateIds: stateId,
                                    MSAListIds: msaId,

                                    Address: newData.Address,
                                    CityIds: cityId,
                                    Zipcode: newData.Zipcode,
                                    Proximity: newData.Proximity,
                                    Latitude: this.state.lat,
                                    Longitude: this.state.lng,
                                    MinPrice: newData.MinPrice !== "" ? parseInt(newData.MinPrice, 10) : "",
                                    MaxPrice: newData.MaxPrice !== "" ? parseInt(newData.MaxPrice, 10) : "",
                                    PriceRangeTypeId: newData.PriceRangeTypeId !== "" ? parseInt(newData.PriceRangeTypeId, 10) : "",
                                    MinLotSize: newData.MinLotSize !== "" ? parseInt(newData.MinLotSize, 10) : "",
                                    MaxLotSize: newData.MaxLotSize !== "" ? parseInt(newData.MaxLotSize, 10) : "",
                                    LotsizeMeasurementId: newData.LotsizeMeasurementId !== "" ? parseInt(newData.LotsizeMeasurementId, 10) : "",
                                    MinNRSF: newData.MinNRSF !== "" ? parseInt(newData.MinNRSF, 10) : "",
                                    MaxNRSF: newData.MaxNRSF !== "" ? parseInt(newData.MaxNRSF, 10) : "",
                                    ShowOnlyWithRoomOfExpansion: newData.ShowOnlyWithRoomOfExpansion,
                                    PortfoliosOnly: newData.PortfoliosOnly,
                                    ExcludePortfolios: newData.ExcludePortfolios,
                                    MarketPSFAverage: parseFloat(newData.MarketPSFAverage),
                                    AvPopulationPerStore: parseFloat(newData.AvPopulationPerStore),
                                    CurrentSqftPerCapita: parseFloat(newData.CurrentSqftPerCapita),
                                    RegionId: CONFIG.REGION_ID
                                }

                                axios.post(CONFIG.API_URL + 'search/criteria', data)
                                    .then(response => {

                                        this.setState({ isLocationFilter: false, isPriceFilter: false, isBuildingSize: false, isLotSize: false, isNrsf: false, isOther: false, showPropertyNameList: false })
                                        this.setState({ isPriceFilter: false, isBuildingSize: false, isLotSize: false, isNrsf: false, isOther: false, isLocationFilter: false, mapView: true })
                                        this.getSearch();
                                        this.getMeasurementsTypes();
                                        this.getPriceRangeTypes();
                                        if (CONFIG.REGION_ID !== REGIONS.UK) {
                                            this.getLookupState();
                                        }
                                        this.getMarketfeatures();
                                    })
                                    .catch(err => {
                                    });
                            }
                        },
                        error => {
                            this.setState({ addressError: 'Please enter valid address' });
                        }
                    );
                } else {
                    this.setState({ addressError: 'Please enter city' });
                }
            }
            else {
                if (errors.length === 0) {
                    const data = {
                        SearchID: parseInt(this.state.searchId, 10),
                        SearchKeyword: newData.SearchKeyword,
                        PropertyTypeIds: newData.PropertyTypeIds,
                        StateIds: stateId,
                        MSAListIds: msaId,
                        Address: newData.Address,
                        CityIds: cityId,
                        Zipcode: newData.Zipcode,
                        Proximity: newData.Proximity,
                        Latitude: this.state.lat,
                        Longitude: this.state.lng,
                        MinPrice: newData.MinPrice !== "" ? parseInt(newData.MinPrice, 10) : "",
                        MaxPrice: newData.MaxPrice !== "" ? parseInt(newData.MaxPrice, 10) : "",
                        PriceRangeTypeId: newData.PriceRangeTypeId !== "" ? parseInt(newData.PriceRangeTypeId, 10) : "",
                        MinLotSize: newData.MinLotSize !== "" ? parseInt(newData.MinLotSize, 10) : "",
                        MaxLotSize: newData.MaxLotSize !== "" ? parseInt(newData.MaxLotSize, 10) : "",
                        LotsizeMeasurementId: newData.LotsizeMeasurementId !== "" ? parseInt(newData.LotsizeMeasurementId, 10) : "",
                        MinNRSF: newData.MinNRSF !== "" ? parseInt(newData.MinNRSF, 10) : "",
                        MaxNRSF: newData.MaxNRSF !== "" ? parseInt(newData.MaxNRSF, 10) : "",
                        ShowOnlyWithRoomOfExpansion: newData.ShowOnlyWithRoomOfExpansion,
                        PortfoliosOnly: newData.PortfoliosOnly,
                        ExcludePortfolios: newData.ExcludePortfolios,
                        MarketPSFAverage: parseFloat(newData.MarketPSFAverage),
                        AvPopulationPerStore: parseFloat(newData.AvPopulationPerStore),
                        CurrentSqftPerCapita: parseFloat(newData.CurrentSqftPerCapita),
                        RegionId: CONFIG.REGION_ID
                    }

                    axios.post(CONFIG.API_URL + 'search/criteria', data)
                        .then(response => {
                            this.setState({ isLocationFilter: false, isPriceFilter: false, isBuildingSize: false, isLotSize: false, isNrsf: false, isOther: false, showPropertyNameList: false })
                            this.setState({ isPriceFilter: false, isBuildingSize: false, isLotSize: false, isNrsf: false, isOther: false, isLocationFilter: false, addressError: '' })
                            this.getSearch('', true);
                            this.getMeasurementsTypes();
                            this.getPriceRangeTypes();
                            if (CONFIG.REGION_ID !== REGIONS.UK) {
                                this.getLookupState();
                            }
                            this.getMarketfeatures();
                        })
                        .catch(err => {
                        });
                }
            }
        }
    }

    removeWishList(propertyId) {
        confirmAlert({
            message: 'Are you sure to remove this property from WatchList?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        this.setState({ isLoading: true });

                        axios.delete(CONFIG.API_URL + 'property/watchlist/' + parseInt(propertyId, 10))
                            .then(res => {
                                this.updateWatchListIcon(propertyId, false);
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });

                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    propertyCheckBox = (checkData) => {
        let fields = this.state.searchData;
        let data = this.state.propertyTypeList;
        checkData.IsChecked = checkData.IsChecked === 0 ? 1 : 0;
        if (checkData.IsChecked === 1) {
            data.forEach(d => {
                if (d.Name === checkData.Name)
                    d.IsChecked = 1
            })
            this.setState({ propertyTypeList: data });
        } else if (checkData.IsChecked === 0) {
            data.forEach(d => {
                if (d.Name === checkData.Name)
                    d.IsChecked = 0
            })
            this.setState({ propertyTypeList: data });
        }

        let filterArr = [];
        let sendFinalData = this.state.propertyTypeList;
        let selectedTrueOnly = sendFinalData.filter(data => data.IsChecked === 1);
        if (selectedTrueOnly.length > 0) {
            if (selectedTrueOnly.length === 1) {
                this.setState({ displayValue: selectedTrueOnly[0].Name, imgId: 0 })
            }
            else { this.setState({ displayValue: selectedTrueOnly[0].Name, imgId: selectedTrueOnly.length - 1 }) }
        }
        else {
            this.setState({ displayValue: 'Property Search', imgId: 0 })
        }
        selectedTrueOnly.forEach(d => {
            filterArr.push(d.ID)
        })

        let propertyTypeIds = filterArr.toString();
        fields.PropertyTypeIds = propertyTypeIds;
        this.setState({ fields });
    }

    updateWatchListIcon(propertyId, isAdded) {
        const { searchResultData } = this.state;

        const newList = searchResultData.map((item) => {
            if (item.PropertyId === propertyId) {
                const updatedItem = {
                    ...item,
                    IsWishlisted: isAdded,
                };

                return updatedItem;
            }

            return item;
        });

        this.setState({ searchResultData: newList });
    }

    addWishList(propertyId) {
        confirmAlert({
            message: 'Are you sure to add this property to your WatchList?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        axios.post(CONFIG.API_URL + 'property/watchlist/' + parseInt(propertyId, 10))
                            .then(response => {
                                this.updateWatchListIcon(propertyId, true);
                            })
                            .catch(err => {
                                this.setState({ isLoading: false });
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    handleMarkerOnClick = (selectedMarker) => {

        const { infoWindow, map } = this.state;
        var price = "";
        if (selectedMarker.AlternatePriceOptionID) {
            price = selectedMarker.AlternatePriceOptionName;

            if ((selectedMarker.AlternatePriceOptionID === PRICEOPTION.CallforOffers || selectedMarker.AlternatePriceOptionID === PRICEOPTION.Auction) && selectedMarker.AlternatePriceOptionDate !== null) {
                price = price + ": " + moment(selectedMarker.AlternatePriceOptionDate).format(getDateFormat())
            }
        }
        else {

            price = selectedMarker.Currency + getFormattedInt(selectedMarker.Price);
        }

        infoWindow.setContent(`
            <div className='gmap-tooltip map-view'> 
            <a target="_blank" className='textdecoration' href=${"/property/" + selectedMarker.PropertyId + "/" + selectedMarker.Permalink}>
             <img className='map-view-img' src=${selectedMarker.PrimaryImage ? '/PropertyImages/' + selectedMarker.PrimaryImage : feature_home} alt="img" /> 
             <h6 title='${selectedMarker.PropertyName}'>${selectedMarker.PropertyName}</h6>
                <p> ${price}</p>
            </a>
            </div>
        `);

        infoWindow.setPosition({ lat: parseFloat(selectedMarker.Latitude), lng: parseFloat(selectedMarker.Longitude) });
        infoWindow.open(map);
    };

    initInfoWindow = (map, maps, searchResultData) => {
        if (!maps) return;

        if (searchResultData && searchResultData.length > 0) {
            const infoWindow = new maps.InfoWindow({ pixelOffset: { width: 0, height: -40 } });
            this.setState({
                map: map,
                maps: maps,
                infoWindow: infoWindow,
            });

            if (map && maps) {
                const mapBounds = new maps.LatLngBounds();
                searchResultData.forEach((item) => {
                    if (item.Latitude && item.Longitude && item.Latitude !== "0" && item.Longitude !== "0") {
                        mapBounds.extend({ lat: parseFloat(item.Latitude), lng: parseFloat(item.Longitude) })
                    }
                });

                map.fitBounds(mapBounds);
                if (searchResultData.length === 1) {
                    map.setZoom(map.getZoom() - 6)
                }
            }
        }
    };

    setOpen = (open, params) => {
        this.setState({ open });
    }

    setOpenMSA = (openMSA, params) => {
        const { selectedStates } = this.state;

        var isMSAError = false;
        if (selectedStates.length === 0) {
            isMSAError = true;
        }
        this.setState({ openMSA, isMSAError });
    }

    setOpenSearch = (openSearch, params) => {
        this.setState({ openSearch });
    }

    citySelect = (e, value) => {
        var cityName = value;
        this.setState({ cityName });
    }


    searchSelect = (event, property) => {
        if (property) {
            if (property.PropertyId) {
                const { from } = { from: { pathname: "/property/" + property.Permalink } };
                this.props.history.push(from);
            } else {
                document.getElementById('btnApply').click();
            }
        }
    }

    autoCompleteClearEventListener() {
        // Take the Reference of Close Button
        const close = document.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
        )[0];

        // Add a Click Event Listener to the button
        close.addEventListener("click", () => {
            this.setState({ userInput: '', propertyNameList: [], searchData: { ...this.state.searchData, SearchKeyword: '' } });
        });
    }

    searchFilterData = () => {
        let mf = this.mapRef.current;
        let { mapData } = this.state;
        let mapBounds = mf.getMap().getBounds();
        let markerIds = '';
        for (let i = 0; i < mapData.length; i++) {
            if (mapBounds.contains([Number(mapData[i].geometry.coordinates[0]), Number(mapData[i].geometry.coordinates[1])])) {
                markerIds += mapData[i].properties.PropertyId + ',';
            }
        }
        this.onMapZoomSearch(markerIds);
    }

    mapFitBounds = (data) => {
        let paddingLength = isDesktop || isTablet ? CONFIG.REGION_ID === REGIONS.US ? parseInt(window.innerHeight / 4) : 200 : 50;
        let points = data.map(ft => ft.geometry.coordinates);
        const applyToArray = (func, array) => func.apply(Math, array);
        const pointsLong = points.map((point) => point[0]);
        const pointsLat = points.map((point) => point[1]);
        const cornersLongLat = [
            [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
            [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
        ];
        const viewport = new WebMercatorViewport({
            width: window.innerWidth,
            height: window.innerHeight,
        }).fitBounds(cornersLongLat, {
            padding: { top: paddingLength, bottom: paddingLength, left: paddingLength, right: paddingLength },
        });
        const { longitude, latitude, zoom } = viewport;
        this.setState({
            viewport: {
                ...viewport,
                latitude: latitude,
                longitude: longitude,
                zoom: zoom,
            }
        });
    }

    handleOnMapLoad = (data) => {
        let width = data.target._containerWidth;
        let height = data.target._containerHeight;
        this.setState({ mapSize: { height, width } });
        this.mapFitBounds(this.state.mapData);
    }

    handleViewportChange = (viewport) => {
        let { width, height } = this.state.mapSize;
        if (width && height) viewport = { ...viewport, height, width };
        this.setState({ viewport });
    }

    handleMapClick = (event) => {
        let latitude = Number(event.lngLat[1]);
        let longitude = Number(event.lngLat[0]);
        let data = event.features.filter(ft => ft.layer.type === "circle")[0];
        if (data && data.layer.type === "circle") {
            let price = "";
            let selectedMarker = data.properties;
            if (selectedMarker.AlternatePriceOptionID) {
                price = selectedMarker.AlternatePriceOptionName;

                if ((selectedMarker.AlternatePriceOptionID === PRICEOPTION.CallforOffers || selectedMarker.AlternatePriceOptionID === PRICEOPTION.Auction) && selectedMarker.AlternatePriceOptionDate !== null) {
                    price = price + ": " + moment(selectedMarker.AlternatePriceOptionDate).format(getDateFormat())
                }
            }
            else {
                price = selectedMarker.Currency + getFormattedInt(selectedMarker.Price);
            }
            let popupInfoDescription = (
                <div>
                    <a target="_blank" className='textdecoration' rel='noreferrer' href={"/property/" + selectedMarker.Permalink}>
                        <img className='map-view-img' src={selectedMarker.PrimaryImage ? '/PropertyImages/' + selectedMarker.PrimaryImage : feature_home} alt="img" />
                        <h6 title={selectedMarker.PropertyName}>{selectedMarker.PropertyName}</h6>
                        <p>{price}</p>
                    </a>
                </div>
            );
            this.setState({ showPopupInfo: true, popupInfoLatlng: { latitude, longitude }, popupInfoDescription });
        }
    }

    render() {
        const { searchData, mapData, isPriceFilter, legendData, isLotSize, isNrsf, isOther, paginationData, searchResultData,
            isLocationFilter, stateList, msaList, cityList, cityName, mapView,
            token, userType, orderBy, selectedStates, selectedMSA, priceRangeTypesList, measurementsList, addressError, MVPMarketPSFAverage,
            MVPAveragePopulationPerStore, MVPCurrentSQFTPerCapita, pageNum, priceError, lotError, propertyNameList,
            nrsfError, isNoRecords, dropdownOpen, displayValue, imgId, propertyTypeList, userInput, open, openSearch, openMSA, isMSAError } = this.state;

        // document.title = CONFIG.PAGE_TITLE + 'Listings';
        return (
            <div className="search-page">
                <Helmet
                    title='Listings'
                    addPostfixTitle={true}
                    keywords="Listings, Search, Property"
                />
                <div className="searchbar-top">
                    {/* <h5>Filters</h5> */}

                    <AvForm onSubmit={this.updateSearch.bind(this)} ref={c => (this.myFormRef = c)}>
                        <Row className="row-width-100">

                            <div className="box-search-home">
                                <div className="searchresult-input">
                                    <Autocomplete
                                        id="asynchronous-demo1"
                                        name="city"
                                        open={openSearch}
                                        onOpen={() => {
                                            this.setOpenSearch(true);
                                        }}
                                        onClose={() => {
                                            this.setOpenSearch(false);
                                        }}
                                        onChange={this.searchSelect}
                                        value={userInput ? userInput : ''}
                                        getOptionLabel={(option) => option ? option.PropertyName : ''}
                                        options={propertyNameList}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={CONFIG.REGION_ID !== REGIONS.UK ? "Enter a location, city or state" : "Enter a location or town/city"}
                                                onChange={this.handleKeyChange}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </div><div onClick={() => this.closePops(this)} className="width10"></div>
                                <div className="select-property-search search-resultdropdown">
                                    <Dropdown isOpen={dropdownOpen} toggle={() => this.toggle()}>
                                        <DropdownToggle>
                                            {displayValue}{imgId === 0 ? '' : <span className="numbroder">+{imgId ? imgId : ''}</span>}
                                            <span className="checked-close">
                                                {(propertyTypeList.filter(x => x.IsChecked === 1)).length > 0 ?
                                                    <img src={xicon} className="closed-x width12" onClick={() => this.clearPropertyPop(this)} alt="Close" />
                                                    : <img src={downicon} className="closed-x arrow-bottom" onClick={this.locationFilter.bind(this)} alt="Down Arrow" />}
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {propertyTypeList ? propertyTypeList.map(data => {
                                                return (
                                                    <DropdownItem key={data.ID} onClick={(e) => this.propertyCheckBox(data)}>
                                                        {data.Name}
                                                        {data.IsChecked === 0 ? '' : <FontAwesomeIcon icon={faCheck} />}
                                                    </DropdownItem>
                                                );
                                            }) : ''}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div onClick={() => this.closePops(this)} className="width10"></div>
                            </div>
                            <div className="search-heading location-search-head">
                                <Link onClick={this.locationFilter.bind(this)}>Location</Link>
                                <span className="checked-close">{searchData.IsLocation === true ? <img src={xicon} className="closed-x width12" onClick={() => this.clearLocationPop()} alt="Close" /> : <img src={downicon} className="closed-x" onClick={this.locationFilter.bind(this)} alt="Down Arrow" />}</span>
                                {isLocationFilter ?
                                    <div className="location-search statescroll">
                                        {CONFIG.REGION_ID !== REGIONS.UK ?
                                            <React.Fragment>
                                                <div className="disply-flex width-100 city-selection">
                                                    <Label>State:</Label>
                                                    <div className="width-100">
                                                        <Autocomplete
                                                            multiple
                                                            id="tags-standard"
                                                            options={stateList}
                                                            value={selectedStates}
                                                            getOptionLabel={(option) => option ? option.Id : ''}
                                                            onChange={this.onStateSelect}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="disply-flex width-100 city-selection">
                                                    <Label>MSA:</Label>
                                                    <div className="width-100">
                                                        <Autocomplete
                                                            multiple
                                                            id="tags-standard"
                                                            options={msaList}
                                                            open={openMSA}
                                                            onOpen={() => {
                                                                this.setOpenMSA(true);
                                                            }}
                                                            onClose={() => {
                                                                this.setOpenMSA(false);
                                                            }}
                                                            value={selectedMSA}
                                                            getOptionLabel={(option) => option.Name}
                                                            onChange={this.onMSASelect}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    error={isMSAError}
                                                                    helperText={isMSAError ? "Please select state" : ''}
                                                                    {...params}
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            : ''}
                                        {CONFIG.REGION_ID === REGIONS.US ?
                                            <div className="disply-flex width-100 city-selection last-field">
                                                <Label>City:&nbsp;&nbsp;</Label>
                                                <div className="width-100">
                                                    <Autocomplete
                                                        id="asynchronous-demo"
                                                        name="city"
                                                        open={open}
                                                        onOpen={() => {
                                                            this.setOpen(true);
                                                        }}
                                                        onClose={() => {
                                                            this.setOpen(false);
                                                        }}
                                                        onChange={this.citySelect}
                                                        value={cityName ? cityName : ''}
                                                        getOptionLabel={(option) => option ? option.StateID ? option.Name + ", " + option.StateID : option.Name : ''}
                                                        options={cityList}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                onChange={this.handleCityChange}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            : CONFIG.REGION_ID === REGIONS.UK ?
                                                <div className="city-selection2 town-city">
                                                    <Label>Town/City:</Label>
                                                    <div className="width-100">
                                                        <Autocomplete
                                                            id="asynchronous-demo"
                                                            name="city"
                                                            open={open}
                                                            onOpen={() => {
                                                                this.setOpen(true);
                                                            }}
                                                            onClose={() => {
                                                                this.setOpen(false);
                                                            }}
                                                            onChange={this.citySelect}
                                                            value={cityName ? cityName : ''}
                                                            getOptionLabel={(option) => option ? option.StateID ? option.Name + ", " + option.StateID : option.Name : ''}
                                                            options={cityList}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    onChange={this.handleCityChange}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                : ""}

                                        {addressError ? <span className="error-ad-searchresult entervalidaddress">{addressError}</span> : ''}
                                        <AvField name="Address" maxLength="255" type="text" onChange={this.handleChange} value={searchData ? searchData.Address : ''} label="Street Address" autoComplete="chrome-off" />
                                        <AvField name="Zipcode" maxLength="255" type="text" onChange={this.handleChange} value={searchData ? searchData.Zipcode : ''} label={CONFIG.REGION_ID === REGIONS.UK ? "Postcode" : "Zip Code"} autoComplete="chrome-off" />
                                        <div className="within-field">
                                            <AvField type="select" name="Proximity" label="Within" onChange={this.handleChange} value={searchData ? searchData.Proximity : '5'}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </AvField>
                                            <span>miles of this address</span>
                                        </div>

                                    </div>
                                    : ''
                                }
                            </div>
                            <div onClick={() => this.closePops(this)} className="width10"></div>
                            <div className="search-heading price-search-head">
                                <Link onClick={this.priceFilter.bind(this)}>Price</Link>
                                <span className="checked-close">{searchData.IsPrice === true ? <span><img src={xicon} className="closed-x width12" onClick={() => this.clearPricePop()} alt="Close" /> </span> : <img src={downicon} className="closed-x" onClick={this.priceFilter.bind(this)} alt="Down Arrow" />}</span>
                                {isPriceFilter ?
                                    <div className="location-search">
                                        <Row>
                                            <Row>
                                                <div className="width-47">
                                                    <AvField name="MinPrice" maxLength="13" onChange={this.handleChange} value={searchData ? getFormattedInt(searchData.MinPrice) : ''} placeholder="Min Price" onKeyPress={(e) => this.onKeyPress(e)} />
                                                </div>
                                                <div className="width-6">
                                                    <span>To</span>
                                                </div>
                                                <div className="width-47">
                                                    <AvField name="MaxPrice" maxLength="13" onChange={this.handleChange} value={searchData ? getFormattedInt(searchData.MaxPrice) : ''} placeholder="Max Price" onKeyPress={(e) => this.onKeyPress(e)} />
                                                </div>
                                                {priceError === true ? <span className="error-ad-searchresult">The maximum price can't be less than the minimum price</span> : ''}
                                            </Row>
                                            <AvRadioGroup inline name="PriceRangeTypeId" label="" className="radius-btn-search" value={searchData ? searchData.PriceRangeTypeId : ''}>

                                                {priceRangeTypesList ? priceRangeTypesList.map(n => {
                                                    return (
                                                        <AvRadio label={n.Name} onChange={this.handleChange} value={n.ID} />
                                                    );
                                                }) : ''}
                                            </AvRadioGroup>

                                        </Row>
                                    </div> : ''
                                }
                            </div>
                            <div onClick={() => this.closePops(this)} className="width10"></div>
                            <div className="search-heading lot-search-head">
                                <Link onClick={this.lotSizeFilter.bind(this)}>Lot Size</Link>
                                <span className="checked-close">{searchData.IsLotSize === true ? <img src={xicon} className="closed-x width12" onClick={() => this.clearLotPop()} alt="Close" /> : <img src={downicon} className="closed-x" onClick={this.lotSizeFilter.bind(this)} alt="Down Arrow" />}</span>
                                {isLotSize ?
                                    <div className="location-search">
                                        <Row>
                                            <Row>
                                                <div className="width-47 margin-b-0">
                                                    <AvField name="MinLotSize" maxLength="13" onChange={this.handleChange} value={searchData ? getFormattedInt(searchData.MinLotSize) : ''} placeholder="Min Size" onKeyPress={(e) => this.onKeyPress(e)} />
                                                </div>
                                                <div className="width-6">
                                                    <span>To</span>
                                                </div>
                                                <div className="width-47 margin-b-0">
                                                    <AvField name="MaxLotSize" maxLength="13" onChange={this.handleChange} value={searchData ? getFormattedInt(searchData.MaxLotSize) : ''} placeholder="Max Size" onKeyPress={(e) => this.onKeyPress(e)} />
                                                </div>
                                                {lotError === true ? <span className="error-ad-searchresult margin-t-5">The maximum value can't be less than the minimum value</span> : ''}
                                            </Row>
                                            <AvRadioGroup inline name="LotsizeMeasurementId" label="" className="radius-btn-search" onChange={this.handleChange} value={searchData ? searchData.LotsizeMeasurementId : ''}>

                                                {measurementsList ? measurementsList.map(n => {
                                                    return (
                                                        <AvRadio label={n.Name} value={n.ID} />
                                                    );
                                                }) : ''}
                                            </AvRadioGroup>

                                        </Row>
                                    </div> : ''
                                }
                            </div>
                            <div onClick={() => this.closePops(this)} className="width10"></div>
                            <div className="search-heading nrsf-search-head">
                                <Link onClick={this.nrsfFilter.bind(this)}>NRSF</Link>
                                <span className="checked-close">{searchData.IsNRSF === true ? <img src={xicon} className="closed-x width12" onClick={() => this.clearNRSFPop()} alt="Close" /> : <img src={downicon} className="closed-x" onClick={this.nrsfFilter.bind(this)} alt="Down Arrow" />}</span>
                                {isNrsf ?
                                    <div className="location-search">
                                        <Row>
                                            <div className="width-47 margin-b-0">
                                                <AvField name="MinNRSF" maxLength="13" onChange={this.handleChange} value={searchData ? getFormattedInt(searchData.MinNRSF) : ''} placeholder="Min" onKeyPress={(e) => this.onKeyPress(e)} />
                                            </div>
                                            <div className="width-6">
                                                <span>To</span>
                                            </div>
                                            <div className="width-47 margin-b-0">
                                                <AvField name="MaxNRSF" maxLength="13" onChange={this.handleChange} value={searchData ? getFormattedInt(searchData.MaxNRSF) : ''} placeholder="Max" onKeyPress={(e) => this.onKeyPress(e)} />
                                            </div>

                                            {nrsfError === true ? <span className="error-ad-searchresult margin-t-5">The maximum value can't be less than the minimum value</span> : ''}

                                        </Row>
                                    </div> : ''
                                }
                            </div>
                            <div onClick={() => this.closePops(this)} className="width10"></div>
                            <div className="search-heading others-search-head">
                                <Link onClick={this.otherFilter.bind(this)}>Others </Link>
                                <span className="checked-close">{searchData.IsOthers === true ? <img src={xicon} className="closed-x width12" onClick={() => this.clearOthersPop()} alt="Close" /> : <img src={downicon} className="closed-x" onClick={this.otherFilter.bind(this)} alt="Down Arrow" />}</span>
                                {isOther ?
                                    <div className="location-search background-search check-showonlyroom">
                                        <Row>
                                            <div className="show-room-only">
                                                <AvField type="radio" name="ShowOnlyWithRoomOfExpansion" label="Show only with room for expansion" onChange={this.handleChange} checked={searchData && searchData.ShowOnlyWithRoomOfExpansion ? true : false} />
                                            </div>
                                            <AvRadioGroup inline name="ExpansionAndPortFolio" label="" onChange={this.handleChange} value={searchData && searchData.PortfoliosOnly === true ? "2" : searchData.ExcludePortfolios === true ? "3" : ''}>
                                                <AvRadio label="Portfolios Only" value="2" />
                                                <AvRadio label="Exclude Portfolios" value="3" />
                                            </AvRadioGroup>
                                            <Row>
                                                <div>
                                                    <h3>Market Features <span>{CONFIG.REGION_ID !== REGIONS.UK ? "(three-mile radius)" : "(10-mile radius)"}</span></h3>

                                                    <h6>Average Cost Per Sq. Ft.</h6>
                                                    <div className="market-radius-flex">
                                                        <AvField name="MarketPSFAverage" type="text" onChange={this.handleChange} value={searchData ? getFormattedFloat(searchData.MarketPSFAverage) : ''} label="more than" onKeyPress={(e) => this.onKeyPress(e)} maxLength="13" />
                                                        <div className="App">
                                                            <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="registerTip3" />
                                                            <ReactTooltip id="registerTip3" place="top" effect="solid">
                                                                National Average is {CONFIG.REGION_ID !== REGIONS.UK ? CURRENCY.US + MVPMarketPSFAverage + " psf" : CURRENCY.UK + MVPMarketPSFAverage + " psf"}
                                                            </ReactTooltip>
                                                        </div>
                                                    </div>
                                                    <h6>Average Population Per Store</h6>
                                                    <div className="market-radius-flex">
                                                        <AvField name="AvPopulationPerStore" type="text" onChange={this.handleChange} value={searchData ? getFormattedFloat(searchData.AvPopulationPerStore) : ''} label="more than" onKeyPress={(e) => this.onKeyPress(e)} maxLength="13" />
                                                        <div className="App">
                                                            <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="registerTip4" />
                                                            <ReactTooltip id="registerTip4" place="top" effect="solid">
                                                                {"National Average is  " + MVPAveragePopulationPerStore}
                                                            </ReactTooltip>
                                                        </div>
                                                    </div>
                                                    <h6>Sq. Ft. Per Capita</h6>
                                                    <div className="market-radius-flex">
                                                        <AvField name="CurrentSqftPerCapita" type="text" onChange={this.handleChange} value={searchData ? getFormattedFloat(searchData.CurrentSqftPerCapita) : ''} label="less than" onKeyPress={(e) => this.onKeyPress(e)} maxLength="13" />

                                                        <div className="App">
                                                            <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="registerTip5" />
                                                            <ReactTooltip id="registerTip5" place="top" effect="solid">
                                                                {"National Average is " + MVPCurrentSQFTPerCapita + " SF/capita"}
                                                            </ReactTooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Row>
                                    </div> : ''
                                }
                            </div>
                            <Button id="btnApply" className="apply-search-btn">Apply</Button>
                            <Button className="clear-search-btn" onClick={this.clear.bind(this)}>Clear</Button>
                        </Row>

                    </AvForm>

                    <div className="gird-list-categories">
                        <div className="select-orderby">
                            <Input placeholder="OrderBy" bsSize="lg" className="select-range" value={orderBy} onChange={e => this.handleOrderByChange(e)} type="select" name="orderBy" id="orderBy">
                                <option key={2 + "DESC"} value={"2-DESC"}>{"Newest"}</option>
                                <option key={2 + "ASC"} value={"2-ASC"}>{"Oldest"}</option>

                                <option key={1 + "ASC"} value={"1-ASC"}>{"Price (Low to High)"}</option>
                                <option key={1 + "DESC"} value={"1-DESC"}>{"Price (High to Low)"}</option>

                                <option key={4 + "ASC"} value={"4-ASC"}>{"Cap Rate (Low to High)"}</option>
                                <option key={4 + "DESC"} value={"4-DESC"}>{"Cap Rate (High to Low)"}</option>

                                <option key={3 + "ASC"} value={"3-ASC"}>{"Rentable Sq. Ft. (Low to High)"}</option>
                                <option key={3 + "DESC"} value={"3-DESC"}>{"Rentable Sq. Ft. (High to Low)"}</option>
                            </Input>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>

                <Row>
                    <div className="m-list-search map-infoview">
                        {mapView && !isNoRecords ?
                            <div className="map-relative">
                                <div className="fixed-legend">
                                    <div id="legend">
                                        <div className="check map-label-box">
                                            <div className="map-legends">
                                                {legendData && legendData.length > 0 ? legendData.map(data => {
                                                    return (
                                                        <div className="set-miles"><span className={data.class}></span> {data.distance}</div>);
                                                })
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <MapGL
                                    ref={this.mapRef}
                                    {...this.state.viewport}
                                    onLoad={this.handleOnMapLoad}
                                    onClick={this.handleMapClick}
                                    onTransitionEnd={this.searchFilterData}
                                    onViewportChange={this.handleViewportChange}
                                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                                    mapStyle={"mapbox://styles/mapbox/streets-v11"}
                                    attributionControl={false}
                                >
                                    <NavigationControl className='zoom-plus-minus' showCompass={false} />
                                    <FullscreenControl className='mapbox-full-zoom' />
                                    {mapData.length && (
                                        <Source id="map-data" type='geojson' data={{ type: 'FeatureCollection', features: mapData }}>
                                            <Layer {...layerStyle} />
                                        </Source>
                                    )}
                                    {this.state.showPopupInfo &&
                                        (<Popup
                                            {...this.state.popupInfoLatlng}
                                            onClose={() => this.setState({ showPopupInfo: false })}
                                        >
                                            {this.state?.popupInfoDescription}
                                        </Popup>)}
                                </MapGL>
                            </div>
                            :
                            isNoRecords ?
                                <MapGL
                                    ref={this.mapRef}
                                    {...this.state.viewport}
                                    onViewportChange={this.handleViewportChange}
                                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                                    mapStyle={"mapbox://styles/mapbox/streets-v11"}
                                    attributionControl={false}
                                >
                                    <NavigationControl className='zoom-plus-minus' showCompass={false} />
                                    <FullscreenControl className='mapbox-full-zoom' />
                                </MapGL> : ''
                        }
                    </div>

                    <div className="p-list-search" onClick={() => this.closePops(this)}>
                        <div className="grid-and-list">

                            <div className="alllisting">
                                {isNoRecords || paginationData.TotalRecords === 0 ?
                                    <div className="your-search">Your search did not match with our {paginationData.OverallCount} listings. <span>Modify your search to find matching listings.</span></div>
                                    :
                                    searchResultData && searchResultData.length > 0 ? <span><b>Showing {paginationData.TotalRecords ? paginationData.TotalRecords : 0} listings  of {paginationData.OverallCount}</b></span>
                                        : ''
                                }
                            </div>

                        </div>
                        {searchResultData && searchResultData.length > 0 ?
                            <Table>
                                <TableBody>
                                    <div className="row gridviewproperty">
                                        {searchResultData && searchResultData.map(stores => {
                                            return (
                                                <Col sm={12} className="margin-b25 list-grid-search">
                                                    {stores.AdvertisementId === 0 ?
                                                        <div className="home-property">
                                                            <div className="property-img" style={{ backgroundImage: `url(${(CONFIG.REGION_ID !== REGIONS.UK ? CONFIG.LSS_US : CONFIG.LSS_UK) + '/PropertyImages/' + stores.PrimaryImage})` }}>
                                                                <Link to={"/property/" + stores.Permalink} target="_blank" rel="noopener noreferrer">
                                                                </Link>
                                                                <div className="tags-tss-s">
                                                                    <div className={getPropertyTypeClass(stores.PropertyTypeId)}>{stores.PropertyType}</div>
                                                                    <div className={getPropertyStatusClass(stores.PropertyStatusId)}>{getPropertyStatus(stores.PropertyStatusId)}</div>
                                                                </div>
                                                            </div>
                                                            <div className="tags-tss">
                                                                {stores.Status ? stores.Status : ''}
                                                            </div>
                                                            <Row className="content-property">
                                                                <Link to={"/property/" + stores.Permalink} className="link-grid" target="_blank" rel="noopener noreferrer">
                                                                    <Col sm={12} className="padding-8">
                                                                        <h5 title={stores.PropertyName}>{substringStripHtmlTag(stores.PropertyName, 55)}</h5>
                                                                        <h6>{stores.City ? stores.City : ''}{CONFIG.REGION_ID === REGIONS.UK ? '' : stores.StateName ? ', ' + stores.StateName : ''}</h6>
                                                                        {stores.AlternatePriceOptionID ?
                                                                            <p className=""><span className="call-forvalues">{stores.AlternatePriceOptionName}{(stores.AlternatePriceOptionID === 5 || stores.AlternatePriceOptionID === 6) && stores.AlternatePriceOptionDate !== null ? ": " + moment(stores.AlternatePriceOptionDate).format(getDateFormat()) : ''}</span></p>
                                                                            :
                                                                            <p className=""><span className="rate-p"> {stores.Price ? stores.Currency + getFormattedInt(stores.Price) : 'N/A'} </span></p>
                                                                        }
                                                                    </Col>
                                                                    <Col sm={12} className="padding-8">
                                                                        {thumbnailPropertyData(stores)}
                                                                    </Col>
                                                                </Link>
                                                            </Row>
                                                            <React.Fragment>
                                                                {userType !== USERTYPES.Admin ?
                                                                    !token ?
                                                                        <div className=" tag-wishlist2"><Button className="" onClick={() => this.signInPopup()}>
                                                                            <img src={pheart} title="Save property to WatchList" alt="Save property to WatchList" /> </Button></div>
                                                                        : <div className="tag-wishlist2">
                                                                            {stores.IsWishlisted === false ?
                                                                                <Button className="" onClick={() => this.addWishList(stores.PropertyId)} title="Save property to WatchList"><img src={pheart} alt="Save Property to WatchList" /> </Button> :
                                                                                <Button className="" onClick={() => this.removeWishList(stores.PropertyId)} title="Remove property from WatchList"> <img src={fheart} alt="Remove Property from WatchList" /></Button>}
                                                                        </div>
                                                                    : ''
                                                                }
                                                            </React.Fragment>
                                                            <div className="clear"></div>
                                                        </div>
                                                        :
                                                        <div className="home-property grid-sponsored">
                                                            <div className="property-img" style={{ backgroundImage: `url(${(CONFIG.REGION_ID !== REGIONS.UK ? CONFIG.LSS_US : CONFIG.LSS_UK) + '/ATImages/' + stores.AdvertisementImage})` }}>
                                                                <a href={stores.AdvertisementURL} target="_blank" rel="noopener noreferrer">
                                                                    {/* <img src={'/AdvertisementImages/' + stores.AdvertisementImage} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} className="width-100 height-100" /> */}
                                                                </a>
                                                                <div className="tags-tss-s">
                                                                    <div className="bluetag-s orangetag">Sponsored</div>
                                                                </div>
                                                            </div>
                                                            <Row className="content-property">
                                                                <a href={stores.AdvertisementURL} target="_blank" className="link-grid" rel="noopener noreferrer">
                                                                    <Col sm={12} className="padding-8">
                                                                        <h5 title={stores.AdvertisementTitle}>{substringStripHtmlTag(stores.AdvertisementTitle, 55)}</h5>
                                                                        <h6>{substringStripHtmlTag(stores.AdvertisementBody, 180)}</h6>
                                                                    </Col>
                                                                </a>
                                                            </Row>
                                                            <div className="clear"></div>
                                                        </div>
                                                    }
                                                </Col>
                                            );
                                        })
                                        }
                                    </div>
                                </TableBody>
                                <TableFooter>
                                    <TableRow className="table-pagination">
                                        <div className={useStyles}>
                                            <Pagination page={paginationData.Page} className="seaarch-pagination" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                                        </div>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            : ''}
                    </div>
                </Row>
            </div >
        );
    }
}

export default SearchResult;
