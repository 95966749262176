import React, { Component } from 'react';
import { Row, Col, Button, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faSearch } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from "react-router-dom";
import { CONFIG } from '../../../Utils/config';
import { PROPERTYSTATUS, getDateFormat, REGIONS, BROKERTYPES } from '../../../Utils/utils';
import axios from 'axios';
import { connect } from 'react-redux';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import './PropertyList.css';
import Pagination from '@material-ui/lab/Pagination';
import edit from '../../../Assets/Img/edit.png';
import Helmet from '../../../Utils/Helmet';

class PropertyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            stateList: [],
            newsTypes: [],
            propertyStatus: [],
            pagination: {},
            newsTypeIds: [],
            searchCriteria: {
            },
            isChangeStatus: false,
            propertyIdStatusChange: '',
            pageNum: 0
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getLookupStatus();
        this.InitialSearchValue();
        localStorage.removeItem('SURL');
    }

    InitialSearchValue() {
        var searchCriteria = {
            PropertyCode: '',
            PropertyName: '',
            PropertyStatusId: 0,
            City: '',
            StateID: '',
            CountryID: CONFIG.REGION_ID === REGIONS.US ? CONFIG.US_CountryId : '',
            RegionId: CONFIG.REGION_ID,
            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: '',
            SortOrder: ''
        }
        this.setState({ searchCriteria }, () => {
            this.getProperties(searchCriteria);
            if (CONFIG.REGION_ID !== REGIONS.UK) {
                this.getLookupState();
            }
        });
    }

    getLookupStatus() {
        axios.get(CONFIG.API_URL + 'lookup/property/status').then(response => {
            if (response.status === 200) {
                this.setState({ propertyStatus: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    getLookupState() {
        const { searchCriteria } = this.state;

        axios.get(CONFIG.API_URL + 'lookup/state/' + searchCriteria.CountryID).then(response => {
            if (response.status === 200) {
                this.setState({ stateList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getLookupCountry() {
        axios.get(CONFIG.API_URL + 'lookup/country/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                this.setState({ countryList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getProperties(data) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        this.props.dispatch(showLoader());
        axios.post(CONFIG.API_URL + 'property/search', data).then(response => {
            if (response.status === 200) {
                this.props.dispatch(hideLoader());
                this.setState({
                    searchResult: response.data.Property, pagination: response.data.Pagination,
                    pageNum: Math.ceil(response.data.Pagination.TotalRecords / 10)
                });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    handleChangePage = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            this.getProperties(searchCriteria);
        });

    };

    onSearchTextChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria[e.target.name] = e.target.value;
        this.setState({ searchCriteria })
    }

    onSearchStatusChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.PropertyStatusId = e.target.value;
        this.setState({ searchCriteria })
    }

    onStateChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.StateID = e.target.value;
        this.setState({ searchCriteria });
    }

    onCountryChange(e) {
        const { searchCriteria } = this.state;
        searchCriteria.CountryID = e.target.value;
        this.setState({
            searchCriteria
        });

    }

    searchSubmit() {
        const { searchCriteria } = this.state;
        searchCriteria.Page = 1;
        this.setState({ searchCriteria }, () => {
            this.getProperties(searchCriteria);
        });
    }

    resetSearch() {
        this.InitialSearchValue();
    }

    editProperty(property) {
        const { from } = { from: { pathname: "/user/editproperty/" + property.PropertyId } };
        this.props.history.push(from);
    }

    restoreProperty(id) {
        const { searchCriteria } = this.state;

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.put(CONFIG.API_URL + 'property/' + id)
            .then(res => {
                this.getProperties(searchCriteria);
            })
            .catch((err) => {
                this.setState({ isLoading: false });

            });
    }

    deleteProperty(id) {
        const { searchCriteria } = this.state;

        confirmAlert({
            message: 'Are you sure to make this property inactive?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        this.setState({ isLoading: true });

                        axios.delete(CONFIG.API_URL + 'property/' + id)
                            .then(res => {
                                this.getProperties(searchCriteria);
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });

                            });

                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    changeStatus(e, id) {
        const { isChangeStatus } = this.state;
        this.setState({ isChangeStatus: !isChangeStatus, propertyIdStatusChange: id });
    }

    onPropertyStatusChange(e, record) {
        const { searchCriteria } = this.state;
        var id = record.PropertyId;
        var propertyStatus = record.PropertyStatusId;
        let brokerType = record.BrokerTypeId;
        var statusId = e.target.value;
        var statusName = e.target.selectedOptions[0].innerHTML;
        if (statusName !== "--Select--") {
            confirmAlert({
                message: 'Are you sure to change this status?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            if (CONFIG.REGION_ID === REGIONS.US) {
                                if (record.IsStripe === true && brokerType !== BROKERTYPES.PremiumBroker && (propertyStatus === PROPERTYSTATUS.Closed || propertyStatus === PROPERTYSTATUS.Inactive)) {
                                    const { from } = { from: { pathname: "/user/editproperty/" + id + "/" + statusId } };
                                    this.props.history.push(from);
                                }
                                else if (propertyStatus === PROPERTYSTATUS.Closed) {
                                    const { from } = { from: { pathname: "/user/editproperty/" + id + "/" + statusId } };
                                    this.props.history.push(from);
                                }
                                else {
                                    let token = localStorage.getItem('accessKey');
                                    if (token) {
                                        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                                    }
                                    this.setState({ isLoading: true });
                                    let endpoint = 'property/status/' + id + '/' + statusId + '/' + record.PropertyStatusId + '/' + CONFIG.REGION_ID;
                                    if (record.IsStripe === true) endpoint = 'property/stripestatus/' + id + '/' + statusId + '/' + record.PropertyStatusId + '/' + CONFIG.REGION_ID;
                                    axios.put(CONFIG.API_URL + endpoint)
                                        .then(res => {
                                            let searchResult = this.state.searchResult;
                                            const idx = searchResult.findIndex(property => property.PropertyId === id);
                                            searchResult[idx].Status = statusName;

                                            this.setState({ isChangeStatus: false, propertyIdStatusChange: '', searchResult });
                                            this.getProperties(searchCriteria);
                                            this.props.dispatch(setSnackbar(true, "success", "Property status updated successfully"));
                                        })
                                        .catch(err => {
                                            this.setState({ isLoading: false });
                                            this.props.dispatch(setSnackbar(true, "error", "Something went wrong please reach out to technical team for assistance."));
                                        });
                                }
                            } else {
                                if (propertyStatus === PROPERTYSTATUS.Closed) {
                                    const { from } = { from: { pathname: "/user/editproperty/" + id + "/" + statusId } };
                                    this.props.history.push(from);
                                }
                                else {
                                    let token = localStorage.getItem('accessKey');
                                    if (token) {
                                        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                                    }
                                    this.setState({ isLoading: true });

                                    axios.put(CONFIG.API_URL + 'property/status/' + id + '/' + statusId + '/' + record.PropertyStatusId + '/' + CONFIG.REGION_ID)
                                        .then(res => {
                                            let searchResult = this.state.searchResult;
                                            const idx = searchResult.findIndex(property => property.PropertyId === id);
                                            searchResult[idx].Status = statusName;

                                            this.setState({ isChangeStatus: false, propertyIdStatusChange: '', searchResult });
                                            this.getProperties(searchCriteria);
                                            this.props.dispatch(setSnackbar(true, "success", "Property status updated successfully"));
                                        })
                                        .catch(err => {
                                            this.setState({ isLoading: false });
                                            this.props.dispatch(setSnackbar(true, "error", "Something went wrong please reach out to technical team for assistance."));
                                        });
                                }
                            }
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        }
    }

    render() {

        const { searchCriteria, searchResult, pagination, propertyStatus, stateList
            , isChangeStatus, propertyIdStatusChange, pageNum } = this.state;

        return (
            <div className="property-list-table">
                <Helmet
                    title="Manage Property"
                    addPostfixTitle={true}
                    keywords="Manage Property, Property"
                />
                <div className="header-name-box form-back-shadow addpadding-property margin-t-15">
                    <Row className="marign-t-8">
                        <Col sm={8}>
                            <h6>Property Listings</h6>
                            <Row className="header-count">
                            </Row>
                        </Col>
                        <Col sm={4}>
                            <div className="add-new-btn">
                                <Link to="/user/addproperty" ><FontAwesomeIcon icon={faPlusSquare} /> Add new property</Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="form-back-shadow ">
                    <div className="filters-search">
                        <AvForm onSubmit={this.searchSubmit.bind(this)} ref={c => (this.myFormSearch = c)}>
                            <Row>
                                <Col sm={3}>
                                    <AvField name="PropertyName" label="Property Name:" type="text"
                                        value={searchCriteria.PropertyName} onChange={(e) => this.onSearchTextChange(e)} />
                                </Col>
                                <Col sm={3}>
                                    <AvField name="City" label={CONFIG.REGION_ID !== REGIONS.UK ? "City:" : "Town/City:"} type="text"
                                        value={searchCriteria.City} onChange={(e) => this.onSearchTextChange(e)} />
                                </Col>
                                {CONFIG.REGION_ID !== REGIONS.UK ?
                                    <Col sm={3}>
                                        <Label>State:</Label>
                                        <AvField type="select" name="StateID" value={searchCriteria.StateID}
                                            onChange={(e) => this.onStateChange(e)}
                                        >
                                            <option value="0">--Select--</option>
                                            {stateList ? stateList.map(n => {
                                                return (
                                                    <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                        disabled={n.Id === '' ? true : false}
                                                        value={n.Id}>
                                                        {n.Id}
                                                    </option>
                                                );
                                            }) : ''}
                                        </AvField>
                                    </Col>
                                    : ''}
                                <Col sm={3}>
                                    <AvField type="select" name="PropertyStatusId" value={searchCriteria.PropertyStatusId}
                                        onChange={(e) => this.onSearchStatusChange(e)}
                                        label="Status:">
                                        <option value="0">--Select--</option>
                                        {propertyStatus ? propertyStatus.map(n => {
                                            return (
                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                    disabled={n.ID === '' ? true : false}
                                                    value={n.ID}>
                                                    {n.Name}
                                                </option>
                                            );
                                        }) : ''}
                                    </AvField>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="text-align-right">
                                    <Button className="search-btn "><FontAwesomeIcon icon={faSearch} />Search</Button>
                                    <Button onClick={this.resetSearch.bind(this)} className="reset-btn">Reset</Button>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                    <Row className="margin-t-23">
                        <Col md={12} className="total-numb">
                            {searchResult && searchResult.length > 0 ? <p>Total Property Listings: {pagination.TotalRecords}</p> : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="table-model property-table">
                                {searchResult ? searchResult.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="date-width">Created Date</TableCell>
                                                <TableCell className="property-width3">Property Name</TableCell>
                                                <TableCell className="property-width2">Address</TableCell>
                                                {CONFIG.REGION_ID === REGIONS.US ?
                                                    <TableCell>City</TableCell>
                                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                                        <TableCell>Town/City</TableCell>
                                                        : ""}
                                                {CONFIG.REGION_ID !== REGIONS.UK ? <TableCell>State</TableCell> : ''}
                                                <TableCell>Status</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {searchResult.map((record) =>
                                                <TableRow key={record.PropertyId} >
                                                    <TableCell>{moment(record.CreatedDate).format(getDateFormat())}</TableCell>
                                                    <TableCell><span title={record.PropertyName}>{record.PropertyName.length > 30 ? <span>{record.PropertyName.substring(0, 30) + "..."}</span> : <span> {record.PropertyName}</span>}</span></TableCell>
                                                    <TableCell>{record.Address1}</TableCell>
                                                    <TableCell>{record.City}</TableCell>
                                                    {CONFIG.REGION_ID !== REGIONS.UK ? <TableCell>{record.StateId}</TableCell> : ''}
                                                    <TableCell> {isChangeStatus && propertyIdStatusChange === record.PropertyId ? <React.Fragment>
                                                        <div className="select-status-property">
                                                            <select type="select" className="select-status-property" name="PropertyStatusId" value={record.PropertyStatusId}
                                                                onChange={(e) => this.onPropertyStatusChange(e, record)}
                                                                label="Status:">
                                                                <option value="0">--Select--</option>
                                                                {propertyStatus ? propertyStatus.map(n => {
                                                                    if (record.PropertyStatusId !== PROPERTYSTATUS.Archived) {
                                                                        if (record.PropertyStatusId === PROPERTYSTATUS.Approved && (n.ID === PROPERTYSTATUS.Sold || n.ID === PROPERTYSTATUS.Inactive || n.ID === PROPERTYSTATUS.SalePending || n.ID === PROPERTYSTATUS.Closed)) {
                                                                            return (
                                                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                                    disabled={n.ID === '' ? true : false}
                                                                                    value={n.ID}>
                                                                                    {n.Name}
                                                                                </option>
                                                                            );
                                                                        } else if (record.PropertyStatusId === PROPERTYSTATUS.Inactive && (n.ID === PROPERTYSTATUS.Approved || n.ID === PROPERTYSTATUS.SalePending)) {
                                                                            return (
                                                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                                    disabled={n.ID === '' ? true : false}
                                                                                    value={n.ID}>
                                                                                    {n.Name}
                                                                                </option>
                                                                            );
                                                                        } else if (record.PropertyStatusId === PROPERTYSTATUS.SalePending && (n.ID === PROPERTYSTATUS.Sold || n.ID === PROPERTYSTATUS.Inactive || n.ID === PROPERTYSTATUS.Approved || n.ID === PROPERTYSTATUS.Closed)) {
                                                                            return (
                                                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                                    disabled={n.ID === '' ? true : false}
                                                                                    value={n.ID}>
                                                                                    {n.Name}
                                                                                </option>
                                                                            );
                                                                        } else if (record.PropertyStatusId === PROPERTYSTATUS.Closed && (n.ID === PROPERTYSTATUS.Approved || n.ID === PROPERTYSTATUS.SalePending)) {
                                                                            return (
                                                                                <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                                    disabled={n.ID === '' ? true : false}
                                                                                    value={n.ID}>
                                                                                    {n.Name}
                                                                                </option>
                                                                            );
                                                                        }
                                                                        else {
                                                                            return null;
                                                                        }
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }) : ''}
                                                            </select>
                                                        </div>
                                                    </React.Fragment> : record.Status}</TableCell>
                                                    <TableCell>
                                                        {record.PropertyStatusId === PROPERTYSTATUS.Approved || record.PropertyStatusId === PROPERTYSTATUS.PendingApproval || record.PropertyStatusId === PROPERTYSTATUS.Draft || record.PropertyStatusId === PROPERTYSTATUS.Inactive || record.PropertyStatusId === PROPERTYSTATUS.SalePending || record.PropertyStatusId === PROPERTYSTATUS.Closed ?
                                                            <img src={edit} type="edit" alt="edit" className="cursor-pointer" onClick={() => this.editProperty(record)} /> : ''}
                                                        {record.PropertyStatusId === PROPERTYSTATUS.Approved || record.PropertyStatusId === PROPERTYSTATUS.Inactive || record.PropertyStatusId === PROPERTYSTATUS.SalePending || record.PropertyStatusId === PROPERTYSTATUS.Closed || record.PropertyStatusId === PROPERTYSTATUS.ex ?
                                                            <Button className="approve-btn status-orange" onClick={(e) => this.changeStatus(e, record.PropertyId)}> Change Status</Button>
                                                            : ''}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan="7">
                                                    <Pagination className="seaarch-pagination" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                    : <h6 className="no-records-found">No records found</h6>
                                    : ''}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default connect()(PropertyList);