import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import Switch from 'react-switch';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faSearch } from '@fortawesome/free-solid-svg-icons';
import edit from '../../../Assets/Img/edit.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from "react-router-dom";
import { CONFIG } from '../../../Utils/config';
import { USERSTATUS, getDateFormat, REGIONS } from '../../../Utils/utils';
import axios from 'axios';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import './UserManagement.css';
import { connect } from 'react-redux';
import { EditProfile } from '../../Shared';
import Helmet from '../../../Utils/Helmet';

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTypes: [],
      userStatus: [],
      stateList: [],
      pagination: {},
      countryList: [],
      searchCriteria: {
      },
      isProfileEdit: false,
      BrokerCount: 0,
      PremiumBrokerCount: 0,
      UnauthorizedCount: 0,
      pageNum: 0
    };
    this.parentMethod = this.parentMethod.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getLookupBrokerTypes();
    this.getLookupStatus();
    if (CONFIG.REGION_ID !== REGIONS.UK) {
      this.getLookupCountry();
    }
    this.InitialSearchValue();
  }

  InitialSearchValue() {
    var searchCriteria = {
      RegionId: CONFIG.REGION_ID,
      Name: '',
      StatusID: 0,
      CountryID: CONFIG.REGION_ID === REGIONS.US ? CONFIG.US_CountryId : '',
      StateID: "",
      BrokerTypeId: 0,

      Page: 1,
      PageLength: CONFIG.PAGE_LENGTH,
      SortBy: '',
      SortOrder: ''
    }
    this.setState({ searchCriteria }, () => {
      this.getUsersList(searchCriteria);
      if (CONFIG.REGION_ID !== REGIONS.UK) {
        this.getLookupState();
      }
    });
  }

  getLookupBrokerTypes() {
    axios.get(CONFIG.API_URL + 'lookup/broker/types').then(response => {
      if (response.status === 200) {
        this.setState({ userTypes: response.data });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  getLookupStatus() {
    axios.get(CONFIG.API_URL + 'lookup/user/status').then(response => {
      if (response.status === 200) {
        this.setState({ userStatus: response.data });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  getLookupCountry() {
    axios.get(CONFIG.API_URL + 'lookup/country/' + CONFIG.REGION_ID).then(response => {
      if (response.status === 200) {
        this.setState({ countryList: response.data });
      }
    })
      .catch(err => {
      });
  }

  getLookupState() {
    const { searchCriteria } = this.state;

    axios.get(CONFIG.API_URL + 'lookup/state/' + searchCriteria.CountryID).then(response => {
      if (response.status === 200) {
        this.setState({ stateList: response.data });
      }
    })
      .catch(err => {
      });
  }

  getUsersList(data) {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'admin/user/search', data).then(response => {
      if (response.status === 200) {
        this.setState({
          searchResult: response.data.Users,
          pagination: response.data.Pagination,
          pageNum: Math.ceil(response.data.Pagination.TotalRecords / 10),
          BrokerCount: response.data.BrokerCount,
          PremiumBrokerCount: response.data.PremiumBrokerCount,
          UnauthorizedCount: response.data.UnauthorizedCount
        });
      }
    })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  resetSearch() {
    this.InitialSearchValue();
  }

  onSearchStateChange(e) {
    const { searchCriteria } = this.state;
    searchCriteria.StateID = e.target.value;
    this.setState({ searchCriteria })
  }

  onSearchCountryChange(e) {
    const { searchCriteria } = this.state;
    searchCriteria.CountryID = e.target.value;

    this.setState({ searchCriteria });
  }

  onSearchStatusChange(e) {
    const { searchCriteria } = this.state;
    searchCriteria.StatusID = e.target.value;
    this.setState({ searchCriteria })
  }

  onSearchNameChange(e) {
    const { searchCriteria } = this.state;
    searchCriteria.Name = e.target.value;
    this.setState({ searchCriteria })
  }

  onUserTypeChange(e) {
    const { searchCriteria } = this.state;
    searchCriteria.BrokerTypeId = e.target.value;
    this.setState({ searchCriteria })
  }

  searchSubmit() {
    const { searchCriteria } = this.state;
    searchCriteria.Page = 1;
    this.setState({ searchCriteria }, () => {
      this.getUsersList(searchCriteria);
    });
  }

  initializeUserObject() {
    var user = {
      UserID: 0,
      Login: '',
      Password: '',
      ConfirmPassword: '',
      FirstName: '',
      Company: '',
      Phone: '',
      IsBuyer: false,
      IsBroker: false,
      IsManagement: false,
      IsLender: false,
      IsVendor: false,
      IsOperator: false,
      IsOtherUserType: false,
      OtherUserTypes: '',
      BillingAddress: '',
      BillingState: '0',
      BillingCity: '',
      BillingCountry: CONFIG.REGION_ID === REGIONS.US ? CONFIG.US_CountryId : '',
      BillingZipCode: '',
      BrokerTypeId: "0",
    }
    return user;
  }

  addUser() {
    var user = this.initializeUserObject();
    this.setState({ isProfileEdit: true, user });
  }

  editUser(id) {
    this.getUserDetail(id);
  }

  restoreUser(id) {

    confirmAlert({
      message: 'Are you sure to make this user active?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            axios.put(CONFIG.API_URL + 'admin/user/' + id)
              .then(res => {
                const { searchCriteria } = this.state;
                this.getUsersList(searchCriteria);
                this.props.dispatch(setSnackbar(true, "success", "The user activated successfully"));
              })
              .catch((err) => {

              });

          }
        },
        {
          label: 'No'
        }
      ]
    });

  }

  deleteUser(id) {
    confirmAlert({
      message: 'Are you sure to make this user inactive?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let token = localStorage.getItem('accessKey');
            if (token) {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }

            axios.delete(CONFIG.API_URL + 'admin/user/' + id)
              .then(res => {
                const { searchCriteria } = this.state;
                this.getUsersList(searchCriteria);
                this.props.dispatch(setSnackbar(true, "success", "The user inactivated successfully"));
              })
              .catch((err) => {

              });

          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  getUserDetail(id) {

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'admin/user/' + id + '/' + CONFIG.REGION_ID).then(response => {
      if (response.status === 200) {
        var user = response.data.UserInfo;

        this.setState({ user }, () => {
          this.setState({ isProfileEdit: true });
        });
      }
    })
      .catch(err => {
        if (err.response !== null && err.response !== undefined) {
          this.props.dispatch(setSnackbar(true, "error", err.response.data));
        }
        else {
          this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
        }
      });
  }

  handleChangePage = (event, pageCount) => {
    const { searchCriteria } = this.state;
    searchCriteria.Page = pageCount;
    this.setState({ searchCriteria }, () => {
      this.getUsersList(searchCriteria);
    });
  };

  parentMethod() {
    this.setState({ isProfileEdit: false });
    const { searchCriteria } = this.state;
    this.getUsersList(searchCriteria);
  }

  render() {
    // document.title = CONFIG.PAGE_TITLE + 'User Management';

    const { searchCriteria, user, searchResult, pagination, userStatus, userTypes, stateList,
      isProfileEdit, BrokerCount, PremiumBrokerCount, UnauthorizedCount, pageNum } = this.state;

    return (
      <div>
        <Helmet
          title='User Management'
          addPostfixTitle={true}
          keywords="User Management, Users"
        />
        {isProfileEdit ? <EditProfile user={user} parentMethod={this.parentMethod} type={"profileEdit"} /> : ''}
        <div className="header-name-box form-back-shadow margin-t-15">
          <Row>
            <Col sm={8}>
              <h6>User Management</h6>
              <Row className="header-count">
                <div className="counter-box width150">
                  <h5>Unauthorized Broker</h5>
                  <h4>{UnauthorizedCount}</h4>
                </div>
                <div className="counter-box last-box-border">
                  <h5>Broker</h5>
                  <h4>{BrokerCount}</h4>
                </div>
                <div className="counter-box last-box-border">
                  <h5>Premium Broker</h5>
                  <h4>{PremiumBrokerCount}</h4>
                </div>
              </Row>
            </Col>
            <Col sm={4}>
              <div className="add-new-btn">
                <span onClick={this.addUser.bind(this)} ><FontAwesomeIcon icon={faPlusSquare} /> Add new user</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="form-back-shadow ">
          <div className="filters-search">
            <AvForm onSubmit={this.searchSubmit.bind(this)} >
              <Row>
                <Col md={4}>
                  <AvField name="Name" label="Name:" type="text"
                    value={searchCriteria.Name} onChange={(e) => this.onSearchNameChange(e)} />
                </Col>
                {/* <Col md={2}>
                  <AvField type="select" name="CountryID" value={searchCriteria.CountryID}
                    onChange={(e) => this.onSearchCountryChange(e)}
                    label="Country:" disabled>
                    <option value="0">--Select--</option>
                    {countryList ? countryList.map(n => {
                      return (
                        <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                          disabled={n.Id === '' ? true : false}
                          value={n.Id}>
                          {n.Code}
                        </option>
                      );
                    }) : ''}
                  </AvField>
                </Col> */}
                {CONFIG.REGION_ID !== REGIONS.UK ?
                  <Col md={2}>
                    <AvField type="select" name="StateID" value={searchCriteria.StateID}
                      onChange={(e) => this.onSearchStateChange(e)}
                      label="State:">
                      <option value="">--Select--</option>
                      {stateList ? stateList.map(n => {
                        return (
                          <option key={n.Id} className={n.Id === '' ? "optHead" : 'clsoption'}
                            disabled={n.Id === '' ? true : false}
                            value={n.Id}>
                            {n.Id}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>
                  : ''}
                {CONFIG.REGION_ID === REGIONS.US ?
                  <Col md={2}>
                    <AvField type="select" name="StatusID" value={searchCriteria.StatusID}
                      onChange={(e) => this.onSearchStatusChange(e)}
                      label="Status:">
                      <option value="0">--Select--</option>
                      {userStatus ? userStatus.map(n => {
                        return (
                          <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                            disabled={n.ID === '' ? true : false}
                            value={n.ID}>
                            {n.Name}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>
                  : CONFIG.REGION_ID === REGIONS.UK ?
                    <Col md={3}>
                      <AvField type="select" name="StatusID" value={searchCriteria.StatusID}
                        onChange={(e) => this.onSearchStatusChange(e)}
                        label="Status:">
                        <option value="0">--Select--</option>
                        {userStatus ? userStatus.map(n => {
                          return (
                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                              disabled={n.ID === '' ? true : false}
                              value={n.ID}>
                              {n.Name}
                            </option>
                          );
                        }) : ''}
                      </AvField>
                    </Col>
                    : ""}
                {CONFIG.REGION_ID === REGIONS.US ?
                  <Col md={2}>
                    <AvField type="select" name="BrokerTypeId" value={searchCriteria.BrokerTypeId}
                      onChange={(e) => this.onUserTypeChange(e)}
                      label="User Type:">
                      <option value="0">--Select--</option>
                      {userTypes ? userTypes.map(n => {
                        return (
                          <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                            disabled={n.ID === '' ? true : false}
                            value={n.ID}>
                            {n.Name}
                          </option>
                        );
                      }) : ''}
                    </AvField>
                  </Col>
                  : CONFIG.REGION_ID === REGIONS.UK ?
                    <Col md={3}>
                      <AvField type="select" name="BrokerTypeId" value={searchCriteria.BrokerTypeId}
                        onChange={(e) => this.onUserTypeChange(e)}
                        label="User Type:">
                        <option value="0">--Select--</option>
                        {userTypes ? userTypes.map(n => {
                          return (
                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                              disabled={n.ID === '' ? true : false}
                              value={n.ID}>
                              {n.Name}
                            </option>
                          );
                        }) : ''}
                      </AvField>
                    </Col>
                    : ""}



                <Col md={2} className="text-align-right margin-t-20 padding-left-remove">
                  <Button className="search-btn"><FontAwesomeIcon icon={faSearch} /> Search</Button>
                  <Button onClick={this.resetSearch.bind(this)} className="reset-btn margin-r-0">Reset</Button>
                </Col>
              </Row>
            </AvForm>
          </div>
          <Row>
            <Col md={12} className="total-numb">
              {searchResult && searchResult.length > 0 ? <p>Total User Listings: {pagination.TotalRecords}</p> : ''}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-model vendor-directory-table">
                {searchResult ? searchResult.length > 0 ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        {CONFIG.REGION_ID === REGIONS.US ?
                          <TableCell>City</TableCell>
                          : CONFIG.REGION_ID === REGIONS.UK ?
                            <TableCell>Town/City</TableCell>
                            : ""}

                        {CONFIG.REGION_ID !== REGIONS.UK ?
                          <TableCell>State</TableCell>
                          : ''}
                        <TableCell>Status</TableCell>
                        <TableCell>User Type</TableCell>
                        <TableCell className="width-230">Registered User Type</TableCell>
                        <TableCell>Created Date</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchResult.map((record) => {
                        var userTypes = "";
                        if (record.IsBuyer) { userTypes = userTypes + "Buyer," }
                        if (record.IsBroker) { userTypes = userTypes + " Broker," }
                        if (record.IsManagement) { userTypes = userTypes + " Management Co," }
                        if (record.IsLender) { userTypes = userTypes + " Lender," }
                        if (record.IsVendor) { userTypes = userTypes + " Vendor," }
                        if (record.IsOperator) { userTypes = userTypes + " Operator," }
                        if (record.IsOtherUserType) { userTypes = userTypes + " Other - " + record.OtherUserTypes + "," }
                        userTypes = userTypes.slice(0, -1);
                        return (
                          <TableRow key={record.UserID} >
                            <TableCell>{record.FirstName} {record.LastName}</TableCell>
                            <TableCell>{record.BillingCity}</TableCell>
                            {CONFIG.REGION_ID !== REGIONS.UK ?
                              <TableCell>{record.BillingState}</TableCell>
                              : ''}
                            <TableCell>{record.LSSUserStatusName}</TableCell>
                            <TableCell>{record.BrokerTypeName}</TableCell>
                            <TableCell>
                              {userTypes}
                            </TableCell>
                            <TableCell>{record.LSSCreatedDate ? moment(record.LSSCreatedDate).format(getDateFormat()) : ''}</TableCell>
                            <TableCell>
                              <Link type="edit" onClick={() => this.editUser(record.UserID)}><img src={edit} alt="Edit" className="cursor-pointer" /></Link>
                              {record.LSSUserStatus === USERSTATUS.Active ?
                                <Switch className="active-in slide-button" onChange={event => this.deleteUser(record.UserID)} checked={true} />
                                : record.LSSUserStatus === USERSTATUS.Inactive ?
                                  <Switch className="active-in slide-button" onChange={event => this.restoreUser(record.UserID)} checked={false} />
                                  : ''
                              }
                            </TableCell>
                          </TableRow>
                        )
                      }
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan="8">
                          <Pagination className="seaarch-pagination table-pagination-model" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                  : <h6 className="no-records-found">No records found</h6>
                  : ''}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

}
export default connect()(UserManagement)
