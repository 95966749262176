import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Switch from 'react-switch';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CONFIG } from './../../../Utils/config';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import edit from './../../../Assets/Img/edit.png';
import './CoBroker.css';
import { connect } from 'react-redux';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import Pagination from '@material-ui/lab/Pagination';

class CoBroker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brokerId: props.brokerId,
            propertyId: props.propertyId,
            isLoading: false,
            coBroker: this.initializeCoBrokerObject(),
            searchCriteria: {},
            pageNum: 0,
            error: {},
            coBrokers: []
        }
        this.getCoBrokers = this.getCoBrokers.bind(this);
    }

    componentDidMount() {
        this.InitialSearchValue();
    }

    initializeCoBrokerObject() {
        var cb = {
            Id: 0,
            PropertyId: 0,
            PrimaryBrokerId: 0,
            BrokerFirstName: '',
            BrokerLastName: '',
            BrokerEmail: '',
            IsActive: null
        }
        return cb;
    }

    InitialSearchValue() {
        const { propertyId, brokerId } = this.state;

        var searchCriteria = {            
            PropertyId: propertyId,
            BrokerId: brokerId,
            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: '',
            SortOrder: ''
        }
        this.setState({ searchCriteria }, () => {
            this.getCoBrokers(searchCriteria);
        });
    }

    addCoBroker(event, errors, values) {
        const { propertyId, brokerId, error, coBroker, searchCriteria } = this.state;
        
        if (errors.length === 0) {
            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }

            var data = {
                Id: coBroker.Id,
                PropertyId: propertyId,
                PrimaryBrokerId: brokerId,
                BrokerFirstName: values.BrokerFirstName,
                BrokerLastName: values.BrokerLastName,
                BrokerEmail: values.BrokerEmail
            }

            axios.post(CONFIG.API_URL + 'property/cobroker', data)
                .then(response => {
                    if (response.status === 200) {
                        if (coBroker.Id === 0) {
                            this.props.dispatch(setSnackbar(true, "success", "Co-Broker added successfully"));
                        }
                        else {
                            this.props.dispatch(setSnackbar(true, "success", "Co-Broker updated successfully"));
                        }

                        this.resetCBForm();
                        this.getCoBrokers(searchCriteria);
                    }
                })
                .catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        } else {
            this.setState({ error });
        }
    }

    resetCBForm() {
        this.formCoBroker && this.formCoBroker.reset();
        var coBroker = this.initializeCoBrokerObject();
        this.setState({ coBroker });
    }

    getCoBrokers(data) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.post(CONFIG.API_URL + 'property/cobroker/search', data)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        coBrokers: response.data.CoBroker,
                        pagination: response.data.Pagination,
                        pageNum: Math.ceil(response.data.Pagination.TotalRecords / 10)
                    });
                }
            })
            .catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    editCoBroker(record) {
        this.setState({ coBroker: record });
    }

    restoreCoBroker(id) {
        confirmAlert({
            message: 'Are you sure to make this co-broker active?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        axios.put(CONFIG.API_URL + 'property/cobroker/' + id)
                            .then(res => {
                                this.props.dispatch(setSnackbar(true, "success", "The co-broker activated successfully"));
                                this.updateCoBrokerIcon(id, true);
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    deleteCoBroker(id) {
        confirmAlert({
            message: 'Are you sure to make this co-broker inactive?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        this.setState({ isLoading: true });

                        axios.delete(CONFIG.API_URL + 'property/cobroker/' + id)
                            .then(res => {
                                this.props.dispatch(setSnackbar(true, "success", "The co-broker inactivated successfully"));
                                this.updateCoBrokerIcon(id, false);
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });
                            });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    updateCoBrokerIcon(id, isActive) {
        const { coBrokers } = this.state;

        const newList = coBrokers.map((item) => {
            if (item.Id === id) {
              const updatedItem = {
                ...item,
                IsActive: isActive,
              };
       
              return updatedItem;
            }
       
            return item;
          });
        
        this.setState({ coBrokers : newList });
    }

    handleChangePage = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            this.getCoBrokers(searchCriteria);
        });
    };

    render() {
        const { coBroker, coBrokers, pageNum } = this.state;
        
        return (
            <React.Fragment>
                <div className="filters-search co-broker-search">
                    <AvForm onSubmit={this.addCoBroker.bind(this)} ref={c => (this.formCoBroker = c)}>
                        <Row>
                            <Col md={6}>
                                <AvField name="BrokerFirstName" label="Broker First Name:" type="text" maxlength="50" value={coBroker.BrokerFirstName}
                                    validate={{
                                        required: { value: true, errorMessage: 'Broker First Name is required' },
                                        pattern: { value: '^[A-Za-z ]+$', errorMessage: 'Broker First Name is invalid' }}}
                                />
                            </Col>
                            <Col md={6}>
                                <AvField name="BrokerLastName" label="Broker Last Name:" type="text" maxlength="50" value={coBroker.BrokerLastName}
                                     validate={{
                                        required: { value: true, errorMessage: 'Broker Last Name is required' },
                                        pattern: { value: '^[A-Za-z ]+$', errorMessage: 'Broker Last Name is invalid' }}}
                                />
                            </Col>
                            <Col md={6}>
                                <AvField name="BrokerEmail" label="Broker Email" type="text" maxlength="100" value={coBroker.BrokerEmail}
                                     validate={{
                                        required: { value: true, errorMessage: 'Broker Email is required' },
                                        pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Broker Email is invalid' }}}
                                />
                            </Col>
                            <Col md={12} className="text-align-right cobroker-top-btn">
                                <Button className="search-btn displaynone" id="btn-submit"><FontAwesomeIcon icon={faSearch} /> Search</Button>
                                <Button className="reset-btn edits-btn">{ coBroker.Id === 0 ? "Add" : "Update"}</Button>
                                <Button className="reset-btn resets-btn" onClick={this.resetCBForm.bind(this)}>Reset</Button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
                <div className="table-scroll vendor-popup-table co-broker">
                    {coBrokers ?
                        coBrokers.length > 0 ?
                            <Table>
                                <TableHead>
                                    <TableRow>                                        
                                        <TableCell>Name</TableCell>
                                        <TableCell className="email-width">Email</TableCell>
                                        <TableCell className="width-80seller">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {coBrokers.map((record) =>
                                        <TableRow>
                                            <TableCell>{record.BrokerFirstName} {record.BrokerLastName}</TableCell>
                                            <TableCell className="email-width">{record.BrokerEmail}</TableCell>
                                            <TableCell>
                                            <Link type="edit" className="edit-link" onClick={() => this.editCoBroker(record)}><img src={edit} alt="Edit" className="cursor-pointer" /></Link>
                                                {record.IsActive ?
                                                    <Switch className="active-in slide-button" onChange={event => this.deleteCoBroker(record.Id)} checked={true} />
                                                    :
                                                    <Switch className="active-in slide-button" onChange={event => this.restoreCoBroker(record.Id)} checked={false} />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colspan="3"><Pagination className="seaarch-pagination table-pagination-model" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            :
                            <h6 className="no-records-found">No co-brokers found</h6>
                        : ''}
                </div>
            </React.Fragment>
        );
    }
}
export default connect()(CoBroker);